import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../Navbar";
import { CgCloseR } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import { BiLeftArrow, BiEdit } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { MdAttachFile, MdOutlineDeleteSweep } from "react-icons/md";
import { GlobalFilter } from "./GlobalFilter";
import swal from "sweetalert";
import uploadImg from "../../components/photos/uploadimg.png";
import sample_wizsuite from "../../sample_wizsuite.csv";
import csv from "../../components/photos/csv.png";
import FormData from "form-data";
import axios from "axios";
import axiosInstance from "../../utils/AxiosInstance.jsx"; // Import the axiosInstance

const Employees = () => {
  const navigate = useNavigate();
  // useEffect
  const dispatch = useDispatch();
  // use states
  const [inviteLink, setInviteLink] = useState("");
  // use states

  const employee_page = useSelector((state) => state.Reducer);
  // console.log("redux = ", employee_page);

  // get all emplyee
  const [table_data, setTable] = useState();
  const get_all_employee = useCallback(async () => {
    
    try {
      await axiosInstance
        .get(`GetAllEmployee`, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("table_data", response.data);
          setTable(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_all_employee();
  }, [get_all_employee]);
  // get all emplyee

  const data = useMemo(() => {
    if (table_data) {
      return table_data;
    } else {
      return [];
    }
  }, [table_data]);

  // const data = useMemo(() => MOCK_DATA);
  // useEffect

  // Create Event
  const [Create_event_modal, setCreate_event_modal] = useState(false);
  const [Add_Employee_by_link, setAdd_Employee_by_link] = useState(false);
  const [Add_Employee_bulk_import, setAdd_Employee_bulk_import] =
    useState(false);
  // Create Event

  // delte row
  async function delete_row(e) {
    console.log("id=", e);
    const a = e.toString();
    try {
      await axiosInstance
        .delete(`DeleteEmployee`, {
          httpsAgent: employee_page.HTTP_AGENT,
          data: {
            employee_id: a,
          },
        })
        .then((response) => {
          console.log("update=", response.data);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            get_all_employee();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // delte row

  // invite by link
  async function inviteByLink() {
    // console.log("inviteLink", inviteLink);
    try {
      await axiosInstance
        .post(
          `EmployeeInvite`,
          {
            work_email: inviteLink,
          },
          {
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("update=", response.data);
          if (response.status == 200) {
            const successMessage = response.data.message || "Success";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });
            setTimeout(() => {
              swal.close();
              setAdd_Employee_by_link(false);
            }, 2000);
            get_all_employee();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              // setAdd_Employee_by_link(false);
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
        // setAdd_Employee_by_link(false);
      }, 2000);
    }
  }
  // invite by link

  const COLUMNS = [
    {
      Header: "Employee Deatils",
      accessor: (row) => {
        return [`${row.first_name}`, `${row.work_email}`];
      },
      Cell: ({ row }) => (
        <>
          <div
            onClick={() => {
              navigate("/Employee_edit", {
                state: {
                  id: row.original.id,
                  first_name: row.original.first_name,
                  last_name: row.original.last_name,
                  employee_id: row.original.employee_id,
                  photo: row.original.photo,
                },
              });
            }}
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              // marginLeft: "33px",
              cursor: "pointer",
            }}
          >
            {row.original.photo ? (
              <img
                src={row.original.photo}
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
                alt=""
              />
            ) : (
              <FaUser
                style={{
                  color: "var(--Whiteblack-color)",
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
              />
            )}

            <div className="name_email">
              <p style={{ fontWeight: "bold" }}>
                {row.original.first_name} {row.original.last_name}
              </p>
              <p>{row.original.work_email}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "Employee ID",
      accessor: "employee_id",

      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
            }}
            // onClick={() => {
            //   navigate("/Event/Event_Details", {
            //     state: {
            //       name: row.original.employee_id,
            //     },
            //   });
            // }}
          >
            {row.original.employee_id}
            {/* {row.original.events_name} */}
          </p>
        </>
      ),
    },
    {
      Header: "Join Date",
      accessor: "join_date",
      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
              // color: "black",
            }}
            // onClick={() => {
            //   navigate("/Event/Event_Details", {
            //     state: {
            //       name: row.original.join_date,
            //     },
            //   });
            // }}
          >
            {row.original.join_date
              ? row.original.join_date.substring(0, 10)
              : row.original.join_date}
            {/* {row.original.events_start_date} to {row.original.events_end_date} */}
          </p>
        </>
      ),
    },
    {
      Header: "Employee Phone",
      accessor: "work_phone",
      Cell: ({ row }) => (
        <>
          <p>{row.original.work_phone}</p>
        </>
      ),
    },
    {
      Header: "Action",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <div>
          <BiEdit
            onClick={() => {
              navigate("/Employee_edit", {
                state: {
                  id: row.original.id,
                  first_name: row.original.first_name,
                  last_name: row.original.last_name,
                  employee_id: row.original.employee_id,
                  photo: row.original.photo,
                },
              });
            }}
            size="1.2em"
            style={{ color: "var(--secondary-color)", cursor: "pointer" }}
          />
          <MdOutlineDeleteSweep
            onClick={() => {
              swal({
                title: "Want to delete",
                text: "Do You Really want to delete",
                icon: "info",
                buttons: {
                  yes: "Yes",
                  no: "No",
                },
              }).then((response) => {
                if (response === "yes") {
                  delete_row(row.original.id);
                } else if (response === "no") {
                } else {
                  console.log(
                    "User closed the popup without clicking any button"
                  );
                }
              });
              // delete_row(row.original.id);
            }}
            size="1.2em"
            style={{
              color: "var(--secondary-color)",
              marginLeft: "5px",
              cursor: "pointer",
            }}
          />
          {/* <Assign id={row.original.id} /> */}
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  //routing to add employee page manually

  // upload file
  const [selectedFile, setSelectedFile] = useState();
  const fileInputRef = useRef(null);

  const submitfile = (e) => {
    e.preventDefault();
    const files = fileInputRef.current.files;
    console.log(files);

    if (files.length === 0) {
      // alert("Select File");
      console.log("file", files.length);
      swal({
        title: "Failure!",
        text: "Please Select a file",
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }

    upload_csv();
    async function upload_csv() {
      console.log(files[0]);
      var dataa = new FormData();
      dataa.append("csvFile", files[0]);

      try {
        await axiosInstance
          .post(`BulkUpload`, dataa, {
            httpsAgent: employee_page.HTTP_AGENT,
          })
          .then((response) => {
            console.log("bulk=", response.data.count);
            if (response.status == 200) {
              get_all_employee();
              const successMessage =
                `Number of rows Added ${response.data.count} and Duplicate rows are ${response.data.duplicate}` ||
                "Something went wrong";
              swal({
                title: "Success!",
                text: successMessage,
                icon: "success",
              });
              setTimeout(() => {
                swal.close();
                setAdd_Employee_bulk_import(false);
              }, 2000);
            } else {
              const errorMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Failure!",
                text: errorMessage,
                icon: "error",
              });

              // Close the SweetAlert after 3000 milliseconds (3 seconds)
              setTimeout(() => {
                swal.close();
                // setAdd_Employee_bulk_import(false);
              }, 2000);
            }
          });
      } catch (e) {
        console.log(e);
        console.log(e.response.data.error);
        const errorMessage = e.response.data.error || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
        });
        setTimeout(() => {
          swal.close();
          // setAdd_Employee_bulk_import(false);
        }, 2000);
      }
    }
  };

  // upload file
  return (
    <>
      <div className="main-container">
        {/* <MainSidebar /> */}
        <div className="allcontent">
          <Navbar />
          {/* <Breadcrumbs/> */}
          <div className="mainbox">
            {/* pop up */}
            {Create_event_modal && (
              <div className="modal">
                <div
                  onClick={() => {
                    setCreate_event_modal(!Create_event_modal);
                  }}
                  className="overlay"
                ></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2>Add Employee</h2>
                    <button
                      className="close-modal"
                      onClick={() => {
                        setCreate_event_modal(!Create_event_modal);
                      }}
                    >
                      <CgCloseR />
                    </button>
                  </div>
                  <div className="modal-body">
                    {/* <button
                      className="cusbtn popupstyle"
                      onClick={() => {
                        setCreate_event_modal(!Create_event_modal);
                        setAdd_Employee_by_link(!Add_Employee_by_link);
                      }}
                    >
                      Add Employee by Invite Link
                    </button> */}
                    <button
                      className="cusbtn popupstyle"
                      onClick={() => {
                        setCreate_event_modal(!Create_event_modal);
                        navigate("/AddEmployeeManually");
                      }}
                    >
                      Add Employee
                    </button>
                    <button
                      className="cusbtn popupstyle"
                      onClick={() => {
                        setCreate_event_modal(!Create_event_modal);
                        setAdd_Employee_bulk_import(!Add_Employee_bulk_import);
                      }}
                    >
                      Upload by CSV file
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* pop up of add employee by link*/}
            {Add_Employee_bulk_import && (
              <div className="modal">
                <div
                  onClick={() => {
                    setAdd_Employee_bulk_import(!Add_Employee_bulk_import);
                  }}
                  className="overlay"
                ></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2> Add Employee By Bulk</h2>
                    <button
                      className="close-modal"
                      onClick={() => {
                        setAdd_Employee_bulk_import(!Add_Employee_bulk_import);
                      }}
                    >
                      <CgCloseR />
                    </button>
                  </div>
                  <div className="modal-body">
                    <a
                      href={sample_wizsuite}
                      style={{
                        textAlign: "center",
                        display: "block",
                        marginBottom: "10px",
                      }}
                      download="sample_wizsuite.csv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="cusbtn">Download Sample CSV</button>
                    </a>
                    <div className="bulk_import_page">
                      <div className="setuploadfile">
                        <div className="app_upload">
                          <div className="parent">
                            <div className="file-upload">
                              {selectedFile ? (
                                <img
                                  style={{ height: "45px" }}
                                  src={csv}
                                  alt="upload"
                                />
                              ) : (
                                <img
                                  className="after_file_upload"
                                  src={uploadImg}
                                  alt="upload"
                                />
                              )}
                              {selectedFile ? (
                                <h3>{selectedFile.name}</h3>
                              ) : (
                                <h3>Upload CSV</h3>
                              )}
                              <input
                                type="file"
                                style={{ color: "var(--Whiteblack-color)" }}
                                name="file"
                                onChange={(e) => {
                                  setSelectedFile(e.target.files[0]);
                                }}
                                accept=".csv"
                                ref={fileInputRef}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button onClick={submitfile} className="cusbtn mt-1">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {Add_Employee_by_link && (
              <div className="modal">
                <div
                  onClick={() => {
                    setAdd_Employee_by_link(!Add_Employee_by_link);
                  }}
                  className="overlay"
                ></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2>Add Employee by link</h2>
                    <button
                      className="close-modal"
                      onClick={() => {
                        setAdd_Employee_by_link(!Add_Employee_by_link);
                      }}
                    >
                      <CgCloseR />
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="btninsideinput">
                      <div className="tryy">
                        <div
                          className="input-container"
                          style={{ marginTop: "10%", width: "100%" }}
                        >
                          <TextField
                            name="addressline1"
                            onChange={(e) => {
                              setInviteLink(e.target.value);
                            }}
                            className="text_input"
                            id="outlined-basic"
                            label="Invite by name or email"
                            variant="outlined"
                          />
                        </div>
                        <div className="sidebtn">
                          <button
                            onClick={() => {
                              inviteByLink();
                            }}
                            className="cusbtn_onboard"
                            style={{
                              paddingTop: "20%",
                              border: "solid",
                              borderColor: "darkslateblue",
                              borderWidth: "1px",
                              borderLeft: "none",
                            }}
                          >
                            Invite
                          </button>
                        </div>
                      </div>
                      <div className="addEmployeepopup">
                        <div className="attachicon">
                          <MdAttachFile /> <p>Private Link</p>
                        </div>
                        <button>Copy Link</button>
                      </div>
                    </div>

                    <div className="dotted_line">
                      <p className="parastylecolor" lassName>
                        Share with multiple please Download Excel Sheet
                      </p>
                    </div>
                    <div className="footerbtn">
                      <button className="cusbtn">Download Sheet</button>
                      <button className="cusbtn">Upload Sheet</button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* breadcrumbs and pop up  */}
            <div className="breadcrumbs_pop">
              <div></div>

              <button
                onClick={() => {
                  setCreate_event_modal(!Create_event_modal);
                }}
                className="cusbtn"
              >
                <BiPlusMedical size="1.2rem" />
              </button>
            </div>
            {/* breadcrumbs and pop up  */}
            {table_data ? (
              <div className="card mt-3">
                <div
                  className="department tablebox"
                  style={{ backgroundcolor: "white" }}
                >
                  {/* pagination */}
                  <div className="pages">
                    <div className="tablebottomboxleft">
                      <h1>Show</h1>
                      <span>
                        <select
                          className="selectnoofpagescolor"
                          style={{
                            padding: "4px 10px 4px 3px",
                            borderRadius: "10px",
                          }}
                          value={pageSize}
                          // className="showrows"
                          onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                          {[10, 15, 20].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>

                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                  {/* pagination */}

                  <div className="resposive_table">
                    <table className="mt-2" {...getTableProps()}>
                      <thead>
                        {headerGroups.map((headerGroups) => (
                          <tr {...headerGroups.getHeaderGroupProps()}>
                            {headerGroups.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? "🔽"
                                      : "🔼"
                                    : ""}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {/* {flight_data.EVENT_TABLE_DATA  ? (
                      <> */}

                        {page.length === 0 ? (
                          <tr>
                            <td>No data</td>
                          </tr>
                        ) : (
                          page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td
                                      style={{
                                        textalign: "center",
                                      }}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                    <button
                      className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <BiLeftArrow />
                    </button>
                    <button
                      className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <BiRightArrow />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card mt-3">
                <div
                  className="department tablebox"
                  style={{ backgroundcolor: "white" }}
                >
                  {/* pagination */}
                  <div className="pages">
                    <div className="tablebottomboxleft">
                      <h1>Show</h1>
                      <span>
                        <select
                          className="selectnoofpagescolor"
                          style={{
                            padding: "4px 10px 4px 3px",
                            borderRadius: "10px",
                          }}
                          value={pageSize}
                          // className="showrows"
                          onChange={(e) => setPageSize(Number(e.target.value))}
                        >
                          {[10, 15, 20].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select>
                      </span>
                    </div>

                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                  {/* pagination */}

                  <table className="mt-2" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? "🔽"
                                    : "🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {/* {flight_data.EVENT_TABLE_DATA  ? (
                    <> */}

                      <tr>
                        <td>No data</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="pagination">
                    <span>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{" "}
                    </span>
                    <button
                      className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      <BiLeftArrow />
                    </button>
                    <button
                      className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      <BiRightArrow />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      {/* <h1 class="text-3xl font-bold underline">
    Hello world!
  </h1> */}
    </>
  );
};

export default Employees;
