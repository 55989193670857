import React, { useCallback, useEffect, useState } from "react";
import { BiPlusMedical } from "react-icons/bi";
import { FaRegCalendarAlt } from "react-icons/fa";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as Yup from "yup";
import { CgCloseR } from "react-icons/cg";
import { useFormik } from "formik";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import swal from "sweetalert";
import { FaCircle } from "react-icons/fa";

const Leave_details = (props) => {
  // get employee by id
  const employee_page = useSelector((state) => state.Reducer);
  const [leave_detail, set_leave_detail] = useState("");
  const [leave_history, set_leave_history] = useState("");
  const [leave_modal, setLeave_modal] = useState(false);
  const [leave_details_pop_up, setLeave_details_pop_up] = useState(false);

  const get_details_byid = useCallback(async () => {
    try {
      await axiosInstance
        .post(
          `LeaveDetailEmployee`,
          {
            employee_id: props.id,
          },
          {
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
          set_leave_detail(response.data.Leave_Details);
          set_leave_history(response.data.history);
          console.log("leave history=", response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_details_byid();
  }, [get_details_byid]);
  // get employee by id

  const getClassForLeaveStatus = (status) => {
    switch (status) {
      case "Approved":
        return "approve";
      case "Pending":
        return "pending";
      case "Rejected":
        return "reject";
      default:
        return ""; // Add default class if needed
    }
  };

  const signUpSchema = Yup.object({
    leavetype_name: Yup.string().required("Select Leave Type"),
    reason: Yup.string().required("Enter a reason"),
    date_from: Yup.string().required("Select date"),
    date_to: Yup.string().required("Select date"),
  });

  const [initialValues, setinitialValues] = useState({
    leavetype_name: "",
    reason: "",
    date_from: "",
    date_to: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("leave", values);
      add_leave_type(values);
    },
  });

  useEffect(() => {
    get_all_leave();
  }, []);
  const [all_leave_type, setAll_leave_type] = useState("");

  async function get_all_leave() {
    try {
      await axiosInstance
        .get(`AllLeaveType`, {
          headers: {
            Authorization: `Bearer ${employee_page.TOKEN}`,
          },
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("single=", response.data);
          setAll_leave_type(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }

  // add leave
  async function add_leave_type(e) {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}AddLeave`, {
          leavetype_id: e.leavetype_name.match(/^\d+/)[0],
          employee_id: props.id,
          leavetype_name: e.leavetype_name,
          date_from: e.date_from,
          date_to: e.date_to,
          reason: e.reason,
        })
        .then((response) => {
          console.log("approve_reject", response.data);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            get_all_leave();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add leave

  // date format
  function formatDate(dateString) {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Define month names array
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month name
    const month = monthNames[date.getMonth()];

    // Get the day
    const day = date.getDate();

    // Get the year
    const year = date.getFullYear();

    // Return formatted date string
    return `${day} ${month}, ${year}`;
  }
  // date format

  // to change text field to date field
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlurr = () => {
    setIsFocused(false);
  };

  // to change text field to date field
  const [isFocused1, setIsFocused1] = useState(false);

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlurr1 = () => {
    setIsFocused1(false);
  };
  return (
    <>
      {/* Add pop up */}
      {leave_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setLeave_modal(!leave_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Leave Type</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setLeave_modal(!leave_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="mt-4">
                {/* <div className="input-container mt-2">
                  <TextField
                    name="leave_type"
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    value={values.leave_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Leave Type"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.leave_type && touched.leave_type ? (
                      <p className="form-error">{errors.leave_type}</p>
                    ) : null}
                  </div>
                </div> */}

                <div className="input-container mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Leave Type
                    </InputLabel>
                    <Select
                      name="leavetype_name"
                      value={values.leavetype_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="leavetype_name"
                    >
                      {leave_detail
                        ? leave_detail.map((ele, index) => {
                            return (
                              <MenuItem
                                index={index}
                                value={`${ele.id},${ele.leave_name}`}
                              >
                                {ele.leave_name} ({ele.remaining_days}) 
                              </MenuItem>
                            );
                          })
                        : ""}
                    </Select>
                  </FormControl>

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.leavetype_name && touched.leavetype_name ? (
                      <p className="form-error">{errors.leavetype_name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="reason"
                    value={values.reason}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Reason"
                    variant="outlined"
                    type="text"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.reason && touched.reason ? (
                      <p className="form-error">{errors.reason}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  {isFocused ? (
                    <TextField
                      type="date"
                      name="date_from"
                      className="text_input"
                      variant="outlined"
                      value={values.date_from}
                      onChange={handleChange}
                      onBlur={handleBlurr}
                      id="outlined-basic"
                      label="Date From"
                    />
                  ) : (
                    <TextField
                      type="text"
                      name="date_from"
                      className="text_input"
                      variant="outlined"
                      value={values.date_from}
                      onFocus={handleFocus}
                      id="outlined-basic"
                      label="Date From"
                    />
                  )}

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.date_from && touched.date_from ? (
                      <p className="form-error">{errors.date_from}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  {isFocused1 ? (
                    <TextField
                      type="date"
                      name="date_to"
                      className="text_input"
                      variant="outlined"
                      value={values.date_to}
                      onChange={handleChange}
                      onBlur={handleBlurr1}
                      id="outlined-basic"
                      label="Date To"
                    />
                  ) : (
                    <TextField
                      type="text"
                      name="date_to"
                      className="text_input"
                      variant="outlined"
                      value={values.date_to}
                      onFocus={handleFocus1}
                      id="outlined-basic"
                      label="Date To"
                    />
                  )}
                  {/* <TextField
                    type="date"
                    name="date_to"
                    className="text_input"
                    variant="outlined"
                    value={values.date_to}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="outlined-basic"
                    label="Date To"
                  /> */}

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.date_to && touched.date_to ? (
                      <p className="form-error">{errors.date_to}</p>
                    ) : null}
                  </div>
                </div>
                <div className="text-center mb-3">
                  <button type="submit" className="cusbtn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* Add pop up*/}


      <div className="headingbox">
        <div className="headingleftbox">
          <h1>Leave balance</h1>
          <p>Here is the pending leave</p>
        </div>
        <div className="headingrightbox">
          <button
            className="editbutton"
            onClick={() => {
              setLeave_modal(!leave_modal);
            }}
          >
            <BiPlusMedical />
          </button>
        </div>
      </div>

      <div className="leave_type mt-3">
        {leave_detail
          ? leave_detail.map((ele, index) => {
              return (
                <>
                  <div className="leave_card" key={index}>
                    <div className="leave_bal_icon">
                      <div className="icon_leave">
                        <FaRegCalendarAlt />
                      </div>
                    </div>
                    <h1 className="mt-1 text-center">{ele.leave_name}</h1>
                    <div className="avil_remain">
                      <p>Availed</p>
                      <p>
                        {parseInt(ele.leave_days) -
                          parseInt(ele.remaining_days)}
                      </p>
                    </div>
                    <div className="avil_remain">
                      <p>Remaining</p>
                      <p>{ele.remaining_days}</p>
                    </div>
                  </div>
                </>
              );
            })
          : ""}
      </div>

      {/* leave history  */}
      <h1 className="mt-2">Leave History</h1>
      <div className="leave_history mt-2">
        {leave_history
          ? leave_history.map((ele, index) => {
              return (
                <>
                  <div className="card history_leave mt-1" index={index}>
                    <div className="applied_on">
                      <p>Applied on: {formatDate(ele.created_at)}</p>
                      <h1>
                        {formatDate(ele.date_from)} To {formatDate(ele.date_to)}
                      </h1>
                      <p>{ele.reason}</p>
                      <p>{ele.leavetype_name}</p>
                    </div>
                    <div className="approve_rej apprejbox">
                      {/* <p>2023-05-18 22:31:51</p> */}
                      <h1 style={{ textAlign: "right" }}>
                        {ele.days_count} Day
                      </h1>
                      <p
                        style={{ textAlign: "right" }}
                        className={getClassForLeaveStatus(ele.leavestatus)}
                      >
                        <FaCircle />
                        {ele.leavestatus}
                      </p>
                      <Leave_detail_pop
                        get_details_byid={get_details_byid}
                        LeaveId={ele.LeaveId}
                        created_at={ele.created_at}
                        start_date={ele.date_from}
                        end_date={ele.date_to}
                        leave_type={ele.leavetype_name}
                        total_days_leave={ele.days_count}
                        leave_reason={ele.reason}
                        leave_status={ele.leavestatus}
                      />
                    </div>
                  </div>
                </>
              );
            })
          : " NO Leave History Found"}
      </div>
    </>
  );
};

export default Leave_details;

const Leave_detail_pop = (props) => {
  const [leave_details_pop_up, setLeave_details_pop_up] = useState(false);
  const employee_page = useSelector((state) => state.Reducer);

  const getClassForLeaveStatus = (status) => {
    switch (status) {
      case "Approved":
        return "approvebtn";
      case "Pending":
        return "pendingbtn";
      case "Rejected":
        return "rejectbtn";
      default:
        return ""; // Add default class if needed
    }
  };

  // date format
  function formatDate(dateString) {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Define month names array
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month name
    const month = monthNames[date.getMonth()];

    // Get the day
    const day = date.getDate();

    // Get the year
    const year = date.getFullYear();

    // Return formatted date string
    return `${day} ${month}, ${year}`;
  }
  // date format

  // approve/reject api
  async function approve_reject(id, status) {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}EditLeave`, {
          leave_id: id,
          leavestatus: status,
        })
        .then((response) => {
          console.log("approve_reject", response.data);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            setLeave_details_pop_up(!leave_details_pop_up);
            props.get_details_byid();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // approve/reject api
  return (
    <>
      {/* Add pop up */}
      {leave_details_pop_up && (
        <div className="modal">
          <div
            onClick={() => {
              setLeave_details_pop_up(!leave_details_pop_up);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>{props.leave_type} Leave</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setLeave_details_pop_up(!leave_details_pop_up);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <div className="pop_area">
                <div className="applied_area flexboxall">
                  <span>Applied On {formatDate(props.created_at)}</span>
                  <p className={getClassForLeaveStatus(props.leave_status)}>
                    <FaCircle size="0.7em" />
                    {props.leave_status}
                  </p>
                </div>
                <div className="flexboxall">
                  <p>
                    <span>Start From</span>
                    <br />
                    {formatDate(props.start_date)}
                  </p>
                  <p style={{ textAlign: "right" }}>
                    <span>End From</span>
                    <br />
                    {formatDate(props.end_date)}
                  </p>
                </div>
                <p>
                  <span>Total Days</span>
                  <br />
                  {props.total_days_leave}
                </p>
                <p>
                  <span>Reason</span>
                  <br />
                  {props.leave_reason}
                </p>
                {props.leave_status == "Pending" ? (
                  <div className="btnareaapp">
                    <button
                      onClick={() => {
                        approve_reject(props.LeaveId, "Rejected");
                      }}
                      className="rejectbtnpop"
                    >
                      Reject
                    </button>
                    <button
                      onClick={() => {
                        approve_reject(props.LeaveId, "Approved");
                      }}
                      className="approvebtnpop"
                    >
                      Approve
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {props.leave_status == "Approved" ? (
                  <div className="apprej_area">
                    <h4>Approved By</h4>
                    <p>Prateek Punj</p>
                  </div>
                ) : (
                  ""
                )}

                {props.leave_status == "Rejected" ? (
                  <div className="apprej_area">
                    <h4>Rejected by</h4>
                    <p>Prateek Punj</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Add pop up*/}
      <button
        onClick={() => {
          setLeave_details_pop_up(!leave_details_pop_up);
        }}
        className="cusbtn"
      >
        View
      </button>
    </>
  );
};
