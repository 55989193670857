import "./App.css";
import "./index.css";
import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/LoginScreens/Login";
import Forgetpass from "./pages/LoginScreens/Forgetpass";
import Dashboard from "./pages/Dashboard/Dashboard";
import Employees from "./pages/Employees/Employees";
import Breadcrumbs from "./breadcrumbs/Breadcrumbs.jsx";
import Attendancedrop from "./pages/Attendance/Attendancedrop.jsx";
import Attendance from "./pages/Attendance/Attendance";
import Regularization from "./pages/Attendance/Regularization/Regularization.jsx";
import Leave from "./pages/Attendance/Leave/Leave.jsx";
import Compoff from "./pages/Attendance/Compoff/Compoff.jsx";
import Onboard_user from "./pages/Onboard/Onboard.jsx";
import Onboard_setting from "./pages/Onboard/Onboard_setting.jsx";

import Payroll from "./pages/Payroll/Payroll.jsx";

import Setting from "./pages/Setting/Setting.jsx";
import Profile from "./pages/AdminProfile/Profile";
import Sign_Up from "./pages/LoginScreens/Sign_Up";
import Otp from "./pages/LoginScreens/Otp";
import Newpassword from "./pages/LoginScreens/Newpassword";
import Employee_edit from "./pages/Employees/Deatil_pages/Employee_edit";
import "./pages/i18n";
import MainSidebar from "./components/Sidebar/MainSidebar";
import Offboard from "./pages/Offboard/Offboard";
import Payroll_Dash from "./pages/Payroll/Payroll_dash/Payroll_Dash";
import Employee_sal from "./pages/Payroll/Emolyee_sal/Employee_sal.jsx";
import Payslip from "./pages/Payroll/Payslip/Payslip";
import Payrun from "./pages/Payroll/Payrun/Payrun";
import Salary_details from "./pages/Payroll/Emolyee_sal/Salary_details";
import AddEmployeeManually from "./pages/Employees/AddEmployeeManually.jsx";
import EmailVerify from "./pages/LoginScreens/EmailVerify";
import TandC from "./pages/LoginScreens/MultiStepForm/Tandc.jsx";
import Privacypolicy from "./pages/LoginScreens/MultiStepForm/Privacypolicy.jsx";

function App() {
  return (
    <Router>
      {/* <SideBar> */}
      {/* <MainSidebar/> */}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/T&C" element={<TandC />} />
        <Route path="/privacy_policy" element={<Privacypolicy />} />
        <Route
          path="/Dashboard"
          element={
            <MainSidebar>
              <Dashboard />
            </MainSidebar>
          }
        />
        <Route
          path="/Employees"
          element={
            <MainSidebar>
              <Employees />
            </MainSidebar>
          }
        />
        <Route
          path="/Employee_edit"
          element={
            <MainSidebar>
              <Employee_edit />
            </MainSidebar>
          }
        />

        <Route
          path="/AddEmployeeManually"
          element={
            <MainSidebar>
              <AddEmployeeManually />
            </MainSidebar>
          }
        />

        <Route
          path="/Attendancedrop"
          element={
            <MainSidebar>
              <Attendancedrop />
            </MainSidebar>
          }
        />
        <Route
          path="/Attendance"
          element={
            <MainSidebar>
              <Attendance />
            </MainSidebar>
          }
        />
        <Route
          path="/Regularization"
          element={
            <MainSidebar>
              <Regularization />
            </MainSidebar>
          }
        />
        <Route
          path="/Leave"
          element={
            <MainSidebar>
              <Leave />
            </MainSidebar>
          }
        />
        <Route
          path="/Compoff"
          element={
            <MainSidebar>
              <Compoff />
            </MainSidebar>
          }
        />

        <Route
          path="/Onboard"
          element={
            <MainSidebar>
              <Onboard_user />
            </MainSidebar>
          }
        />
        <Route
          path="/Onboard_setting"
          element={
            <MainSidebar>
              <Onboard_setting />
            </MainSidebar>
          }
        />

        <Route
          path="/Offboard/Offboard.jsx"
          element={
            <MainSidebar>
              <Offboard />
            </MainSidebar>
          }
        />

        <Route
          path="/Payroll/Emolyee_sal/Salary_details"
          element={
            <MainSidebar>
              <Salary_details />
            </MainSidebar>
          }
        />

        <Route
          path="/Payroll/Payroll.jsx"
          element={
            <MainSidebar>
              <Payroll />
            </MainSidebar>
          }
        />

        <Route
          path="/Payroll/Payroll_dash/Payroll_Dash"
          element={
            <MainSidebar>
              <Payroll_Dash />
            </MainSidebar>
          }
        />

        <Route
          path="/Payroll/Emolyee_sal/Employee_sal.jsx"
          element={
            <MainSidebar>
              <Employee_sal />
            </MainSidebar>
          }
        />

        <Route
          path="/Payroll/Payslip/Payslip.jsx"
          element={
            <MainSidebar>
              <Payslip />
            </MainSidebar>
          }
        />

        <Route
          path="/Payroll/Payrun/Payrun.jsx"
          element={
            <MainSidebar>
              <Payrun />
            </MainSidebar>
          }
        />

        <Route
          path="/Setting"
          element={
            <MainSidebar>
              <Setting />
            </MainSidebar>
          }
        />

        <Route
          path="/AdminProfile/Profile"
          element={
            <MainSidebar>
              <Profile />
            </MainSidebar>
          }
        />

        <Route path="/Forgetpass" element={<Forgetpass />} />
        <Route path="/Otp" element={<Otp />} />
        <Route path="/Newpassword" element={<Newpassword />} />
        <Route path="/Sign_Up" element={<Sign_Up />} />
        <Route path="./breadcrumbs/Breadcrumbs.jsx" element={<Breadcrumbs />} />
        <Route path="/EmailVerify" element={<EmailVerify />} />

        <Route path="*" element={<> not found</>} />
      </Routes>
      {/* </SideBar> */}
    </Router>
  );
}

export default App;
