import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { Checkbox } from "./Checkbox_request";
import { BiLeftArrow, BiEdit } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { CgCloseR } from "react-icons/cg";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { MdDelete } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import swal from "sweetalert";
import FormData from "form-data";
// import axiosInstance from "axiosInstance";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import uploadImg from "../../../components/photos/uploadimg.png";
import onbaord from "../../../onbaord.csv";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstanceInstance
import csv from "../../../components/photos/csv.png";
import { FaUser } from "react-icons/fa";
import { GlobalFilter } from "../../Employees/GlobalFilter";
import MOCK_DATA from "./Customers.json";
import { BiSearchAlt } from "react-icons/bi";
import { MdOutlineDeleteSweep } from "react-icons/md";
import dp from "../../../components/photos/dp.jpg";

const Process = () => {
  const navigate = useNavigate();
  // useEffect
  const dispatch = useDispatch();

  const employee_page = useSelector((state) => state.Reducer);

  // get all emplyee
  const [table_data, setTable] = useState();
  const get_all_employee = useCallback(async () => {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}getonboardByStatus`, {
          status: "PENDING",
        })
        .then((response) => {
          console.log("table_data", response.data);
          setTable(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_all_employee();
  }, [get_all_employee]);
  // get all emplyee

  const data = useMemo(() => {
    if (table_data) {
      return table_data;
    } else {
      return [];
    }
  }, [table_data]);

  const COLUMNS = [
    {
      Header: "Profile Info",
      accessor: (row) => {
        return [`${row.first_name}`, `${row.email}`];
      },
      Cell: ({ row }) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              // marginLeft: "33px",
              cursor: "pointer",
            }}
          >
            {row.original.photo ? (
              <img
                src={row.original.photo}
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
                alt=""
              />
            ) : (
              <FaUser
                style={{
                  color: "var(--Whiteblack-color)",
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
              />
            )}

            <div className="name_email">
              <p style={{ fontWeight: "bold" }}>
                {row.original.first_name} {row.original.last_name}
              </p>
              <p>{row.original.email}</p>
            </div>
          </div>
        </>
      ),
    },

    {
      Header: "Status",
      accessor: "step_complete",
      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
              // color: "black",
            }}
            onClick={() => {
              navigate("/Event/Event_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
          >
            {row.original.step_complete}/3 Completed
            {/* {row.original.events_start_date} to {row.original.events_end_date} */}
          </p>
        </>
      ),
    },

    {
      Header: "Action",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <div>
          <BiEdit
            onClick={() => {
              navigate("/Employees/Deatil_pages/Employee_edit", {
                state: {
                  id: row.original.id,
                  first_name: row.original.first_name,
                  last_name: row.original.last_name,
                  onboard: "onboard",
                  // employee_id: row.original.employee_id,
                  // photo: row.original.photo,
                },
              });
            }}
            size="1.2em"
            style={{
              color: "var(--secondary-color)",
              cursor: "pointer",
              marginRight: "10px",
            }}
          />
          {/* <button className="cusbtn_onboard">View</button> */}
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // pop up rows add
  const [add_candiadate, set_add_candiadate] = useState(false);
  const initialValues = {
    rows: [
      {
        first_name: "",
        last_name: "",
        email: "",
        personal_email: "",
        join_date: "",
        department_id: "",
      },
    ],
  };

  const signUpSchema = Yup.object().shape({
    rows: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string().required("Required"),
        last_name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        personal_email: Yup.string()
          .email("Invalid email")
          .required("Required"),
        join_date: Yup.string().required("Required"),
        department_id: Yup.string().required("Required"),
      })
    ),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      // console.log("Form submitted:", values);
      add_deatil(values.rows);
    },
  });

  // add employee deatils
  async function add_deatil(e) {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}AddOnboard`, {
          data: e,
        })
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              get_all_employee();
              swal.close();
              set_add_candiadate(!add_candiadate);
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              set_add_candiadate(!add_candiadate);
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add employee deatils

  const addRow = () => {
    setFieldValue("rows", [
      ...values.rows,
      {
        first_name: "",
        last_name: "",
        email: "",
        personal_email: "",
        join_date: "",
        department_id: "",
      },
    ]);
  };

  const removeRow = (index) => {
    const newRows = [...values.rows];
    newRows.splice(index, 1);
    setFieldValue("rows", newRows);
  };

  // pop up rows add

  const [Add_Employee_bulk_import, setAdd_Employee_bulk_import] =
    useState(false);
  // upload file
  const [selectedFile, setSelectedFile] = useState();
  const fileInputRef = useRef(null);

  const submitfile = (e) => {
    e.preventDefault();
    const files = fileInputRef.current.files;
    console.log(files);

    if (files.length === 0) {
      // alert("Select File");
      console.log("file", files.length);
      swal({
        title: "Failure!",
        text: "Please Select a file",
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return;
    }

    upload_csv();
    async function upload_csv() {
      console.log(files[0]);
      var dataa = new FormData();
      dataa.append("csvFile", files[0]);

      try {
        await axiosInstance
          .post(`${employee_page.GLOBAL_API}uploadByCsv`, dataa)
          .then((response) => {
            console.log("bulk=", response.data.count);
            if (response.status == 200) {
              const successMessage =
                `Number of rows Added ${response.data.count} and Duplicate rows are ${response.data.duplicate}` ||
                "Something went wrong";
              swal({
                title: "Success!",
                text: successMessage,
                icon: "success",
              });
              setTimeout(() => {
                swal.close();
                get_all_employee();
                setAdd_Employee_bulk_import(false);
              }, 2000);
            } else {
              const errorMessage =
                response.data.message || "Something went wrong";
              swal({
                title: "Failure!",
                text: errorMessage,
                icon: "error",
              });

              // Close the SweetAlert after 3000 milliseconds (3 seconds)
              setTimeout(() => {
                swal.close();
                // setAdd_Employee_bulk_import(false);
              }, 2000);
            }
          });
      } catch (e) {
        console.log(e);
        console.log(e.response.data.error);
        const errorMessage = e.response.data.error || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
        });
        setTimeout(() => {
          swal.close();
          // setAdd_Employee_bulk_import(false);
        }, 2000);
      }
    }
  };

  // upload file

  // get_all_department api

  const [department_arr, setDepartment_arr] = useState("");
  async function get_all_department() {
    try {
      await axiosInstance
        .get(`${employee_page.GLOBAL_API}AllDepartment`)
        .then((response) => {
          // console.log("d", response.data);
          setDepartment_arr(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  useEffect(() => {
    get_all_department();
  }, []);
  // get_all_department api

  // to change text field to date field
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlurr = () => {
    setIsFocused(false);
  };

  return (
    <>
      <div className="breadcrumbs_pop">
        <div></div>
        <div className="appr_reject">
          <button
            onClick={() => {
              set_add_candiadate(!add_candiadate);
            }}
            className="cusbtn"
          >
            <BiPlusMedical size="1rem" />
          </button>
        </div>
      </div>

      <div
        className="mainbox mt-2"
        style={{
          boxShadow: "var(--box-shadow)",
          borderRadius: "var(--border-radius)",
          marginRight: "10px",
          background: "var(--card-color)",
          marginLeft: "10px",
        }}
      >
        {/* pop up of add bulk*/}
        {Add_Employee_bulk_import && (
          <div className="modal">
            <div
              onClick={() => {
                setAdd_Employee_bulk_import(!Add_Employee_bulk_import);
              }}
              className="overlay"
            ></div>
            <div className="modal-content">
              <div className="modal-header">
                <h2> Add Onboard By Bulk</h2>
                <button
                  className="close-modal"
                  onClick={() => {
                    setAdd_Employee_bulk_import(!Add_Employee_bulk_import);
                  }}
                >
                  <CgCloseR />
                </button>
              </div>
              <div className="modal-body">
                <a
                  href={onbaord}
                  style={{
                    textAlign: "center",
                    display: "block",
                    marginBottom: "10px",
                  }}
                  download="onbaord.csv"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="cusbtn">
                    Download CSV for Add Onboard
                  </button>
                </a>
                <div className="bulk_import_page">
                  <div className="setuploadfile">
                    <div className="app_upload">
                      <div className="parent">
                        <div className="file-upload">
                          {selectedFile ? (
                            <img
                              style={{ height: "45px" }}
                              src={csv}
                              alt="upload"
                            />
                          ) : (
                            <img
                              className="after_file_upload"
                              src={uploadImg}
                              alt="upload"
                            />
                          )}
                          {selectedFile ? (
                            <h3>{selectedFile.name}</h3>
                          ) : (
                            <h3>Upload CSV file for Onboard</h3>
                          )}
                          <input
                            type="file"
                            style={{ color: "var(--Whiteblack-color)" }}
                            name="file"
                            onChange={(e) => {
                              setSelectedFile(e.target.files[0]);
                            }}
                            accept=".csv"
                            ref={fileInputRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button onClick={submitfile} className="cusbtn mt-1">
                    Upload CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* pop up of add bulk*/}

        {/* pop up */}
        {add_candiadate && (
          <div className="modal">
            <div
              onClick={() => {
                set_add_candiadate(!add_candiadate);
              }}
              className="overlay"
            ></div>
            <div className="modal_content_large">
              <div className="modal-header">
                <h2>Add Candidate</h2>
                <button
                  className="close-modal"
                  onClick={() => {
                    set_add_candiadate(!add_candiadate);
                  }}
                >
                  <CgCloseR />
                </button>
              </div>

              <div className="onboard_multiple  mt-1">
                <h1>Add Multiple</h1>
                <div className="downl_up">
                  <button
                    className="cusbtn mr-1"
                    onClick={() => {
                      set_add_candiadate(!add_candiadate);
                      setAdd_Employee_bulk_import(!Add_Employee_bulk_import);
                    }}
                  >
                    Bulk Upload
                  </button>
                  {/* <button className="cusbtn mr-1">Download XLS,CSV</button> */}
                  {/* <button className="cusbtn">Upload XLS,CSV</button> */}
                </div>
              </div>

              {/* add more rows  */}
              <div>
                <form onSubmit={handleSubmit}>
                  {values.rows.map((row, index) => (
                    <div
                      key={index}
                      className="mt-2"
                      style={{
                        display: "flex",
                        padding: "0px 10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="inputbox" style={{ width: "15%" }}>
                        <TextField
                          fullWidth
                          name={`rows[${index}].first_name`}
                          label="First Name"
                          value={row.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.rows &&
                        touched.rows[index] &&
                        errors.rows &&
                        errors.rows[index] &&
                        errors.rows[index].first_name ? (
                          <p className="form-error">
                            {errors.rows[index].first_name}
                          </p>
                        ) : null}
                      </div>

                      <div className="inputbox" style={{ width: "15%" }}>
                        <TextField
                          fullWidth
                          name={`rows[${index}].last_name`}
                          label="Last Name"
                          value={row.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.rows &&
                        touched.rows[index] &&
                        errors.rows &&
                        errors.rows[index] &&
                        errors.rows[index].last_name ? (
                          <p className="form-error">
                            {errors.rows[index].last_name}
                          </p>
                        ) : null}
                      </div>

                      <div className="inputbox" style={{ width: "15%" }}>
                        <TextField
                          fullWidth
                          name={`rows[${index}].email`}
                          label="Email"
                          value={row.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.rows &&
                        touched.rows[index] &&
                        errors.rows &&
                        errors.rows[index] &&
                        errors.rows[index].email ? (
                          <p className="form-error">
                            {errors.rows[index].email}
                          </p>
                        ) : null}
                      </div>

                      <div className="inputbox" style={{ width: "15%" }}>
                        <TextField
                          fullWidth
                          name={`rows[${index}].personal_email`}
                          label="Work Email"
                          value={row.personal_email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.rows &&
                        touched.rows[index] &&
                        errors.rows &&
                        errors.rows[index] &&
                        errors.rows[index].personal_email ? (
                          <p className="form-error">
                            {errors.rows[index].personal_email}
                          </p>
                        ) : null}
                      </div>

                      <div className="inputbox" style={{ width: "15%" }}>
                        {isFocused ? (
                          <TextField
                            fullWidth
                            name={`rows[${index}].join_date`}
                            label="Join Date"
                            value={row.join_date}
                            type="date"
                            onChange={handleChange}
                            onBlur={handleBlurr}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            name={`rows[${index}].join_date`}
                            label="Join Date"
                            type="text"
                            onFocus={handleFocus}
                          />
                        )}

                        {touched.rows &&
                        touched.rows[index] &&
                        errors.rows &&
                        errors.rows[index] &&
                        errors.rows[index].join_date ? (
                          <p className="form-error">
                            {errors.rows[index].join_date}
                          </p>
                        ) : null}
                      </div>

                      <div className="inputbox" style={{ width: "15%" }}>
                        <TextField
                          fullWidth
                          select
                          name={`rows[${index}].department_id`}
                          label="Department"
                          value={row.department_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {department_arr
                            ? department_arr.map((employee) => (
                                <MenuItem key={employee.id} value={employee.id}>
                                  {employee.department_name}
                                </MenuItem>
                              ))
                            : ""}
                        </TextField>

                        {touched.rows &&
                        touched.rows[index] &&
                        errors.rows &&
                        errors.rows[index] &&
                        errors.rows[index].department_id ? (
                          <p className="form-error">
                            {errors.rows[index].department_id}
                          </p>
                        ) : null}
                      </div>

                      {index === values.rows.length - 1 ? (
                        <IoMdAddCircle
                          size="2em"
                          style={{ color: "var(--primary-color)" }}
                          className="mb-1"
                          onClick={addRow}
                        />
                      ) : (
                        <MdDelete
                          style={{ color: "#be202e" }}
                          size="2em"
                          className="mb-1"
                          onClick={() => removeRow(index)}
                        />
                      )}
                    </div>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px",
                    }}
                  >
                    <button className="cusbtn" onClick={() => {}}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              {/* add more rows  */}
            </div>
          </div>
        )}
        {/* pop up*/}

        {table_data ? (
          <div className="mt-2">
            <div
              className="department tablebox"
              style={{ backgroundcolor: "white" }}
            >
              {/* pagination */}
              <div className="pages">
                <div className="tablebottomboxleft">
                  <h1>Show</h1>
                  <span>
                    <select
                      className="selectnoofpagescolor"
                      style={{
                        padding: "4px 10px 4px 3px",
                        borderRadius: "10px",
                      }}
                      value={pageSize}
                      // className="showrows"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
              {/* pagination */}

              <table className="mt-2" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {/* {flight_data.EVENT_TABLE_DATA  ? (
                      <> */}

                  {page.length === 0 ? (
                    <tr>
                      <td>No data</td>
                    </tr>
                  ) : (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                style={{
                                  textalign: "center",
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <button
                  className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiLeftArrow />
                </button>
                <button
                  className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiRightArrow />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="department tablebox mt-2"
              style={{ backgroundcolor: "white" }}
            >
              {/* pagination */}
              <div className="pages">
                <div className="tablebottomboxleft">
                  <h1>Show</h1>
                  <span>
                    <select
                      className="selectnoofpagescolor"
                      style={{
                        padding: "4px 10px 4px 3px",
                        borderRadius: "10px",
                      }}
                      value={pageSize}
                      // className="showrows"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
              {/* pagination */}

              <table className="mt-2" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  <tr>
                    <td>No data</td>
                  </tr>
                </tbody>
              </table>
              <div className="pagination">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <button
                  className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiLeftArrow />
                </button>
                <button
                  className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiRightArrow />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Process;
