import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { FaEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance

const Company_Settings = (props) => {
  const [editInput, setEditInput] = useState(true);
  const company = useSelector((state) => state.Reducer);
  // console.log(company);
  // Employee details edit start

  const signUpSchema = Yup.object({
    // company_name: Yup.string().required("Enter Company Name"),
    // favicon: Yup.mixed().required("Select Company Favicon").test("fileSize", "Max Size 2MB", (file) => {
    //   if (file) {
    //     return file.size <= 2000000;
    //   } else {
    //     return true;
    //   }
    // }),

    // file: Yup.mixed().test("fileSize", "Max Size 1MB", async (file) => {
    //   if (file) {
    //     console.log(file);

    //     if (
    //       file.startsWith("https://wizsuite-data.s3.ap-south-1.amazonaws.com/")
    //     ) {
    //       return true;
    //     } else {
    //       return file.size <= 1000000;
    //     }
    //   } else {
    //     return true;
    //   }
    // }),

    contact_person: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Name must contain only alphabets"
    ),
    address: Yup.string().min(2, "Enter a valid address"),
    country: Yup.string().matches(/^[A-Za-z\s]+$/, "Enter a Country"),
    city: Yup.string().matches(/^[A-Za-z\s]+$/, "Enter a City"),
    stateprov: Yup.string().matches(/^[A-Za-z\s]+$/, "Enter a State"),
    postalcode: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Enter your 6 Digit Postal code")
      .max(6, "Enter your 6 Digit Postal code"),
    email: Yup.string().matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Invalid email address"
    ),
    phonenumber: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    mobilenumber: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    websiteurl: Yup.string().required("Enter Website Url"),
  });

  const [initialValues, setinitialValues] = useState({
    company_name: "",
    address: "",
    contact_person: "",
    country: "",
    city: "",
    stateprov: "",
    postalcode: "",
    email: "",
    phonenumber: "",
    mobilenumber: "",
    websiteurl: "",
    favicon: "",
    file: "",
  });

  useEffect(() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
      get_company_setting();
    }
  }, [props.tabStyle]);

  // get_company_setting api

  async function get_company_setting() {
    try {
      await axiosInstance
        .get(`${company.GLOBAL_API}CompanyDetail`)
        .then((response) => {
          // console.log(response.data[0]);
          setinitialValues({
            company_name: response.data[0].company_name,
            address: response.data[0].Address,
            contact_person: response.data[0].contact_person,
            country: response.data[0].country,
            city: response.data[0].city,
            stateprov: response.data[0].state,
            postalcode: response.data[0].pincode,
            email: response.data[0].email,
            phonenumber: response.data[0].phone,
            mobilenumber: response.data[0].mobile,
            websiteurl: response.data[0].website_url,
            favicon: response.data[0].company_favicon,
            file: response.data[0].company_logo,
          });
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      update_details(values);
    },
  });

  // put company deatils
  async function update_details(e) {
    var dataa = new FormData();
    dataa.append("company_name", e.company_name);
    dataa.append("address", e.address);
    dataa.append("contact_person", e.contact_person);
    dataa.append("country", e.country);
    dataa.append("city", e.city);
    dataa.append("stateprov", e.stateprov);
    dataa.append("postalcode", e.postalcode);
    dataa.append("phonenumber", e.phonenumber);
    dataa.append("mobilenumber", e.mobilenumber);
    dataa.append("websiteurl", e.websiteurl);
    // dataa.append("company_favicon", e.favicon);
    // dataa.append("company_logo", e.file);

    try {
      await axiosInstance
        .put(`${company.GLOBAL_API}CompanyEdit`, dataa, {
          headers: {
            Authorization: `Bearer ${company.TOKEN}`,
          },
          httpsAgent: company.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("update=", response);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
           
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put company deatils
  const { t } = useTranslation();

  // upload button

  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  // upload logo file file
  const [file, setFile] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false); // Flag variable
  const [logo_err, setlogo_err] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDropfile = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    setIsFileSelected(true); // Set flag to true when file is selected
    setlogo_err(null);
  };

  const handleFileInput = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFieldValue("file", selectedFile);
    setIsFileSelected(true); // Set flag to true when file is selected
    setlogo_err(null);
  };
  // upload logo file file

  // upload favicon file file
  const [favicon, setFavicon] = useState(null);
  const [isFileSelected_favi, setIsFileSelected_favi] = useState(false);
  const [favicon_err, setFavicon_err] = useState(null);

  const handleDragOver_favicon = (e) => {
    e.preventDefault();
  };

  const handleDragEnter_favicon = (e) => {
    e.preventDefault();
  };

  const handleDragLeave_favicon = (e) => {
    e.preventDefault();
  };

  const handleDropfile_favicon = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFavicon(droppedFile);
    setIsFileSelected_favi(true);
    setFavicon_err(null);
  };

  const handleFileInput_favicon = (e) => {
    const selectedFile = e.target.files[0];
    setFavicon(selectedFile);
    setFieldValue("favicon", e.currentTarget.files[0]);
    // console.log(e.currentTarget.files[0]);
    setIsFileSelected_favi(true);
    setFavicon_err(null);
  };

  // for logo and favicon
  async function update_logo_fav(e) {
    var dataa = new FormData();
    if (e == "logo") {
      dataa.append("company_logo", file);

      if (file.size >= 1000000) {
        setlogo_err("Max Size 1MB");
        setIsFileSelected(false);
        return;
      }
    } else if (e == "favi") {
      dataa.append("company_favicon", favicon);

      if (favicon.size >= 1000000) {
        setFavicon_err("Max Size 1MB");
        setIsFileSelected_favi(false);
        return;
      }
    }
    
    try {
      await axiosInstance
        .put(`${company.GLOBAL_API}CompanyEdit`, dataa, {
          headers: {
            Authorization: `Bearer ${company.TOKEN}`,
          },
          httpsAgent: company.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("update=", response);
          if (response.status == 200) {
            setIsFileSelected(false);
            setIsFileSelected_favi(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            window.location.reload();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // for logo and favicon

  return (
    <>
      <div className="company_edit">
        <FaEdit
          onClick={() => {
            setEditInput(!editInput);
          }}
          className="editbutton"
        />
      </div>
      <form onSubmit={handleSubmit} onDragEnter={handleDrag}>
        <div className="setting_input_boxes mt-2">
          <div className="input-container">
            <TextField
              name="company_name"
              value={values.company_name}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                readOnly: true,
              }}
              disabled={true}
              className="text_input"
              id="outlined-basic"
              label="Company name"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.company_name && touched.company_name ? (
                <p className="form-error">{errors.company_name}</p>
              ) : null}
            </div>
          </div>

          <div className="input-container">
            <TextField
              name="contact_person"
              value={values.contact_person}
              onChange={handleChange}
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(
                  /[^A-Za-z\s]/g,
                  ""
                );
              }}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="contact person"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.contact_person && touched.contact_person ? (
                <p className="form-error">{errors.contact_person}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="setting_input_boxes mt-2">
          <div className="input-container">
            <TextField
              name="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="Address"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.address && touched.address ? (
                <p className="form-error">{errors.address}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="setting_input_boxes mt-2">
          <div className="input-container">
            <TextField
              name="country"
              value={values.country}
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(
                  /[^A-Za-z\s]/g,
                  ""
                );
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="Country"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.city && touched.city ? (
                <p className="form-error">{errors.city}</p>
              ) : null}
            </div>
          </div>
          <div className="input-container">
            <TextField
              name="city"
              value={values.city}
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(
                  /[^A-Za-z\s]/g,
                  ""
                );
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="City"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.city && touched.city ? (
                <p className="form-error">{errors.city}</p>
              ) : null}
            </div>
          </div>
          <div className="input-container">
            <TextField
              name="stateprov"
              value={values.stateprov}
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(
                  /[^A-Za-z\s]/g,
                  ""
                );
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="State/Province"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.stateprov && touched.stateprov ? (
                <p className="form-error">{errors.stateprov}</p>
              ) : null}
            </div>
          </div>
          <div className="input-container">
            <TextField
              name="postalcode"
              value={values.postalcode}
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="Postal Code"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.postalcode && touched.postalcode ? (
                <p className="form-error">{errors.postalcode}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="setting_input_boxes mt-2">
          <div className="input-container">
            <TextField
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="Email"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.email && touched.email ? (
                <p className="form-error">{errors.email}</p>
              ) : null}
            </div>
          </div>
          <div className="input-container">
            <TextField
              name="phonenumber"
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
              }}
              value={values.phonenumber}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.phonenumber && touched.phonenumber ? (
                <p className="form-error">{errors.phonenumber}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="setting_input_boxes mt-2">
          <div className="input-container">
            <TextField
              name="mobilenumber"
              value={values.mobilenumber}
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="Mobile Number"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.mobilenumber && touched.mobilenumber ? (
                <p className="form-error">{errors.mobilenumber}</p>
              ) : null}
            </div>
          </div>
          <div className="input-container">
            <TextField
              name="websiteurl"
              value={values.websiteurl}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={editInput}
              className="text_input"
              id="outlined-basic"
              label="Website Url"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.websiteurl && touched.websiteurl ? (
                <p className="form-error">{errors.websiteurl}</p>
              ) : null}
            </div>
          </div>
        </div>

        <div
          className="setting_input_boxes mt-2"
          style={{ marginLeft: "14px" }}
        >
          <div style={{ width: "100%" }} className="multi_file_upload">
            {/* Single file upload */}
            <div className="app" style={{ width: "95%" }}>
              <div className="parent">
                <div
                  className="file-upload"
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDropfile}
                >
                  <img
                    src={file ? URL.createObjectURL(file) : initialValues.file}
                    style={{ height: "100px", objectFit: "contain" , maxWidth:"190px"}}
                    alt=""
                  />

                  <h3>Drag and drop Company Logo</h3>
                  <p>or</p>
                  {!isFileSelected ? (
                    <button
                      className="cusbtn"
                      type="button"
                      onClick={() => {
                        document.getElementById("fileInput").click();
                      }}
                    >
                      Upload
                    </button>
                  ) : (
                    <button
                      className="cusbtn"
                      type="button"
                      onClick={() => {
                        update_logo_fav("logo");
                      }}
                    >
                      Submit
                    </button>
                  )}

                  <div style={{ height: "20px" }}>
                    {logo_err ? <p className="form-error">{logo_err}</p> : null}
                  </div>

                  <input
                    type="file"
                    id="fileInput"
                    style={{
                      color: "var(--Whiteblack-color)",
                      display: "none",
                    }}
                    name="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileInput}
                  />
                </div>
              </div>
            </div>
            {/* Single file upload */}
          </div>

          <div style={{ width: "100%" }} className="multi_file_upload">
            {/* Single file upload */}
            <div className="app" style={{ width: "95%" }}>
              <div className="parent">
                <div
                  className="file-upload"
                  onDragOver={handleDragOver_favicon}
                  onDragEnter={handleDragEnter_favicon}
                  onDragLeave={handleDragLeave_favicon}
                  onDrop={handleDropfile_favicon}
                >
                  <img
                    src={
                      favicon
                        ? URL.createObjectURL(favicon)
                        : initialValues.favicon
                    }
                    style={{ height: "100px", objectFit: "contain", maxWidth:"190px" }}
                    alt=""
                  />
                  <h3>Drag and drop Company Favicon</h3>
                  <p>or</p>
                  {!isFileSelected_favi ? (
                    <button
                      className="cusbtn"
                      type="button"
                      onClick={() => {
                        document.getElementById("fileInput_favicon").click();
                      }}
                    >
                      Upload
                    </button>
                  ) : (
                    <button
                      className="cusbtn"
                      type="button"
                      onClick={() => {
                        update_logo_fav("favi");
                      }}
                    >
                      Submit
                    </button>
                  )}
                  <div style={{ height: "20px" }}>
                    {favicon_err ? (
                      <p className="form-error">{favicon_err}</p>
                    ) : null}
                  </div>
                  <input
                    type="file"
                    id="fileInput_favicon"
                    style={{
                      color: "var(--Whiteblack-color)",
                      display: "none",
                    }}
                    name="favicon"
                    accept=".jpg, .jpeg, .png, .gif"
                    onChange={handleFileInput_favicon}
                  />
                </div>
              </div>
            </div>
            {/* Single file upload */}
          </div>
        </div>

        <div className="butnsec">
          <button type="submit" className="cusbtn">
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default Company_Settings;
