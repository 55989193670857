import React, { useState, useEffect, useCallback } from "react";
// import {
//   CircularProgressbar,
//   CircularProgressbarWithChildren,
//   buildStyles,
// } from "react-circular-progressbar";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "react-circular-progressbar/dist/styles.css";
// import { ResponsiveBar } from "@nivo/bar";
import { isSameDay as isSameDayFns } from "date-fns";
// import { FaEdit } from "react-icons/fa";
import { GiSandsOfTime } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance

const Attendance_details = (props) => {
  const employee_page = useSelector((state) => state.Reducer);

  // calendar
  // const [markedDates, setMarkedDates] = useState([
  //   new Date(2023, 8, 5),
  //   new Date(2023, 8, 10),
  // ]);

  // function isSameDay(date1, date2) {
  //   return isSameDayFns(date1, date2);
  // }

  // const tileContent = ({ date }) => {
  //   const isMarked = markedDates.some((markedDate) =>
  //     isSameDay(markedDate, date)
  //   );

  //   return isMarked ? <div className="dot"></div> : null;
  // };

  const [date, setDate] = useState(new Date());
  const [total_hours, set_total_hours] = useState();
  const [checkin_out, set_checkin_out] = useState();

  const onChange = (newDate) => {
    setDate(newDate);
    get_calendar_details(newDate);
  };

  const get_calendar_details = async (e) => {
    // console.log(e);
    function formatDate(dateString) {
      const date = new Date(dateString);
      // Get timezone offset in minutes and convert it to milliseconds
      const timezoneOffset = date.getTimezoneOffset() * 60000;
      // Adjust the date by adding the timezone offset
      const adjustedDate = new Date(date.getTime() - timezoneOffset);
      return adjustedDate.toISOString().slice(0, 10);
    }

    const formattedDate = formatDate(e);

    try {
      const response = await axiosInstance.post(
        "AttendenceByDateAndId",
        {
          employee_id: props.id,
          attendence_date: formattedDate,
        },
        {
          httpsAgent: employee_page.HTTP_AGENT,
        }
      );
      // for adding tottal hours
      function sumTotalHours(data) {
        let totalSeconds = 0;
        console.log(data[0].total_hours);
        if (data[0].total_hours === "") {
          set_total_hours("00:00:00");  // Resetting the total hours if needed
          return;  // Exit the function
        }
        console.log("hi total");
        data.forEach((item) => {
          const [hours, minutes, seconds] = item.total_hours
            .split(":")
            .map(Number);
          totalSeconds += hours * 3600 + minutes * 60 + seconds;
        });

        // Convert total seconds back to HH:MM:SS
        const totalHours = Math.floor(totalSeconds / 3600);
        const totalMinutes = Math.floor((totalSeconds % 3600) / 60);
        const totalSecs = totalSeconds % 60;

        // Pad with zeroes if needed
        const formattedHours = String(totalHours).padStart(2, "0");
        const formattedMinutes = String(totalMinutes).padStart(2, "0");
        const formattedSeconds = String(totalSecs).padStart(2, "0");
        set_total_hours(
          `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        );
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      }
      // for adding tottal hours
      console.log("date =", response.data);
      if (response.data.message) {
        console.log("hi");
        set_total_hours("");
        set_checkin_out("");
      } else if(response.data.total_hours != "") {
        sumTotalHours(response.data);
        set_checkin_out(response.data);
      }
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  };
  // calendar

  // get cards by id
  const [cards_details, set_cards_details] = useState("");
  const get_cards_detail_byid = useCallback(async () => {
    try {
      await axiosInstance
        .post(
          "WorkingHoursEmployee",
          {
            employee_id: props.id,
          },
          {
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("on click", response.data);
          set_cards_details(response.data.Working_Hours_Data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_cards_detail_byid();
  }, [get_cards_detail_byid]);
  // get cards by id
  return (
    <>
      <div className="headingbox">
        <div className="headingleftbox">
          <h1>Attendance Information</h1>
          {/* <p>Update employee Work information</p> */}
        </div>
      </div>
      <div>
        <div className="atten_box">
          <h1>Attendance</h1>
          <div className="atten_det_cardbox">
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <GiSandsOfTime style={{ color: "#008EAD" }} />
              </div>
              <p>Today Hour</p>
              <h3 style={{ color: "#008EAD" }}>
                {cards_details ? cards_details.today_working_hours : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <GiSandsOfTime style={{ color: "#203E98" }} />
              </div>
              <p>Weekly Hour</p>
              <h3 style={{ color: "#203E98" }}>
                {cards_details ? cards_details.weekly_working_hours : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <GiSandsOfTime style={{ color: "#8081F5" }} />
              </div>
              <p>Monthly Hour</p>
              <h3 style={{ color: "#8081F5" }}>
                {cards_details ? cards_details.monthly_working_hours : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <GiSandsOfTime style={{ color: "#8081F5" }} />
              </div>
              <p>Total Working Hour</p>
              <h3 style={{ color: "#8081F5" }}>
                {cards_details ? cards_details.total_working_hours : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <GiSandsOfTime style={{ color: "#00B2FF" }} />
              </div>
              <p>Regularization</p>
              <h3 style={{ color: "#00B2FF" }}>
                {cards_details ? cards_details.regularise_count : ""}
              </h3>
            </div>
            <div className="aten_deta_box card">
              <div className="atten_icon">
                <GiSandsOfTime style={{ color: "#FF8800" }} />
              </div>
              <p>Holiday</p>
              <h3 style={{ color: "#FF8800" }}>
                {cards_details ? cards_details.leave_count : ""}
              </h3>
            </div>
          </div>
        </div>

        {/* calendar and deatils  */}
        <div className="calendar_details mt-2">
          <div className="calendar">
            <div className="calendar-container">
              <Calendar onChange={onChange} value={date} />
            </div>
          </div>

          <div className="deatils_calendar">
            <div className="at_office">
              <h3>At Office</h3>
              <div className="at_office_detail mt-1">
                <p>2023-05-24</p>
                <p>Working Hour - {total_hours}</p>
              </div>
            </div>

            <div className="checkin_checkout mt-1">
              <div className="checkin_checkout_heading">
                <h3>Check In</h3>
                <h3>Check Out</h3>
              </div>

              {checkin_out
                ? checkin_out.map((ele, index) => {
                    return (
                      <div className="checkin_checkout_time mt-1" key={index}>
                        <p>
                          {ele.is_regularize ? (
                            <div className="orangedot"></div>
                          ) : (
                            ""
                          )}
                          {ele.checkIn_AtOffice == "Worked Remotely" ? (
                            <div className="browndot"></div>
                          ) : (
                            ""
                          )}
                          {ele.checkIn_AtOffice == "At office" ? (
                            <div className="browndot"></div>
                          ) : (
                            ""
                          )}
                          {ele.check_In ? ele.check_In : "No Check In"}
                        </p>
                        <p>
                          {ele.checkOut_AtOffice == "Worked Remotely" ? (
                            <div className="browndot"></div>
                          ) : (
                            ""
                          )}
                          {ele.checkOut_AtOffice == "At office" ? (
                            <div className="browndot"></div>
                          ) : (
                            ""
                          )}
                          {ele.check_Out ? ele.check_Out : "No check Out"}
                        </p>
                      </div>
                    );
                  })
                : "No Check In and Out"}

              <div className="attendance_color mt-2">
                <div className="colors_detail">
                  <div className="greendot"></div> <p>At Office</p>
                </div>

                <div className="colors_detail">
                  <div className="orangedot"></div>{" "}
                  <p>Attendance Regularization</p>
                </div>

                <div className="colors_detail">
                  <div className="browndot"></div> <p>Worked Remotely</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* calendar and deatils  */}
      </div>
    </>
  );
};

export default Attendance_details;
