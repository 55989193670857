import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Image_sec_all from "./Image_sec_all";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import dot from "../../components/photos/dotimg.png";
import axiosInstance from "../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import logo from "../../components/photos/wizsuite_login.png";
import swal from "sweetalert";

const Newpassword = () => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const forget_pass = useSelector((state) => state.Reducer);
  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "300px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom -100px right -100px",
  };

  const initialValues = {
    new_password: "",
    confirmPassword: "",
  };

  const signUpSchema = Yup.object({
    new_password: Yup.string()
      .required("New Password is required")
      .min(6, "New Password must be at least 6 characters")
      .matches(/[a-zA-Z]/, "New Password must contain at least one letter")
      .matches(/[0-9]/, "New Password must contain at least one digit"),

    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });
  const { t } = useTranslation();
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values, action) => {
        // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        change_pass(values);
      },
    });
  // Get the entire URL of the page
  const pageUrl = window.location.href;
  const url = new URL(pageUrl);
  const token = url.searchParams.get("token");
  const timestamp = url.searchParams.get("timestamp");
   useEffect(()=>{
    const token = url.searchParams.get("token");
    if(token == null){
      // console.log("hi");
      navigate("/")
    }
   })

  // Convert the timestamp to a Date object
  const date = new Date(parseInt(timestamp));

  // Output the token, timestamp, and converted date values
  // console.log("Token:", token);
  // console.log('Timestamp:', timestamp);
  // console.log("Converted Date:", date);

  // change_pass api
  // password Visibility
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConformPassword] = useState(false);

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConformPassword(!showConfirmPassword);
  };

  // password Visibility
  async function change_pass(e) {
    
    try {
      await axiosInstance
        .post(
          `${forget_pass.GLOBAL_API}ChangePassword`,
          {
            token: token,
            password: e.confirmPassword,
          },
          {
            httpsAgent: forget_pass.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log(response.data.success);
          if (response.status == 200) {
            // alert success
            swal({
              title: "Success!",
              text: "Your email is verified LogIn now",
              icon: "success",
            });
            setTimeout(() => {
              swal.close();
              navigate("/Sign_Up");
            }, 4000);
            // alert success
          } else {
            // alert fail
            const errorMessage =
              response.data.success || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 3000);
            // alert fail
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      // alert fail
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 3000);
      // alert fail
    }
  }
  // change_pass api

  return (
    <>
      <div className="loginbox">
        <div className="image">
          <div className="about_wiz ">
            <div className="img_logo">
              <img src={logo} alt="" />
            </div>

            <div className="welcome mt-5">
              <h1>{t("Welcome_To_WizSuite")}</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. <br />
                Explicabo accusantium magni a alias ratione hic sapiente <br />
                laudantium autem possimus tenetur cumque quam architecto,
                <br /> expedita error non dignissimos nam obcaecati adipisci.
              </p>
            </div>

            <button
              onClick={() => {
                navigate("/Sign_Up");
              }}
              className="secondary-btn"
            >
              {t("Sign_in")}
            </button>
          </div>
        </div>

        <div style={style} className="form">
          <div className="sign_up">
            <h3>Change Password</h3>
          </div>
          <div className="signinfull mt-5">
            <div className="hello flex_row_global">
              <h2 style={{ color: "#605eb6" }}>Enter new password</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="check_success_sign_up">
                <div className="input_container_sign_up mt-2">
                  {/* <div> */}

                  <TextField
                    name="new_password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={values.new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.new_password && touched.new_password ? (
                      <p className="form-error">{errors.new_password}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input_container_sign_up mt-2">
                  <TextField
                    name="confirmPassword"
                    value={values.confirmPassword}
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleConfirmPasswordVisibility}
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Confirm Password"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <p className="form-error">{errors.confirmPassword}</p>
                    ) : null}
                  </div>
                </div>

                <div className="login mt-2">
                  <button className="cusbtn">Change Password</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newpassword;
