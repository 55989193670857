import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Quill from 'quill';
import "quill/dist/quill.core.css";
import axiosInstance from "../../../utils/AxiosInstance";

const Privacypolicy = () => {
  const [terms, setTerms] = useState("");
  async function getTerms() {
    try {
      const res = await axiosInstance.get("privacy");
      console.log(res.data[0].content);
      setTerms(res.data[0].content);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getTerms();
    console.log(terms);
  }, []);
  return <div dangerouslySetInnerHTML={{ __html: terms }}></div>;
};

export default Privacypolicy;
