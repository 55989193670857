import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://wizsuite-testing.bzbug.com/", // Use the global API URL from config
});

// Set the Authorization header globally
const accessToken = localStorage.getItem("accessToken");
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${accessToken}`;

// Function to refresh the JWT token
const RefreshAuthToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  try {
    const response = await axios.post(
      `https://testing.wizsuite.com/refresh-token`,
      {
        oldToken: refreshToken,
      },
      {
        httpsAgent: false,
      }
    );
    console.log("update refresh token=", response.data);
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.accessToken}`;
    localStorage.setItem("accessToken", `${response.data.accessToken}`);
  } catch (e) {
    console.log(e);
    console.log(e.response.data.error);
  }
};

// Add a request interceptor to intercept outgoing requests
axiosInstance.interceptors.request.use(
  async (config) => {
    // Set the Authorization header
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor to intercept responses
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Do something with response error
    const originalRequest = error.config;

    // Check if the error status is 401 (unauthorized) and it's not a token refresh request
    const remember = localStorage.getItem("remember");
    console.log("remember=", remember, "with ", typeof remember);
    // const navigate = useNavigate();

    if (remember == "true") {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true; // Avoid infinite loop

        // Refresh the JWT token
        await RefreshAuthToken();

        // Retry the original request with the new token
        return axiosInstance(originalRequest);
      }
    } else if (error.response.status === 401 && remember !== "true") {
      window.location.href = "/Sign_Up";
      console.log("remember false else=", remember);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
