import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";
import "../../App.css";

export const GlobalFilter = ({ filter, setFilter }) => {
  const navigate = useNavigate();

  const navigateToAdd = () => {
    // 👇️ navigate to /contacts
    navigate("/organisation/department/AddRecord");
  };

  return (
    <>
      
       
        <div className="globalsearchbar">
          <BiSearchAlt style={{marginRight:"5px"}} />
          <input style={{ border:"none", outline:"none"}}
            placeholder="Search.."
            value={filter || ""}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>

        {/* <div
          style={{
            width: "50%",
            position: "relative",
          }}
        >
        </div> */}
     
    </>
  );
};
