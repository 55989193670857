import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../../Navbar";
import { CgCloseR } from "react-icons/cg";
import { BiLeftArrow, BiEdit } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import dp from "../../../components/photos/dp.jpg";
import { GlobalFilter } from "./GlobalFilter";
import { BsThreeDotsVertical } from "react-icons/bs";
import MOCK_DATA from "./Customers.json";
import { useFormik } from "formik";
import { MdOutlineDeleteSweep } from "react-icons/md";
import * as Yup from "yup";
import FormData from "form-data";
import axios from "axios";

const Reimbursement = () => {
  const navigate = useNavigate();
  // useEffect
  const dispatch = useDispatch();

  const flight_data = useSelector((state) => state.Reducer);
  // console.log("redux = ", flight_data.EVENT_TABLE_DATA);

  const [upcoming, setUpcoming] = useState(false);
  const [options, setOptions] = useState();

  useEffect(() => {
    // event_table_detail();
    // if (flight_data.EVENT_TABLE_DATA) {
    //   setUpcoming(true);
    // }
    // assistance_list();
  }, [upcoming]);

  // const data = useMemo(() => {
  //   if (upcoming) {
  //     return flight_data.EVENT_TABLE_DATA;
  //   } else {
  //     return [];
  //   }
  // }, [upcoming, flight_data.EVENT_TABLE_DATA]);

  const data = useMemo(() => MOCK_DATA);
  // useEffect

  // Create Event
  const [Create_event_modal, setCreate_event_modal] = useState(false);

  // Create Event

  const COLUMNS = [
    {
      Header: "Claim Number",
      accessor: "firstname",
      Cell: ({ row }) => (
        <>
          <p>BH68778</p>
        </>
      ),
    },
    {
      Header: "Submitted Date",
      accessor: "events_name",
      Cell: ({ row }) => (
        <>
          <p>1 jan, 2023</p>
        </>
      ),
    },
    {
      Header: "Type",
      accessor: (row) => {
        return [`${row.events_start_date}`, `${row.events_end_date}`];
      },
      Cell: ({ row }) => (
        <>
          <p>Business exense</p>
        </>
      ),
    },
    {
      Header: "Description",
      accessor: (row) => {
        return [`${row.event_start_time}`, `${row.event_end_time}`];
      },
      Cell: ({ row }) => (
        <>
          <p>lorem ipusm</p>
        </>
      ),
    },
    {
      Header: "Approved Amount",
      accessor: "",
      Cell: ({ row }) => (
        <p>
          $14.00
          {/* <p>{row.original.description}</p> */}
        </p>
      ),
    },
    {
      Header: "Claim Amount",
      accessor: "",
      Cell: ({ row }) => (
        <p>
          $15.00
          {/* <p>{row.original.description}</p> */}
        </p>
      ),
    },
    {
      Header: "Status",
      accessor: "",
      Cell: ({ row }) => <p>Approved</p>,
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>

    <h1 className="orange_heading_color mt-1">Reimbursement</h1>
      <div className="mt-3">
        <div
          className="department tablebox"
          style={{ backgroundcolor: "white" }}
        >
          <table className="mt-2" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroups) => (
                <tr {...headerGroups.getHeaderGroupProps()}>
                  {headerGroups.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽"
                            : "🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {/* {flight_data.EVENT_TABLE_DATA ? (
                      <> */}

              {page.length === 0 ? (
                <tr>
                  <td>No data</td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            style={{
                              textalign: "center",
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className="pagination">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <button
              className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <BiLeftArrow />
            </button>
            <button
              className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <BiRightArrow />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reimbursement;
