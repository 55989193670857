import React from "react";
import Navbar from "../Navbar";
import MainSidebar from "../../components/Sidebar/MainSidebar";

const Profile = () => {

  return (
    <div className="main-container">
      {/* <MainSidebar /> */}
      <div className="allcontent">
        <Navbar />
        profile
      </div>
    </div>
  );
};

export default Profile;
