import React, { useCallback, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import { FaHome, FaUser } from "react-icons/fa";
import { BsBackspaceFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { BiUpload } from "react-icons/bi";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axios";

const AddEmployeeManually = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef();
  const add_emplyee = useSelector((state) => state.Reducer);
  // console.log(add_emplyee)
  const { t } = useTranslation();

  // get all shift branch department
  const [shift, setShift] = useState("");
  const [department_arr, setDepartment_arr] = useState("");
  const [branch_data, setbranch_data] = useState("");
  const [reporting, setReporting] = useState("");
  const get_all_shift = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`AllShifts`, {
        httpsAgent: add_emplyee.HTTP_AGENT,
      });
      setShift(response.data);
      // console.log("shift", response.data);
    } catch (error) {
      console.error(error);
      console.error(error.response.data.error);
    }
  }, []);

  // get_all_department api
  const get_all_department = useCallback(async () => {
    try {
      await axiosInstance
        .get(`AllDepartment`, {
          httpsAgent: add_emplyee.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("depart", response.data);
          setDepartment_arr(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  // get_all_department api

  // get all branch
  const get_all_branch = useCallback(async () => {
    try {
      await axiosInstance
        .get(`AllBranches`, {
          httpsAgent: add_emplyee.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("branch", response.data);
          setbranch_data(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);
  // get all branch

  // get all reprting
  const get_all_reporting_manager = useCallback(async () => {
    try {
      await axiosInstance
        .get(`AllreportingManager`, {
          httpsAgent: add_emplyee.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("report", response.data);
          setReporting(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);
  // get all reprting

  // get all designation
  const [designation_data, setdesignation_data] = useState("");

  async function get_all_designation() {
    try {
      await axiosInstance.get(`Alldesignation`).then((response) => {
        // console.log("desi",response.data);
        setdesignation_data(response.data);
      });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get all designation

  useEffect(() => {
    get_all_shift();
    get_all_department();
    get_all_branch();
    get_all_reporting_manager();
    get_all_designation();
  }, [
    get_all_shift,
    get_all_department,
    get_all_branch,
    get_all_reporting_manager,
    get_all_designation,
  ]);
  // get all shift branch department

  const signUpSchema = Yup.object({
    contact_profile: Yup.mixed().test("fileSize", "Max Size 2MB", (file) => {
      if (file) {
        return file.size <= 2000000;
      } else {
        return true;
      }
    }),
    // employee_id: Yup.number().required("Enter Employee ID"),
    first_name: Yup.string().required("Enter First Name"),
    last_name: Yup.string().required("Enter Last Name"),
    employee_phone: Yup.string()
      .required("Enter Phone Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    employee_email: Yup.string()
      .required("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address"),
    join_date: Yup.string().required("Enter joining date"),
    department: Yup.string().required("Enter Department"),
    designation: Yup.string().required("Enter Designation"),
    // reporting_manager: Yup.string().required("Enter Reporting Manager"),
    branch: Yup.string().required("Enter Branch"),
    shift: Yup.string().required("Enter Shift"),
  });

  const [initialValues, setInitialValues] = useState({
    employee_id: "",
    first_name: "",
    last_name: "",
    employee_phone: "",
    employee_email: "",
    join_date: "",
    department: "",
    designation: "",
    reporting_manager: "",
    branch: "",
    shift: "",
    contact_profile: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    // By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      add_employee(values);
    },
  });

  // add employee deatils
  async function add_employee(e) {
    try {
      await axiosInstance
        .post(
          `AddEmployee`,
          {
            first_name: e.first_name,
            last_name: e.last_name,
            work_email: e.employee_email,
            work_phone: e.employee_phone,
            join_date: e.join_date,
            designation: e.designation,
            department_id: e.department,
            branch_id: e.branch,
            shift_id: e.shift,
            // reportingManager_id: e.reporting_manager,
          },
          {
            httpsAgent: add_emplyee.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("update=", response.data);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  // add employee deatils
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];

  // to change text field to date field
  const [isFocused1, setIsFocused1] = useState(false);

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlurr1 = () => {
    setIsFocused1(false);
  };
  return (
    <>
      <div className="main-container">
        <div className="allcontent">
          <Navbar />
          <div className="mainbox">
            <h1>Add Employee</h1>
            <div className="breadcrumbs_pop">
              <p style={{ fontWeight: "bold", marginBottom: "17px" }}>
                <div className="small_iconsstyle">
                  <FaHome />
                </div>
                /Employees
              </p>
              <button
                onClick={() => {
                  navigate("/Employees");
                }}
                className="breadcrumbbtn"
              >
                <BsBackspaceFill size="1.2rem" />
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="twoBoxesAlign">
                <div className="charts_employee mt-3 card">
                  <div className="profile_upload mt-4">
                    {selectedImage ? (
                      <img
                        className="set_profile_img_manually"
                        src={selectedImage}
                        alt=""
                      />
                    ) : (
                      <div className="set_profile_img_manually empty_profile_img">
                        <FaUser size="6em" />
                      </div>
                    )}

                    <div className="upload_btn mt-2">
                      <button
                        type="button"
                        className="profilebtn"
                        onClick={(e) => {
                          // e.preventDefault();
                          // console.log("img=",fileInputRef.current)
                          fileInputRef.current.click();
                        }}
                      >
                        <BiUpload /> {t("upload_contact_profile")}
                      </button>
                      <input
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          // console.log("url=",e.target.files[0])
                          if (file) {
                            // console.log("url=",URL.createObjectURL(file))
                            setSelectedImage(URL.createObjectURL(file));
                          }
                          setFieldValue(
                            "contact_profile",
                            e.currentTarget.files[0]
                          );
                        }}
                        ref={fileInputRef}
                        name="contact_profile"
                        type="file"
                        style={{ display: "none" }}
                      />
                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.contact_profile && touched.contact_profile ? (
                          <p className="form-error">{errors.contact_profile}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="content-tabs mt-3 card">
                  <div style={{ display: "flex" }}>
                    <div
                      className="input-container mr-1 mt-2"
                      style={{ flex: "1" }}
                    >
                      <TextField
                        name="first_name"
                        type="text"
                        value={values.first_name}
                        onInput={(e) => {
                          e.target.value = (e.target.value || "").replace(
                            /[^A-Za-z\s]/g,
                            ""
                          );
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="text_input"
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                      />

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.first_name && touched.first_name ? (
                          <p className="form-error">{errors.first_name}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input-container mt-2" style={{ flex: "1" }}>
                      <TextField
                        name="last_name"
                        type="text"
                        value={values.last_name}
                        onInput={(e) => {
                          e.target.value = (e.target.value || "").replace(
                            /[^A-Za-z\s]/g,
                            ""
                          );
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="text_input"
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                      />

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.last_name && touched.last_name ? (
                          <p className="form-error">{errors.last_name}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      className="input-container mr-1 mt-2"
                      style={{ flex: "1" }}
                    >
                      <TextField
                        name="employee_phone"
                        type="text"
                        value={values.employee_phone}
                        onInput={(e) => {
                          e.target.value = (e.target.value || "").replace(
                            /[^\d]/g,
                            ""
                          );
                          e.target.value = e.target.value.slice(0, 10);
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="text_input"
                        id="outlined-basic"
                        label="Employee Phone"
                        variant="outlined"
                      />

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.employee_phone && touched.employee_phone ? (
                          <p className="form-error">{errors.employee_phone}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input-container mt-2" style={{ flex: "1" }}>
                      <TextField
                        name="employee_email"
                        type="text"
                        value={values.employee_email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="text_input"
                        id="outlined-basic"
                        label="Employee Email"
                        variant="outlined"
                      />

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.employee_email && touched.employee_email ? (
                          <p className="form-error">{errors.employee_email}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      className="input-container mr-1 mt-2"
                      style={{ flex: "1" }}
                    >
                      {isFocused1 ? (
                        <TextField
                          name="join_date"
                          type="date"
                          value={values.join_date}
                          onChange={handleChange}
                          className="text_input"
                          id="outlined-basic"
                          variant="outlined"
                          InputProps={{
                            inputProps: { max: today },
                          }}
                          onBlur={handleBlurr1}
                          label="DOB"
                        />
                      ) : (
                        <TextField
                          type="text"
                          name="join_date"
                          className="text_input"
                          variant="outlined"
                          value={values.join_date}
                          onFocus={handleFocus1}
                          id="outlined-basic"
                          label="DOB"
                        />
                      )}
                      {/* <TextField
                        name="join_date"
                        type="date"
                        value={values.join_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="text_input"
                        id="outlined-basic"
                        variant="outlined"
                        InputProps={{
                          inputProps: { max: today },
                        }}
                      /> */}

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.join_date && touched.join_date ? (
                          <p className="form-error">{errors.join_date}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input-container mt-2" style={{ flex: "1" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Department
                        </InputLabel>
                        <Select
                          name="department"
                          value={values.department}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Department"
                        >
                          {department_arr
                            ? department_arr.map((ele, index) => {
                                return (
                                  <MenuItem value={ele.id} key={index}>
                                    {ele.department_name}
                                  </MenuItem>
                                );
                              })
                            : ""}
                        </Select>
                      </FormControl>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.department && touched.department ? (
                          <p className="form-error">{errors.department}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      className="input-container mr-1 mt-2"
                      style={{ flex: "1" }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Designation
                        </InputLabel>
                        <Select
                          name="designation"
                          value={values.designation}
                          onChange={handleChange}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Designation"
                        >
                          {designation_data
                            ? designation_data.map((ele, index) => {
                                return (
                                  <MenuItem value={ele.id} key={index}>
                                    {ele.name}
                                  </MenuItem>
                                );
                              })
                            : ""}
                        </Select>
                      </FormControl>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.designation && touched.designation ? (
                          <p className="form-error">{errors.designation}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input-container mt-2" style={{ flex: "1" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Reporting Manager
                        </InputLabel>
                        <Select
                          name="reporting_manager"
                          value={values.reporting_manager}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Reporting Manager"
                        >
                          {reporting
                            ? reporting.map((ele, index) => {
                                return (
                                  <MenuItem value={ele.id} key={index}>
                                    {ele.department_name}
                                  </MenuItem>
                                );
                              })
                            : ""}
                        </Select>
                      </FormControl>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.reporting_manager &&
                        touched.reporting_manager ? (
                          <p className="form-error">
                            {errors.reporting_manager}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      className="input-container mr-1 mt-2"
                      style={{ flex: "1" }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Branch
                        </InputLabel>
                        <Select
                          name="branch"
                          value={values.branch}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Branch"
                        >
                          {branch_data
                            ? branch_data.map((ele, index) => {
                                return (
                                  <MenuItem value={ele.id} key={index}>
                                    {ele.name}
                                  </MenuItem>
                                );
                              })
                            : ""}
                        </Select>
                      </FormControl>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.branch && touched.branch ? (
                          <p className="form-error">{errors.branch}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input-container mt-2" style={{ flex: "1" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Shift
                        </InputLabel>
                        <Select
                          name="shift"
                          value={values.shift}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Shift"
                        >
                          {shift
                            ? shift.map((ele, index) => {
                                return (
                                  <MenuItem value={ele.id} key={index}>
                                    {ele.shift_name}
                                  </MenuItem>
                                );
                              })
                            : ""}
                        </Select>
                      </FormControl>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.shift && touched.shift ? (
                          <p className="form-error">{errors.shift}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <button type="submit" className="cusbtn">
                      Add Employee
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployeeManually;
