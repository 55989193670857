import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { BiUserCheck } from "react-icons/bi";
import { MdViewModule } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";
import { RiPagesFill } from "react-icons/ri";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { SETACTiVELINK } from "../../redux/bulkbtns/Actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axiosInstance from "../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import wiz_logo from "../../components/photos/wizsuite_login.png"

const MainSidebar = ({ children }) => {
  const company = useSelector((state) => state.Reducer);

  const { t } = useTranslation();
  const routes = [
    {
      path: "/Dashboard",
      name: `${t("Dashboard")}`,
      icon: <FaHome className="menuicon" />,
      toggleState: 1,
    },
    {
      path: "/Employees",
      name: `${t("Employees")}`,
      icon: <AiOutlineUsergroupAdd className="menuicon" />,
      toggleState: 2,
    },
    {
      path: "/Attendancedrop",
      name: `${t("Attendance")}`,
      icon: <BiUserCheck className="menuicon" />,
      toggleState: 3,
      subRoutes: [
        {
          path: "/Attendance",
          name: `${t("Attendance")}`,
          icon: <BsFillPeopleFill />,
          toggleState: 4,
        },
        {
          path: "/Regularization",
          name: `${t("Regularization")}`,
          icon: <BsFillPeopleFill />,
          toggleState: 5,
        },
        {
          path: "/Leave",
          name: `Leave`,
          icon: <BsFillPeopleFill />,
          toggleState: 6,
        },
        {
          path: "/Compoff",
          name: `Compoff`,
          icon: <BsFillPeopleFill />,
          toggleState: 10,
        },
      ],
    },
    {
      path: "/Onboard",
      name: `${t("Onboard")}`,
      icon: <MdViewModule className="menuicon" />,
      toggleState: 7,
      subRoutes: [
        {
          path: "/Onboard",
          name: `${t("Onboard_user")}`,
          toggleState: 8,
        },
        {
          path: "/Onboard_setting",
          name: `${t("Onboard_setting")}`,
          toggleState: 9,
        },
      ],
    },
    // {
    //   path: "/Offboard/Offboard.jsx",
    //   name: `Offboard`,
    //   icon: <MdOutlinePayment className="menuicon"/>,
    //   toggleState: 10,
    // },
    // {
    //   path: "/Payroll/Payroll.jsx",
    //   name: `${t("Payroll")}`,
    //   icon: <MdOutlinePayment className="menuicon"/>,
    //   toggleState: 11,
    //   subRoutes: [
    //     {
    //       path: "/Payroll/Payroll_dash/Payroll_Dash",
    //       name: `Payroll Dashboard`,
    //       icon: <BsFillPeopleFill />,
    //       toggleState: 12,
    //     },
    //     {
    //       path: "/Payroll/Emolyee_sal/Employee_sal.jsx",
    //       name: `Employee Salary`,
    //       icon: <BsFillPeopleFill />,
    //       toggleState: 13,
    //     },
    //     {
    //       path: "/Payroll/Payslip/Payslip.jsx",
    //       name: `Payslip`,
    //       icon: <BsFillPeopleFill />,
    //       toggleState: 14,
    //     },
    //     {
    //       path: "/Payroll/Payrun/Payrun.jsx",
    //       name: `Payrun`,
    //       icon: <BsFillPeopleFill />,
    //       toggleState: 15,
    //     },
    //   ],
    // },
    {
      path: "/Setting",
      name: `Settings`,
      icon: <RiPagesFill className="menuicon" />,
      toggleState: 16,
    },
  ];

  const dispatch = useDispatch();
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
    dispatch(SETACTiVELINK(index));
  };
  const togglesidebar = useSelector((state) => state.Reducer);

  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  // console.log(togglesidebar.VALUE_ACTIVE_LINK)

  const showAnimation = {
    // hidden: {
    //   width: 0,
    //   opacity: 0,
    //   transition: {
    //     duration: 0.5,
    //   },
    // },
    // show: {
    //   opacity: 1,
    //   width: "auto",
    //   transition: {
    //     duration: 0.5,
    //   },
    // },
  };
  //state for storing the url of company logo
  const [companyLogo, setCompamyLogo] = useState();
  //getting company logo on load of the page through company detail api
  useEffect(() => {
    getCompanylogo();
  }, []);

  //api for getting compnay logo
  async function getCompanylogo() {
    try {
      await axiosInstance
        .get(`${company.GLOBAL_API}CompanyDetail`)
        .then((response) => {
          if (response.status === 200) {
            setCompamyLogo(response.data[0].company_logo);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  return (
    <>
      <div className="main-container">
        <div className="side">
          <motion.div
            animate={{
              width: togglesidebar.SIDEMENUOPENCLOSE ? "200px" : "45px",
              transition: {
                duration: 0.5,
                type: "spring",
                damping: 13,
              },
            }}
            className={`sidebar`}
          >
            <div className="top_section">
              <AnimatePresence>
                {togglesidebar.SIDEMENUOPENCLOSE && (
                  <motion.img
                    src={
                      companyLogo
                        ? companyLogo
                        : wiz_logo
                    }
                    // src={require(wiz_logo)}
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                  />
                )}
                {!togglesidebar.SIDEMENUOPENCLOSE && (
                  <motion.img
                    src={
                      companyLogo
                        ? companyLogo
                        : wiz_logo
                    }
                    // src={require("../../components/photos/wizlogo.png")}
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="wizlogo"
                  />
                )}
              </AnimatePresence>
            </div>
            <div className="tryhoja">
              <section className="routes">
                {routes.map((route, index) => {
                  if (route.subRoutes) {
                    return (
                      <SidebarMenu
                        index={index}
                        // setIsOpen={setIsOpen}
                        setToggleState={setToggleState}
                        toggleState={toggleState}
                        route={route}
                        showAnimation={showAnimation}
                        // isOpen={togglesidebar.SIDEMENUOPENCLOSE}
                      />
                    );
                  }

                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      // className="link"
                      // activeClassName="active"
                      className={
                        togglesidebar.VALUE_ACTIVE_LINK == route.toggleState
                          ? "linkactive "
                          : "link"
                      }
                      onClick={() => toggleTab(route.toggleState)}
                    >
                      <div className="icon">{route.icon}</div>
                      <AnimatePresence>
                        {togglesidebar.SIDEMENUOPENCLOSE && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                })}
              </section>
            </div>
          </motion.div>
        </div>

        <main
          className={togglesidebar.SIDEMENUOPENCLOSE ? `main` : `mainclose`}
        >
          {children}
        </main>
      </div>
    </>
  );
};

export default MainSidebar;
