import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { Checkbox } from "./Checkbox_request";
import { BiLeftArrow, BiEdit } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GlobalFilter } from "../../Employees/GlobalFilter";
import dp from "../../../components/photos/dp.jpg";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import swal from "sweetalert";

const Complete = () => {
  const navigate = useNavigate();
  // useEffect
  const dispatch = useDispatch();

  const employee_page = useSelector((state) => state.Reducer);

  // get all emplyee
  const [table_data, setTable] = useState();
  const get_all_onboard = useCallback(async () => {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}getonboardByStatus`, {
          status: "COMPLETED",
        })
        .then((response) => {
          console.log("table_data", response.data);
          setTable(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_all_onboard();
  }, [get_all_onboard]);
  // get all emplyee

  const data = useMemo(() => {
    if (table_data) {
      return table_data;
    } else {
      return [];
    }
  }, [table_data]);

  // useEffect

  // single onboard api
  async function Single_onbaord(id) {
    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}onboardSingle`, {
          onboard_id: id,
        })
        .then((response) => {
          console.log("onboard_single", response.data);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            get_all_onboard();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.message || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // single onboard api

  const COLUMNS = [
    {
      Header: "Profile Info",
      accessor: (row) => {
        return [`${row.first_name}`, `${row.email}`];
      },
      Cell: ({ row }) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              // marginLeft: "33px",
              cursor: "pointer",
            }}
          >
            <img
              src={dp}
              style={{
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                objectFit: "cover",
                margin: "0px 5px 0px 5px",
              }}
              alt=""
            />
            <div className="name_email">
              <p style={{ fontWeight: "bold" }}>{row.original.first_name}</p>
              <p>{row.original.email}</p>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   Header: "Candidate ID",
    //   accessor: "events_name",

    //   Cell: ({ row }) => (
    //     <>
    //       <p
    //         style={{
    //           cursor: "pointer",
    //           // color: "black",
    //         }}
    //         onClick={() => {
    //           navigate("/Event/Event_Details", {
    //             state: {
    //               name: row.original,
    //             },
    //           });
    //         }}
    //       >
    //         #ID7657689
    //         {/* {row.original.events_name} */}
    //       </p>
    //     </>
    //   ),
    // },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
              // color: "black",
            }}
          >
            {row.original.status}
          </p>
        </>
      ),
    },

    {
      Header: "Action",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <div>
          <button
            onClick={() => {
              Single_onbaord(row.original.id);
            }}
            className="cusbtn_onboard"
          >
            Start Onboard
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]);
    }
  );

  const selectedIds = selectedFlatRows.map((row) =>
    parseInt(row.original.id, 10)
  );

  console.log("No rows selected", selectedIds);
  const { globalFilter, pageIndex, pageSize } = state;

  // onboard_bulk api
  async function onboard_bulk() {
    console.log(selectedIds);
    if (selectedIds.length === 0) {
      swal({
        title: "Failure!",
        text: "Select some candidates to onboard",
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 2000);
      return; // Exit the function early if no rows are selected
    }

    try {
      await axiosInstance
        .post(`${employee_page.GLOBAL_API}onboardMulitple`, {
          onboard_id: selectedIds,
        })
        .then((response) => {
          console.log("approve_reject", response.data);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
            get_all_onboard();
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // onboard_bulk api
  return (
    <>
      <div className="breadcrumbs_pop">
        <div></div>
        <div className="appr_reject">
          <button
            onClick={() => {
              onboard_bulk();
            }}
            className="cusbtn"
            style={{ marginRight: "5px" }}
          >
            Multiple Onboard
          </button>
        </div>
      </div>

      <div
        className="mainbox mt-2"
        style={{
          boxShadow: "var(--box-shadow)",
          borderRadius: "var(--border-radius)",
          marginRight: "10px",
          background: "var(--card-color)",
          marginLeft: "10px",
        }}
      >
        {table_data ? (
          <div className="mt-2">
            <div
              className="department tablebox"
              style={{ backgroundcolor: "white" }}
            >
              {/* pagination */}
              <div className="pages">
                <div className="tablebottomboxleft">
                  <h1>Show</h1>
                  <span>
                    <select
                      className="selectnoofpagescolor"
                      style={{
                        padding: "4px 10px 4px 3px",
                        borderRadius: "10px",
                      }}
                      value={pageSize}
                      // className="showrows"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
              {/* pagination */}

              <table className="mt-2" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {/* {flight_data.EVENT_TABLE_DATA  ? (
                      <> */}

                  {page.length === 0 ? (
                    <tr>
                      <td>No data</td>
                    </tr>
                  ) : (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                style={{
                                  textalign: "center",
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <div className="pagination">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <button
                  className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiLeftArrow />
                </button>
                <button
                  className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiRightArrow />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="department tablebox mt-2"
              style={{ backgroundcolor: "white" }}
            >
              {/* pagination */}
              <div className="pages">
                <div className="tablebottomboxleft">
                  <h1>Show</h1>
                  <span>
                    <select
                      className="selectnoofpagescolor"
                      style={{
                        padding: "4px 10px 4px 3px",
                        borderRadius: "10px",
                      }}
                      value={pageSize}
                      // className="showrows"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </div>
              {/* pagination */}

              <table className="mt-2" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  <tr>
                    <td>No data</td>
                  </tr>
                </tbody>
              </table>
              <div className="pagination">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <button
                  className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiLeftArrow />
                </button>
                <button
                  className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiRightArrow />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Complete;
