import React, { useContext, useEffect, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import { useNavigate } from "react-router-dom";
import { BsCheck2 } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Formik } from "formik";
import axios from "axios";
import { FaUser } from "react-icons/fa";

const Congradulation = () => {
  const { t } = useTranslation();
  const { next, prev } = useContext(MultiStepFormContext);
  const navigate = useNavigate();
  return (
    <>
      <div className="congratulation">
        <div className="correct check_success">
            <BsCheck2 size="10em" color="#fff" className="checkicon"/>
            <h1>{t('Congratulation')}</h1>
            <p>{t('You_have_succesfully')}<br/>{t('with_us_login')}<br/>{t('services')}</p>
            <div className="next_prev_btn mt-3">
              <button type="button" onClick={() => {navigate("/Sign_Up")}}  className="cusbtn">
              {t('Sign_in')}
              </button>
            </div>
        </div>
      </div>
    </>
  );
};
export default Congradulation;
