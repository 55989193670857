export const SET_SIDE_MENU_OPEN_CLOSE = 'SET_SIDE_MENU_OPEN_CLOSE';
export const SET_LIGHT_DARK_MODE =  'SET_LIGHT_DARK_MODE';
export const SET_CHECKED_MODE =  'SET_CHECKED_MODE';
export const SET_EVENT_TABLE =  'SET_EVENT_TABLE';
export const SET_ACTIVE_LINK =  'SET_ACTIVE_LINK';
export const SET_MENU_CHECK =  'SET_MENU_CHECK';
export const SET_TOKEN =  'SET_TOKEN';
export const SET_REFRESH_TOKEN =  'SET_REFRESH_TOKEN';
export const SET_COMPANY_ID =  'SET_COMPANY_ID';
export const SET_HTTPS =  'SET_HTTPS';
export const SET_EMPLOYEE_EDIT_TOGGLE_STATE =  'EMPLOYEE_EDIT_TOGGLE_STATE';

export const SET_GLOBAL_API =  'SET_GLOBAL_API';



