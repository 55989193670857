import React, { useState } from "react";
import Navbar from "../../Navbar";
import Leave_request from "./Leave_request";
import Leave_approve from "./Leave_approve";
import Leave_reject from "./Leave_reject";

const Leave = () => {
  // radio btn
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedValue = localStorage.getItem("leave_toggle");
    return storedValue ? storedValue : "option1";
  }); // To store the selected option

  const handleOptionChange = (event) => {
    // console.log(event.target.value);
    localStorage.setItem("leave_toggle", event.target.value);
    setSelectedOption(event.target.value);
  };
  const [toggleState, setToggleState] = useState(() => {
    const storedValue = localStorage.getItem("leave");
    return storedValue ? parseInt(storedValue) : 1;
  });

  const toggleTab = (index) => {
    localStorage.setItem("leave", index);
    setToggleState(index);
  };
  // radio btn

  return (
    <div className="main-container">
      {/* <MainSidebar />*/}
      <div className="allcontent">
        <Navbar />
        <div className="mainbox">
          <div className="attendace_radio  mt-1">
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={(e) => {
                    handleOptionChange(e);
                  }}
                  onClick={() => toggleTab(1)}
                />
                <span>Request</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={(e) => {
                    handleOptionChange(e);
                  }}
                  onClick={() => toggleTab(2)}
                />
                <span>Approve</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  value="option3"
                  checked={selectedOption === "option3"}
                  onChange={(e) => {
                    handleOptionChange(e);
                  }}
                  onClick={() => toggleTab(3)}
                />
                <span>Reject</span>
              </label>
            </div>
          </div>

          <div className="mt-3">
            <div
              className={
                toggleState === 1
                  ? "content-regularization  active-content"
                  : "content-regularization"
              }
            >
              <Leave_request />
            </div>

            <div
              className={
                toggleState === 2
                  ? "content  active-content card"
                  : "content"
              }
            >
              <Leave_approve />
            </div>

            <div
              className={
                toggleState === 3
                  ? "content  active-content card"
                  : "content"
              }
            >
              <Leave_reject />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leave;
