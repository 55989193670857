import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CgCloseR } from "react-icons/cg";
import { MdLocationOn } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { BiEdit } from "react-icons/bi";

// single branch
const SingleBranch = (props) => {
  // for Translation
  const { t } = useTranslation();
  // Create Event
  const [Create_branch_modal, setCreate_branch_modal] = useState(false);
  const branch_redux = useSelector((state) => state.Reducer);
  // Add branch popup start
  const signUpSchema = Yup.object({
    branchname: Yup.string().required("Enter Branch Name"),
    branchphone: Yup.string().required("Enter Branch Phone"),
    branchcity: Yup.string().required("Enter Branch City"),
    branchlatitude: Yup.string().required("Enter Branch Latitude"),
    branchlongitude: Yup.string().required("Enter Branch Longitude"),
  });

  const [initialValues, setinitialValues] = useState({
    branchname: "",
    branchphone: "",
    branchcity: "",
    branchlatitude: "",
    branchlongitude: "",
    addressline1: "",
    addressline2: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      Add_branch(values);
    },
  });

  // get single branch
 useEffect (() => {
    get_single_branch();
  }, []);
  async function get_single_branch() {
    try {
      await axios
        .post(
          `${branch_redux.GLOBAL_API}BranchById`,
          {
            company_id: branch_redux.COMPANYAPI,
            branch_id: props.branch_id,
          },
          {
            headers: {
              Authorization: `Bearer ${branch_redux.TOKEN}`,
            },
            httpsAgent: branch_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("single=", response);
          setinitialValues({
            branchname: response.data[0].name,
            branchphone: response.data[0].phone,
            branchcity: response.data[0].city,
            branchlatitude: response.data[0].latitude,
            branchlongitude: response.data[0].longitude,
            addressline1: response.data[0].addressline1,
            addressline2: response.data[0].addressline2,
          });
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get single branch

  // add branch deatils
  async function Add_branch(e) {
    try {
      await axios
        .put(
          `${branch_redux.GLOBAL_API}EditBranch`,
          {
            name: e.branchname,
            phone: e.branchphone,
            city: e.branchcity,
            latitude: e.branchlatitude,
            longitude: e.branchlongitude,
            addressline1: e.addressline1,
            addressline2: e.addressline2,
            company_id: branch_redux.COMPANYAPI,
            branch_id: props.branch_id,
          },
          {
            headers: {
              Authorization: `Bearer ${branch_redux.TOKEN}`,
            },
            httpsAgent: branch_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);
          if (response.status == 200) {
            props.setCreate_single_branch(false);
            props.get_all_branch();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            setCreate_branch_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      setCreate_branch_modal(false);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add branch deatils

  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="branchname"
            value={values.branchname}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Branch Name"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.branchname && touched.branchname ? (
              <p className="form-error">{errors.branchname}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="branchphone"
            value={values.branchphone}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Branch Phone"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.branchphone && touched.branchphone ? (
              <p className="form-error">{errors.branchphone}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="branchcity"
            value={values.branchcity}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Branch City"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.branchcity && touched.branchcity ? (
              <p className="form-error">{errors.branchcity}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="branchlatitude"
            value={values.branchlatitude}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(/[^0-9.]/g, "");
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Branch Latitude"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.branchlatitude && touched.branchlatitude ? (
              <p className="form-error">{errors.branchlatitude}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="branchlongitude"
            value={values.branchlongitude}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(/[^0-9.]/g, "");
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Branch Longitude"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.branchlongitude && touched.branchlongitude ? (
              <p className="form-error">{errors.branchlongitude}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            name="addressline1"
            value={values.addressline1}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Address Line 1"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.addressline1 && touched.addressline1 ? (
              <p className="form-error">{errors.addressline1}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            name="addressline2"
            value={values.addressline2}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Address Line 2"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.addressline2 && touched.addressline2 ? (
              <p className="form-error">{errors.addressline2}</p>
            ) : null}
          </div>
        </div>
        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
// single branch
const Branch = (props) => {
  // for Translation
  const { t } = useTranslation();
  // Create Event
  const [Create_branch_modal, setCreate_branch_modal] = useState(false);
  const [Create_single_branch, setCreate_single_branch] = useState(false);
  const [brnach_id, setBrnach_id] = useState("");
  const branch_redux = useSelector((state) => state.Reducer);
  // Add branch popup start
  const signUpSchema = Yup.object({
    branchname: Yup.string().required("Enter Branch Name"),
    branchphone: Yup.string().required("Enter Branch Phone"),
    branchcity: Yup.string().required("Enter Branch City"),
    branchlatitude: Yup.string().required("Enter Branch Latitude"),
    branchlongitude: Yup.string().required("Enter Branch Longitude"),
  });

  const [initialValues, setinitialValues] = useState({
    branchname: "",
    branchphone: "",
    branchcity: "",
    // branchlatitude: "",
    // branchlongitude: "",
    addressline1: "",
    addressline2: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      Add_branch(values);
    },
  });
  // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", errors);


  // get all branch
  const [branch_data, setbranch_data] = useState("");
  useEffect (() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
    get_all_branch();
    }
  }, [props.tabStyle]);

  async function get_all_branch() {
    try {
      await axios
        .get(
          `${branch_redux.GLOBAL_API}AllBranches`,
          {
            headers: {
              Authorization: `Bearer ${branch_redux.TOKEN}`,
            },
            httpsAgent: branch_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log(response.data);
          setbranch_data(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get all branch

  // add branch deatils
  async function Add_branch(e) {
    try {
      await axios
        .post(
          `${branch_redux.GLOBAL_API}InsertBranch`,
          {
            branch_name: e.branchname,
            branch_phone: e.branchphone,
            branch_city: e.branchcity,
            branch_latitude: e.branchlatitude,
            branch_longitude: e.branchlongitude,
            addressline1: e.addressline1,
            addressline2: e.addressline2,
            company_id: branch_redux.COMPANYAPI,
          },
          {
            headers: {
              Authorization: `Bearer ${branch_redux.TOKEN}`,
            },
            httpsAgent: branch_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);
          if (response.status == 200) {
            get_all_branch();
            setCreate_branch_modal(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            setCreate_branch_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      setCreate_branch_modal(false);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add branch deatils

  return (
    <>
      {/* pop up */}
      {Create_branch_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_branch_modal(!Create_branch_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Branch</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_branch_modal(!Create_branch_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="input-container mt-2">
                  <TextField
                    name="branchname"
                    value={values.branchname}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch Name"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchname && touched.branchname ? (
                      <p className="form-error">{errors.branchname}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="branchphone"
                    value={values.branchphone}
                    onInput={(e) => {
                      let cleanedValue = (e.target.value || "").replace(/[^\d]/g, "");
                      cleanedValue = cleanedValue.slice(0, 10);
                      e.target.value = cleanedValue;
                      handleChange(e);
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch Phone"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchphone && touched.branchphone ? (
                      <p className="form-error">{errors.branchphone}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="branchcity"
                    value={values.branchcity}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch City"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchcity && touched.branchcity ? (
                      <p className="form-error">{errors.branchcity}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="branchlatitude"
                    value={values.branchlatitude}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^0-9.]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch Latitude"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchlatitude && touched.branchlatitude ? (
                      <p className="form-error">{errors.branchlatitude}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="branchlongitude"
                    value={values.branchlongitude}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^0-9.]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Branch Longitude"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.branchlongitude && touched.branchlongitude ? (
                      <p className="form-error">{errors.branchlongitude}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="addressline1"
                    value={values.addressline1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Address Line 1"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.addressline1 && touched.addressline1 ? (
                      <p className="form-error">{errors.addressline1}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="addressline2"
                    value={values.addressline2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Address Line 2"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.addressline2 && touched.addressline2 ? (
                      <p className="form-error">{errors.addressline2}</p>
                    ) : null}
                  </div>
                </div>
                <div className="text-center mb-3">
                  <button type="submit" className="cusbtn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* pop up*/}

      {/* single branch  */}
      {/* pop up */}
      {Create_single_branch && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_single_branch(!Create_single_branch);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Branch</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_single_branch(!Create_single_branch);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <SingleBranch branch_id={brnach_id} setCreate_single_branch={setCreate_single_branch} get_all_branch={get_all_branch}/>
            </div>
          </div>
        </div>
      )}
      {/* pop up*/}
      {/* single branch  */}

      <div className="addbtnbra">
        <button
          className="cusbtn"
          type="button"
          onClick={() => {
            setCreate_branch_modal(!Create_branch_modal);
          }}
        >
          Add Branch
        </button>
      </div>
      <div className="branchbox mt-5 mb-3">
        {branch_data
          ? branch_data.map((ele, index) => {
              return (
                <div className="card brachcard mb-3" key={index} >
                  <div className="branchiconbox">
                    <MdLocationOn size="1.5em" />
                  </div>
                  <div style={{ textAlign: "right" }} className="editbtndep">
                    <BiEdit
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setBrnach_id(ele.id);
                        setCreate_single_branch(!Create_single_branch);
                      }}
                      color="var(--secondary-color)"
                    />
                  </div>
                  <h1>{ele.name}</h1>
                  <p>{ele.phone}</p>
                  <p>{ele.addressline1}</p>
                  {/* <p>Rally Infra H-157, Sector 62, Noida</p> */}
                </div>
              );
            })
          : <p style={{marginTop:"25px", fontSize:"20px",fontWeight:"bold"}}>No Branch Found</p>}
      </div>
    </>
  );
};

export default Branch;
