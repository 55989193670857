import React from "react";
import { FaUsers } from "react-icons/fa";

const Dashboardcardbox = (props) => {

    const activeUsers = props.data[0].ActiveUsers;
const totalPresent = props.data[0].totalPresent;

const difference = activeUsers-totalPresent;
  return (
    <>
      <div className="card eventcard">
        <div>
          <h1 style={{ fontSize: "30px" }}>{props.data[0].ActiveUsers}</h1>
          <p>Total Employee </p>
        </div>
        <div>
          <div className="eventicon">
            <FaUsers size="2em" style={{ color: "var(--eventicon-color)" }} />
          </div>
        </div>
      </div>

      <div className="card eventcard">
        <div>
          <h1 style={{ fontSize: "30px" }}>{props.data[0].ontime}</h1>
          <p>On Time</p>
        </div>
        <div>
          <div className="eventicon">
            {" "}
            <FaUsers size="2em" style={{ color: "var(--eventicon-color)" }} />
          </div>
        </div>
      </div>

      <div className="card eventcard">
        <div>
          <h1 style={{ fontSize: "30px" }}>{props.data[0].totalPresent}</h1>
          <p>Total Present</p>
        </div>
        <div>
          <div className="eventicon">
            {" "}
            <FaUsers size="2em" style={{ color: "var(--eventicon-color)" }} />
          </div>
        </div>
      </div>

      <div className="card eventcard">
        <div>
          <h1 style={{ fontSize: "30px" }}>{difference}</h1>
          <p>Absent</p>
        </div>
        <div>
          <div className="eventicon">
            {" "}
            <FaUsers size="2em" style={{ color: "var(--eventicon-color)" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboardcardbox;
