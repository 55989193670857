import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { Checkbox } from "./Checkbox_request";
import { CgCloseR } from "react-icons/cg";
import { BiLeftArrow, BiEdit } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormData from "form-data";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import dp from "../../../components/photos/dp.jpg";
import MOCK_DATA from "./Customers.json";
import { BiSearchAlt } from "react-icons/bi";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance

const Attendance_request = () => {
  const navigate = useNavigate();
  // useEffect
  const dispatch = useDispatch();

  const employee_page = useSelector((state) => state.Reducer);
  // console.log("redux = ", flight_data.EVENT_TABLE_DATA);

  // get all attendence
  const [table_data, setTable] = useState();
  const get_all_attendence = useCallback(async () => {
    try {
      await axiosInstance
        .get(`Allattendence`, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("table_data", response.data);
          setTable(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_all_attendence();
  }, [get_all_attendence]);
  // get all attendence

  const data = useMemo(() => {
    if (table_data) {
      return table_data;
    } else {
      return [];
    }
  }, [table_data]);

  const COLUMNS = [
    {
      Header: "Employee Details",
      accessor: "firstname",
      Cell: ({ row }) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              // marginLeft: "33px",
              cursor: "pointer",
            }}
          >
            <img
              src={row.original.photo ? row.original.photo : dp}
              style={{
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                objectFit: "cover",
                margin: "0px 5px 0px 5px",
              }}
              alt=""
            />
            <div className="name_email">
              <p style={{ fontWeight: "bold" }}>
                {row.original.first_name} {row.original.last_name}
              </p>
              <p>{row.original.employee_id}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      Header: "In Time",
      accessor: "check_In",

      Cell: ({ row }) => (
        <>
          <p
           
          >
            {row.original.check_In}
          </p>
        </>
      ),
    },
    {
      Header: "Out Time",
      accessor: "check_Out",
      Cell: ({ row }) => (
        <>
          <p
           
          >
            {row.original.check_Out}
          </p>
        </>
      ),
    },
    {
      Header: "Working Hours",
      accessor: "total_hours",
      Cell: ({ row }) => (
        <>
          <p > {row.original.total_hours}</p>
        </>
      ),
    },
    {
      Header: "Department",
      accessor: "department_name",
      Cell: ({ row }) => (
        <p>
          <p>{row.original.department_name}</p>
        </p>
      ),
    },
    {
      Header: "Location",
      accessor: "checkIn_address",
      Cell: ({ row }) => (
        <p>
          <p>{row.original.checkIn_address}</p>
        </p>
      ),
    },

    {
      Header: "Reporting Manager",
      accessor: "reporting_manager_name",
      Cell: ({ row }) => (
        <p>
          <p>{row.original.reporting_manager_name}</p>
        </p>
      ),
    },
    // {
    //   Header: "Approve/Reject",
    //   accessor: "modifiedtime",
    //   Cell: ({ row }) => (
    //     <div>
    //       <FaThumbsUp
    //         onClick={() => {
    //           navigate("/Employees/Deatil_pages/Employee_edit", {
    //             state: {
    //               name: row.original,
    //             },
    //           });
    //         }}
    //         size="1.2em"
    //         style={{ color: "#095800", cursor: "pointer" }}
    //       />
    //       <FaThumbsDown
    //         size="1.2em"
    //         style={{
    //           color: "#be202e",
    //           marginLeft: "5px",
    //           cursor: "pointer",
    //         }}
    //       />
    //       {/* <Assign id={row.original.id} /> */}
    //     </div>
    //   ),
    // },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <Checkbox {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <div className="mainbox">
       
        <div
          className="department tablebox"
          style={{ backgroundcolor: "white" }}
        >
          
          {/* pagination */}
          <div className="pages">
            <div className="tablebottomboxleft">
              <h1>Show</h1>
              <span>
                <select
                  className="selectnoofpagescolor"
                  style={{
                    padding: "4px 10px 4px 3px",
                    borderRadius: "10px",
                  }}
                  value={pageSize}
                  // className="showrows"
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 15, 20].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </span>
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <div className="globalsearchbar">
                <BiSearchAlt style={{ marginRight: "5px" }} />
                <input
                  style={{ border: "none", outline: "none" }}
                  placeholder="Search.."
                  value={globalFilter || ""}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  position: "relative",
                }}
              ></div>
            </div>
          </div>
          {/* pagination */}

          <table className="mt-2" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroups) => (
                <tr {...headerGroups.getHeaderGroupProps()}>
                  {headerGroups.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽"
                            : "🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {/* {flight_data.EVENT_TABLE_DATA ? (
                      <> */}

              {page.length === 0 ? (
                <tr>
                  <td>No data</td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            style={{
                              textalign: "center",
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className="pagination">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <button
              className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <BiLeftArrow />
            </button>
            <button
              className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <BiRightArrow />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendance_request;
