import React, { useEffect, useState } from "react";
import { BiPlusMedical } from "react-icons/bi";
import { CgCloseR } from "react-icons/cg";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { useCallback } from "react";
import axiosInstance from "../../../utils/AxiosInstance";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { BiEdit } from "react-icons/bi";
import { IoMailOpenSharp } from "react-icons/io5";
import { FaDotCircle } from "react-icons/fa";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

//popup data logic for adding SMTP
const Add_SMTP = (props) => {
  //schema for popup of signup schema
  const SMTP = useSelector((state) => state.Reducer);
  const signUpSchema = Yup.object({
    port: Yup.string().required("Enter Port number"),
    host: Yup.string().required("Enter Host number"),
    password: Yup.string().required("Enter Password"),
    username: Yup.string().required("Enter Username"),
  });

  const [initialValues, setinitialValues] = useState({
    port: "",
    host: "",
    password: "",
    username: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      add_SMTP(values);
    },
  });

  //api call to add SMTP
  const add_SMTP = useCallback(async (e) => {
    try {
      await axiosInstance
        .post(
          `InsertSmtpSetttings`,
          {
            port: e.port,
            host: e.host,
            username: e.username,
            password: e.password,
          },
          {
            httpsAgent: SMTP.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            props.setCreate_event_modal(!props.Create_event_modal);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            props.setCreate_event_modal(!props.Create_event_modal);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  });

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <TextField
          type="text"
          name="port"
          className="text_input"
          id="outlined-basic"
          variant="outlined"
          value={values.port}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Port number"
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.port && touched.port ? (
            <p className="form-error">{errors.port}</p>
          ) : null}
        </div>
      </div>

      <div className="input-container mt-2">
        <TextField
          type="text"
          name="host"
          className="text_input"
          id="outlined-basic"
          variant="outlined"
          value={values.host}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Host name"
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.host && touched.host ? (
            <p className="form-error">{errors.host}</p>
          ) : null}
        </div>
      </div>

      <div className="input-container mt-2">
        <TextField
          type="password"
          name="password"
          className="text_input"
          id="outlined-basic"
          variant="outlined"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Password "
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.password && touched.password ? (
            <p className="form-error">{errors.password}</p>
          ) : null}
        </div>
      </div>

      <div className="input-container mt-2">
        <TextField
          type="text"
          name="username"
          className="text_input"
          id="outlined-basic"
          variant="outlined"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Username"
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.username && touched.username ? (
            <p className="form-error">{errors.username}</p>
          ) : null}
        </div>
      </div>
      <div className="text-center mt-1 mb-3">
        <button type="submit" className="cusbtn">
          Create
        </button>
      </div>
    </form>
  );
};

//popup data logic for editing SMTP
const Edit_SMTP = (props) => {
  const signUpSchema = Yup.object({
    port: Yup.string().required("Enter Port number"),
    host: Yup.string().required("Enter Host number"),
    password: Yup.string().required("Enter Password"),
    username: Yup.string().required("Enter Username"),
    status: Yup.string().required("Status"),
  });

  const [initialValues, setinitialValues] = useState({
    port: "",
    host: "",
    password: "",
    username: "",
    status: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      changeSmtpvalues(values);
    },
  });
  const SMTP = useSelector((state) => state.Reducer);

  //calling the function ot gte the smtp getting on click of edit popup
  useEffect(() => {
    get_SMTP();
  }, []);

  //getting the values in edit popup
  const get_SMTP = useCallback(async () => {
    try {
      await axiosInstance
        .get(`SmtpSetttings`, {
          httpsAgent: SMTP.HTTP_AGENT,
        })
        .then((response) => {
          console.log("the response is the poirt is:", response.data[0].port);
          setinitialValues({
            port: response.data[0].port,
            host: response.data[0].host,
            password: response.data[0].password,
            username: response.data[0].username,
            status: response.data[0].status,
          });
        });
    } catch (e) {
      console.log(e);
    }
  });

  //editing the smtp values
  const changeSmtpvalues = useCallback(async (e) => {
    try {
      await axiosInstance
        .put(
          `EditSmtpSetttings`,
          {
            smtpsetting_id: props.smtpID,
            status: e.status,
            port: e.port,
          },
          {
            httpsAgent: SMTP.HTTP_AGENT,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            props.get_SMTP();
            props.setEditModal(!props.edit_modal);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            props.setEditModal(!props.edit_modal);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  });

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="input-container mt-2">
        <TextField
          type="text"
          name="port"
          className="text_input"
          id="outlined-basic"
          variant="outlined"
          value={values.port}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Port number"
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.port && touched.port ? (
            <p className="form-error">{errors.port}</p>
          ) : null}
        </div>
      </div>

      <div className="input-container mt-2">
        <TextField
          type="text"
          name="host"
          className="text_input"
          id="outlined-basic"
          variant="outlined"
          value={values.host}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Host name"
          disabled
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.host && touched.host ? (
            <p className="form-error">{errors.host}</p>
          ) : null}
        </div>
      </div>

      <div className="input-container mt-2">
        <TextField
          type="password"
          name="password"
          className="text_input"
          id="outlined-basic"
          variant="outlined"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Password "
          disabled
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.password && touched.password ? (
            <p className="form-error">{errors.password}</p>
          ) : null}
        </div>
      </div>

      <div className="input-container mt-2">
        <TextField
          type="text"
          name="username"
          className="text_input"
          id="outlined-basic"
          variant="outlined"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Username"
          disabled
        />
        <div style={{ height: "20px", textAlign: "left" }}>
          {errors.username && touched.username ? (
            <p className="form-error">{errors.username}</p>
          ) : null}
        </div>
      </div>
      <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="status"
        value={values.status}
        onChange={handleChange}
        style={{ fontWeight: "bold" }}
      >
        <FormControlLabel value="1" control={<Radio />} label="Active" />
        <FormControlLabel value="0" control={<Radio />} label="Deactivate" />
      </RadioGroup>
      <div className="text-center mt-1 mb-3">
        <button type="submit" className="cusbtn">
          Edit
        </button>
      </div>
    </form>
  );
};

const Smtp = (props) => {
  const SMTP = useSelector((state) => state.Reducer);

  //state for popup (add smtp)
  const [Create_event_modal, setCreate_event_modal] = useState(false);
  //state for popup (edit smtp)
  const [edit_modal, setEditModal] = useState(false);
  //state stroing the all smtps (edit smtp)
  const [smtpSetting, setSmtpSettings] = useState();
  //state for storing the ID of the editable popup to send it as the prop to the edit_smtp component
  const [smtpID, setSmtpID] = useState();

  //calling get api on initial page render
  useEffect (() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
    get_SMTP();
    }
  }, [props.tabStyle]);


  //get api for smtp
  const get_SMTP = useCallback(async () => {
    try {
      await axiosInstance
        .get(`SmtpSetttings`, {
          httpsAgent: SMTP.HTTP_AGENT,
        })
        .then((response) => {
          console.log("the response is:", response.data);
          setSmtpSettings(response.data);
        });
    } catch (e) {
      console.log(e);
    }
  });

  return (
    //add smtp popup
    <div>
      {/* create smtp settings modal */}
      {Create_event_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_event_modal(!Create_event_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add SMTP</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_event_modal(!Create_event_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Add_SMTP
                setCreate_event_modal={setCreate_event_modal}
                Create_event_modal={Create_event_modal}
              />
            </div>
          </div>
        </div>
      )}

      {/* edit smtp settings modal*/}
      {edit_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setEditModal(!edit_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit SMTP</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setEditModal(!edit_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Edit_SMTP
                setEditModal={setEditModal}
                edit_modal={edit_modal}
                smtpID={smtpID}
                get_SMTP={get_SMTP}
              />
            </div>
          </div>
        </div>
      )}

      {/* add smtp button */}
      <button
        onClick={() => {
          setCreate_event_modal(!Create_event_modal);
        }}
        className="cusbtn"
      >
        <BiPlusMedical size="0.8rem" /> Add SMTP
      </button>
      {smtpSetting
        ? smtpSetting.map((data) => (
            <div className="card brachcard reporting_manager">
              <div className="mangerdepart">
                <div className="mangerarea">
                  <button
                    className="stylenonebtn"
                    onClick={() => {
                      setSmtpID(data.id);
                      setEditModal(!edit_modal);
                    }}
                  >
                    <BiEdit color="var(--secondary-color)" />
                  </button>
                  <p style={{ fontWeight: "bold" }}>Port Number: {data.port}</p>
                  <p style={{ fontWeight: "bold" }}> Host Name: {data.host}</p>
                  <p style={{ fontWeight: "bold" }}>
                    {" "}
                    User Name: {data.username}
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    {" "}
                    Password: {data.password}
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    {" "}
                    Status:{" "}
                    {data.status === "1" ? (
                      <FaDotCircle style={{ color: "green" }} />
                    ) : (
                      <FaDotCircle style={{ color: "red" }} />
                    )}{" "}
                    {data.status === "1" ? "Active" : "Not Active"}{" "}
                  </p>
                </div>
                <div className="mangerarea">
                  <p style={{ fontWeight: "bold" }}>
                    {/* Host Name:{data.host} */}

                    <IoMailOpenSharp className="shiftbag" />
                  </p>
                </div>
              </div>
            </div>
          ))
        : <p style={{marginTop:"25px", fontSize:"20px",fontWeight:"bold"}}>No Smtp Setting Found</p>}
    </div>
  );
};

export default Smtp;
