import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../utils/AxiosInstance.jsx"; // Import the axiosInstance
import { useDispatch } from "react-redux";
import dot from "../../components/photos/dotimg.png";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Image_sec_all from "./Image_sec_all";
import logo from "../../components/photos/wizsuite_login.png";
import { SETGLOBALAPI } from "../../redux/bulkbtns/Actions";
import swal from "sweetalert";

const Forgetpass = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forget_pass = useSelector((state) => state.Reducer);

  const [loading, setLoading] = useState(false); // Add loading state

  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "300px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom -100px right -100px",
  };

  const initialValues = {
    email: "",
  };

  const signUpSchema = Yup.object({
    email: Yup.string().email("Must be a valid email").required("Enter Email"),
  });

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: (values) => {
        forget(values);
      },
    });

  // forget api
  async function forget(e) {
    setLoading(true); // Start loading by setting it to true
    try {
      const response = await axiosInstance.post(
        `${forget_pass.GLOBAL_API}resetPassword`,
        { email: e.email },
        { httpsAgent: forget_pass.HTTP_AGENT }
      );

      if (response.status === 200) {
        const successMessage =
          response.data.success || "Email sent successfully";
        swal({
          title: "Success!",
          text: successMessage,
          icon: "success",
        });
        setTimeout(() => {
          swal.close();
        }, 2000);
      } else {
        const errorMessage = response.data.message || "Something went wrong";
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
        });
        setTimeout(() => {
          swal.close();
        }, 3000);
      }
    } catch (e) {
      console.log(e);
      const errorMessage = e.response?.data?.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
      });
      setTimeout(() => {
        swal.close();
      }, 3000);
    } finally {
      setLoading(false); // End loading by setting it to false
    }
  }

  return (
    <>
      <div className="loginbox">
        <Image_sec_all />
        <div style={style} className="form">
          <div className="sign_up">
            <h3>Forgot Password</h3>
          </div>
          <div className="signinfull mt-5">
            <div className="hello flex_row_global">
              <h2 style={{ color: "#605eb6" }}>Enter email to get link</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="check_success_sign_up">
                <div className="input_container_sign_up mt-2">
                  <TextField
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                  />
                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.email && touched.email ? (
                      <p className="form-error">{errors.email}</p>
                    ) : null}
                  </div>
                </div>
                <div className="login mt-2">
                  <button
                    type="submit"
                    className="cusbtn"
                    style={{
                      cursor: loading ? "not-allowed" : "pointer",
                    }}
                    disabled={loading} 
                  >
                    {loading ? "Sending..." : "Generate Link"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgetpass;
