import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../Locales/en/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import esTranslation from '../Locales/es/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
    // lng: 'en', 
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already handles safe escaping
    },
  });

export default i18n;
