import React, { useState } from "react";
import MainSidebar from "../../../components/Sidebar/MainSidebar";
import Navbar from "../../Navbar";
import {
  BsBackspaceFill,
  BsPersonFill,
  BsFillBagFill,
  BsFillPersonCheckFill,
  BsFillPersonDashFill,
  BsTriangle,
  BsEyeFill,
} from "react-icons/bs";
import dp from "../../../components/photos/dp.jpg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Salary from "./Salary";
import Overview from "./Overview";
import Reimbursement from "./Reimbursement";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";

function Salary_details() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="main-container">
        <div className="allcontent">
          <Navbar />
          <div className="mainbox">
            <div className="breadcrumbs_pop">
              <div></div>

              <button
                onClick={() => {
                  navigate("/Employees");
                }}
                className="cusbtn"
              >
                <BsBackspaceFill size="1.2rem" />
              </button>
            </div>
            <div className="card employee_edit mt-2">
              <div className="employee_name_desi">
                <img
                  src={dp}
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    margin: "0px 5px 0px 5px",
                  }}
                  alt=""
                />
                <div className="name_designation_empl">
                  <h1>Nisha Singh</h1>
                  {/* <h1>{t('greeting')} </h1> */}
                  <h4>EM5468465</h4>
                  <p>UI & UX Developer</p>
                </div>
              </div>

              <div className="employee_phone_email">
                <div className="phone_email">
                  <p className="padding_only">Phone:</p>
                  <p className="blue_color">+918872731966</p>
                </div>
                <div className="phone_email">
                  <p className="padding_only">Email:</p>
                  <p className="blue_color">zatin56@gmail.com</p>
                </div>
                <div className="phone_email">
                  <p className="padding_only">Birthday:</p>
                  <p>11 June</p>
                </div>
                <div className="phone_email">
                  <p className="padding_only">Gender:</p>
                  <p>Female</p>
                </div>
                <div className="phone_email">
                  <p className="padding_only">Address:</p>
                  <p>1022-A type-2, Rail coach factory</p>
                </div>
              </div>
            </div>

            {/* details */}
            <div className="tabs_deatils">
              <div className="bloc-tabs card mt-3">
                <p style={{ color: "var(--secondary-color) !important" }}>
                  Employee Settings
                </p>
                <p
                  className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(1)}
                >
                  <BsEyeFill /> Overview
                </p>
                <p
                  className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  <FaMoneyCheckAlt />
                  Salary Details
                </p>
                <p
                  className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(3)}
                >
                  <GiReceiveMoney />
                  Reimbursement
                </p>
              </div>

              <div className="content-tabs mt-3 card">
                <div
                  className={
                    toggleState === 1 ? "content  active-content" : "content"
                  }
                >
                  <Overview />
                </div>

                <div
                  className={
                    toggleState === 2 ? "content  active-content" : "content"
                  }
                >
                  <Salary />
                </div>

                <div
                  className={
                    toggleState === 3 ? "content  active-content" : "content"
                  }
                >
                  <Reimbursement />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Salary_details;
