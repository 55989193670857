import React, { useState } from "react";
import Navbar from "../Navbar";
import Complete from "./Onboard_page/Complete";
import Process from "./Onboard_page/Process";

const Onboard_user = () => {
  // radio btn
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedValue = localStorage.getItem("complete_process_toggle");
    return storedValue ? storedValue : "option1";
  }); 

  const handleOptionChange = (event) => {
    localStorage.setItem("complete_process_toggle", event.target.value);
    setSelectedOption(event.target.value);
  };
  const [toggleState, setToggleState] = useState(() => {
    const storedValue = localStorage.getItem("complete_process");
    return storedValue ? parseInt(storedValue) : 1;
  });

  const toggleTab = (index) => {
    localStorage.setItem("complete_process", index);
    setToggleState(index);
  };
  // radio btn

  return (
    <div className="main-container">
      <div className="allcontent">
        <Navbar />

        <div className="mainbox">
          <div className="attendace_radio  mt-1">
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={(e) => {
                    handleOptionChange(e);
                  }}
                  onClick={() => toggleTab(1)}
                />
                <span>Complete</span>
              </label>
            </div>
            <div className="radio_btn">
              <label>
                <input
                  type="radio"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={(e) => {
                    handleOptionChange(e);
                  }}
                  onClick={() => toggleTab(2)}
                />
                <span>In Process</span>
              </label>
            </div>
          </div>

          <div className="mt-2">
            <div
              className={toggleState === 1 ? " active-content" : "content"}
              style={{ width: "100%", overflow: "auto" }}
            >
              <Complete />
            </div>

            <div
             className={toggleState === 2 ? " active-content" : "content"}
              style={{ width: "100%", overflow: "auto" }}
            >
              <Process />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboard_user;
