import React from "react";

const Overview = () => {
  return (
    <>
      <div className="overview">
        <div className="overview_basic m-1">
          <h1 style={{ color:"var(--secondary-color)"}}>Basic Information</h1>
          <div className="basic mb-2">
            <p>Employee ID</p>
            <h1>ABJKBK9876HJ</h1>
          </div>
          <div className="basic mb-2">
            <p>Full Name</p>
            <h1>Nisha Singh</h1>
          </div>
          <div className="basic mb-2">
            <p>Email</p>
            <h1>Nisha@gamil.com</h1>
          </div>
          <div className="basic mb-2">
            <p>Phone</p>
            <h1>87690264783</h1>
          </div>
          <div className="basic mb-2">
            <p>Date of joining</p>
            <h1>1 jan,2023</h1>
          </div>
          <div className="basic mb-2">
            <p>Department</p>
            <h1>IT</h1>
          </div>
        </div>
        <div className="overview_personal m-1">
          <h1 style={{ color:"var(--secondary-color)"}}>Personal Information</h1>
          <div className="basic mb-2">
            <p>Personal phone</p>
            <h1>87462846783</h1>
          </div>
          <div className="basic mb-2">
            <p>Date of birth</p>
            <h1>11 june, 1997</h1>
          </div>
          <div className="basic mb-2">
            <p>Gender</p>
            <h1>Male</h1>
          </div>
          <div className="basic mb-2">
            <p>Marital Status</p>
            <h1>Married</h1>
          </div>
          <div className="basic mb-2">
            <p>Address</p>
            <h1>s-27 Railway Colomy</h1>
          </div>
        </div>
        <div className="overview_payment m-1">
          <h1 style={{ color:"var(--secondary-color)"}}>Payment Information</h1>
          <div className="basic mb-2">
            <p>Bank Name</p>
            <h1>ICIC</h1>
          </div>
          <div className="basic mb-2">
            <p>Bank Account</p>
            <h1>9898-7878-9078</h1>
          </div>
          <div className="basic mb-2">
            <p>IFSC Code</p>
            <h1>ICIC798472398</h1>
          </div>
          <div className="basic mb-2">
            <p>Account Name</p>
            <h1>Nisha Singh</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
