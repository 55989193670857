import React, { useState, useCallback, useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { BiPlusMedical, BiUpload } from "react-icons/bi";
import { CgCloseR } from "react-icons/cg";
import swal from "sweetalert";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../utils/AxiosInstance.jsx"; // Import the axiosInstance

// employee work experience pop up
const WorkExperiencePOpUP = (props) => {
  // Create Event
  // get employee by id

  const [reloadData, setReloadData] = useState(false);

  const reloadDataHandler = () => {
    setReloadData((prevState) => !prevState);
  };
  const employee_page = useSelector((state) => state.Reducer);
  const get_work_byid = useCallback(async () => {
    try {
      let requestData = { previousEmp_id: props.dependent_id };
      if (props.onboard) {
        requestData.onboard_id = props.id;
      } else {
        requestData.employee_id = props.id;
      }
      await axiosInstance
        .post(`PreviousEmployerDetailById`, requestData, {
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update depandant pop=", response.data);
          const date = new Date(response.data[0].worked_from);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;

          const date1 = new Date(response.data[0].address);
          const year1 = date.getFullYear();
          const month1 = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
          const day1 = String(date.getDate()).padStart(2, "0");
          const formattedDate1 = `${year}-${month}-${day}`;
          setinitialValues({
            depandentname: response.data[0].dependent_name,
            depandentrelation: response.data[0].dependent_relation,
            depandentdateofbirth: formattedDate,
            company_name: response.data[0].company_name,
            worked_from: formattedDate,
            worked_to: formattedDate1,
            address: response.data[0].address,
          });
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_work_byid();
  }, [get_work_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    company_name: Yup.string().required("Enter Company Name"),
    worked_from: Yup.string().required("Enter Worked From"),
    worked_to: Yup.string().required("Enter Worked to"),
    address: Yup.string().required("Enter address"),
  });

  const [initialValues, setinitialValues] = useState({
    company_name: "",
    worked_from: "",
    worked_to: "",
    address: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      add_work_put(values);
    },
  });

  // put identity deatils
  async function add_work_put(e) {
    console.log("api put");
    try {
      await axiosInstance
        .put(
          `EditPreviousEmployer`,
          {
            dependent_id: props.dependent_id,
            company_name: e.company_name,
            worked_from: e.worked_from,
            worked_to: e.worked_to,
            address: e.address,
            previousEmp_id: props.dependent_id,
          },
          {
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
          // console.log("update=", response);
          if (response.status == 200) {
            props.get_work_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              props.setedit_work_exp(false);
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              props.setedit_work_exp(false);
              reloadDataHandler();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
        props.setedit_work_exp(false);
      }, 2000);
    }
  }
  // put identity deatils
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="company_name"
            value={values.company_name}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Company Name"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.company_name && touched.company_name ? (
              <p className="form-error">{errors.company_name}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="address"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Address"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.address && touched.address ? (
              <p className="form-error">{errors.address}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="worked_from"
            type="date"
            value={values.worked_from}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Worked From"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.worked_from && touched.worked_from ? (
              <p className="form-error">{errors.worked_from}</p>
            ) : null}
          </div>
        </div>
        <div className="input-container mt-2">
          <TextField
            name="worked_to"
            placeholder=""
            value={values.worked_to}
            type="date"
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            label="Worked To"
            id="outlined-basic"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.worked_to && touched.worked_to ? (
              <p className="form-error">{errors.worked_to}</p>
            ) : null}
          </div>
        </div>
        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
// employee work experience pop up

// employee work experience
const WorkExperience = (props) => {
  // Create Event
  const [Creat_work_exp, setCreat_work_exp] = useState(false);
  const [edit_work_exp, setedit_work_exp] = useState(false);
  const [timeline_value, setTimeline_value] = useState("");
  const [WorkIdforPopUp, setWorkIdforPopUp] = useState("");
  const navigate = useNavigate();

  // get employee by id
  const employee_page = useSelector((state) => state.Reducer);
  const [id_for_put, setId_for_put] = useState("");
  const get_work_byid = useCallback(async () => {
    try {
      let requestData = {};
      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`AllPreviousEmployer`, requestData, {
          headers: {
            Authorization: `Bearer ${employee_page.TOKEN}`,
          },
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("update depandant=", response.data);
          setTimeline_value(response.data);
        });
    } catch (e) {
      // console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_work_byid();
  }, [get_work_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    company_name: Yup.string().required("Enter Company Name"),
    worked_from: Yup.string().required("Enter Worked From"),
    worked_to: Yup.string().required("Enter Worked to"),
    address: Yup.string().required("Enter address"),
  });

  const [initialValues, setinitialValues] = useState({
    company_name: "",
    worked_from: "",
    worked_to: "",
    address: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);

      add_dependent_post(values);
    },
  });

  // add dependent deatils
  async function add_dependent_post(e) {
    try {
      let requestData = {
        company_name: e.company_name,
        worked_from: e.worked_from,
        worked_to: e.worked_to,
        address: e.address,
      };

      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`AddPreviousEmployer`, requestData, {
          headers: {
            Authorization: `Bearer ${employee_page.TOKEN}`,
          },
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            get_work_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              setCreat_work_exp(false);
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              setCreat_work_exp(false);
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
        setCreat_work_exp(false);
      }, 2000);
    }
  }
  // add dependent deatils
  return (
    <>
      {/* pop up */}
      {Creat_work_exp && (
        <div className="modal">
          <div
            onClick={() => {
              setCreat_work_exp(!Creat_work_exp);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Work Experience</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreat_work_exp(!Creat_work_exp);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="input-container mt-2">
                  <TextField
                    name="company_name"
                    value={values.company_name}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.company_name && touched.company_name ? (
                      <p className="form-error">{errors.company_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.address && touched.address ? (
                      <p className="form-error">{errors.address}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="worked_from"
                    type="date"
                    value={values.worked_from}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Worked From"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.worked_from && touched.worked_from ? (
                      <p className="form-error">{errors.worked_from}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input-container mt-2">
                  <TextField
                    name="worked_to"
                    value={values.worked_to}
                    type="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    label="Worked To"
                    id="outlined-basic"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.worked_to && touched.worked_to ? (
                      <p className="form-error">{errors.worked_to}</p>
                    ) : null}
                  </div>
                </div>
                <div className="text-center mb-3">
                  <button type="submit" className="cusbtn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* pop up*/}
      {edit_work_exp && (
        <div className="modal">
          <div
            onClick={() => {
              setedit_work_exp(!edit_work_exp);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Work Experience</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setedit_work_exp(!edit_work_exp);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <WorkExperiencePOpUP
                get_work_byid={get_work_byid}
                setedit_work_exp={setedit_work_exp}
                dependent_id={WorkIdforPopUp}
                onboard={props.onboard}
                id={props.id}
              />
            </div>
          </div>
        </div>
      )}

      {/* <form onSubmit={handleSubmit}> */}
      <div className="addtionalareabox">
        <div className="secondheading mt-1">
          <div className="secondheadingbox">
            <div className="addbtn">
              <h2>Work Experience</h2>
              <button
                className="cusbtnnew"
                type="button"
                onClick={() => {
                  setCreat_work_exp(!Creat_work_exp);
                }}
              >
                <BiPlusMedical size="0.8rem" />
              </button>
            </div>
            <p>Updated Work information</p>
          </div>
        </div>
        <div className="timelinebox mt-2 mb-3">
          {timeline_value
            ? timeline_value.map((ele, index) => {
                return (
                  <div className="dependent" index={index}>
                    <div className="dependentcircle"></div>
                    <div className="depantdetailbox">
                      <div style={{ display: "flex", justifyContent: 'space-between'}}>
                        <h2>{ele.company_name}</h2>{" "}
                        <FaEdit
                          onClick={() => {
                            setWorkIdforPopUp(ele.id);
                            setedit_work_exp(!edit_work_exp);
                          }}
                          style={{
                            color: "var(--secondary-color)",
                            marginLeft: "7px",
                          }}
                        />
                      </div>
                      <p>{ele.address}</p>
                      <p>
                        {ele.worked_from
                          ? ele.worked_from.substring(0, 10)
                          : ele.worked_from}{" "}
                        to{" "}
                        {ele.worked_to
                          ? ele.worked_to.substring(0, 10)
                          : ele.worked_to}
                      </p>
                    </div>
                  </div>
                );
              })
            : "No Work Experience add if you want"}
        </div>
      </div>
      {/* </form> */}
    </>
  );
};
// employee work experience

// education deatil pop up
const EducationPOpUP = (props) => {
  // Create Event
  // get employee by id
  const [reloadData, setReloadData] = useState(false);

  const reloadDataHandler = () => {
    setReloadData((prevState) => !prevState);
  };
  const employee_page = useSelector((state) => state.Reducer);
  const get_work_byid = useCallback(async () => {
    try {
      let requestData = {
        education_id: props.dependent_id,
      };
      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`EducationDetailById`, requestData, {
          headers: {
            Authorization: `Bearer ${employee_page.TOKEN}`,
          },
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update depandant pop=", response.data);
          const date = new Date(response.data[0].date_of_completion);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-based
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          setinitialValues({
            university_name: response.data[0].university_name,
            course: response.data[0].course,
            specialization_name: response.data[0].specialization_name,
            date_of_completion: formattedDate,
          });
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_work_byid();
  }, [get_work_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    university_name: Yup.string().required("Enter university Name"),
    specialization_name: Yup.string().required("Enter specialization name"),
    course: Yup.string().required("Enter course"),
    date_of_completion: Yup.string().required("Enter date of completion"),
  });

  const [initialValues, setinitialValues] = useState({
    university_name: "",
    course: "",
    specialization_name: "",
    date_of_completion: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      add_education_put(values);
    },
  });

  // put identity deatils
  async function add_education_put(e) {
    console.log("api put");
    try {
      await axiosInstance
        .put(
          `EditEducationDetail`,
          {
            dependent_id: props.dependent_id,
            university_name: e.university_name,
            course: e.course,
            specialization_name: e.specialization_name,
            date_of_completion: e.date_of_completion,
            education_id: props.dependent_id,
          },
          {
            httpsAgent: employee_page.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            props.get_work_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              props.setedit_work_exp(false);
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              props.setedit_work_exp(false);
              reloadDataHandler();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
        props.setedit_work_exp(false);
      }, 2000);
    }
  }
  // put identity deatils
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="university_name"
            value={values.university_name}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="University Name"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.university_name && touched.university_name ? (
              <p className="form-error">{errors.university_name}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            name="course"
            value={values.course}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Course"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.course && touched.course ? (
              <p className="form-error">{errors.course}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            name="specialization_name"
            value={values.specialization_name}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Specialization In"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.specialization_name && touched.specialization_name ? (
              <p className="form-error">{errors.specialization_name}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <TextField
            name="date_of_completion"
            type="date"
            value={values.date_of_completion}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Year of Completion"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.date_of_completion && touched.date_of_completion ? (
              <p className="form-error">{errors.date_of_completion}</p>
            ) : null}
          </div>
        </div>

        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
// education deatil pop up

// education deatil
const Education = (props) => {
  // Create Event
  const [Creat_work_exp, setCreat_work_exp] = useState(false);
  const [edit_work_exp, setedit_work_exp] = useState(false);
  const [timeline_value, setTimeline_value] = useState("");
  const [WorkIdforPopUp, setWorkIdforPopUp] = useState("");

  // get employee by id
  const employee_page = useSelector((state) => state.Reducer);
  const get_work_byid = useCallback(async () => {
    try {
      let requestData = {};
      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`AllEducationDetail`, requestData, {
          headers: {
            Authorization: `Bearer ${employee_page.TOKEN}`,
          },
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("all education=", response.data);
          setTimeline_value(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }, []);

  useEffect(() => {
    get_work_byid();
  }, [get_work_byid]);
  // get employee by id

  // Employee details edit start
  const signUpSchema = Yup.object({
    university_name: Yup.string().required("Enter university Name"),
    specialization_name: Yup.string().required("Enter specialization name"),
    course: Yup.string().required("Enter course"),
    date_of_completion: Yup.string().required("Enter date of completion"),
  });

  const [initialValues, setinitialValues] = useState({
    university_name: "",
    course: "",
    specialization_name: "",
    date_of_completion: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      add_education_post(values);
    },
  });

  // add dependent deatils
  async function add_education_post(e) {
    try {
      let requestData = {
        university_name: e.university_name,
        specialization_name: e.specialization_name,
        date_of_completion: e.date_of_completion,
        course: e.course,
      };

      if (props.onboard) {
        requestData.onboard_id = props.id;
        requestData.type = "Onboard";
      } else {
        requestData.employee_id = props.id;
        requestData.type = "Employee";
      }
      await axiosInstance
        .post(`AddEducationDetails`, requestData, {
          headers: {
            Authorization: `Bearer ${employee_page.TOKEN}`,
          },
          httpsAgent: employee_page.HTTP_AGENT,
        })
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            get_work_byid();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              setCreat_work_exp(false);
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
              setCreat_work_exp(false);
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
        setCreat_work_exp(false);
      }, 2000);
    }
  }
  // add dependent deatils
  return (
    <>
      {/* pop up */}
      {Creat_work_exp && (
        <div className="modal">
          <div
            onClick={() => {
              setCreat_work_exp(!Creat_work_exp);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Work Experience</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreat_work_exp(!Creat_work_exp);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="input-container mt-2">
                  <TextField
                    name="university_name"
                    value={values.university_name}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="University Name"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.university_name && touched.university_name ? (
                      <p className="form-error">{errors.university_name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="course"
                    value={values.course}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Course"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.course && touched.course ? (
                      <p className="form-error">{errors.course}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="specialization_name"
                    value={values.specialization_name}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Specialization In"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.specialization_name &&
                    touched.specialization_name ? (
                      <p className="form-error">{errors.specialization_name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="date_of_completion"
                    type="date"
                    value={values.date_of_completion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Year of Completion"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.date_of_completion && touched.date_of_completion ? (
                      <p className="form-error">{errors.date_of_completion}</p>
                    ) : null}
                  </div>
                </div>

                <div className="text-center mb-3">
                  <button type="submit" className="cusbtn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* pop up*/}
      {edit_work_exp && (
        <div className="modal">
          <div
            onClick={() => {
              setedit_work_exp(!edit_work_exp);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Work Experience</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setedit_work_exp(!edit_work_exp);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <EducationPOpUP
                get_work_byid={get_work_byid}
                setedit_work_exp={setedit_work_exp}
                dependent_id={WorkIdforPopUp}
                onboard={props.onboard}
                id={props.id}
              />
            </div>
          </div>
        </div>
      )}

      {/* <form onSubmit={handleSubmit}> */}
      <div className="addtionalareabox">
        <div className="secondheading mt-1">
          <div className="secondheadingbox">
            <div className="addbtn">
              <h2>Education Details</h2>
              <button
                className="cusbtnnew"
                type="button"
                onClick={() => {
                  setCreat_work_exp(!Creat_work_exp);
                }}
              >
                <BiPlusMedical size="0.8rem" />
              </button>
            </div>
            <p>Updated Education Details</p>
          </div>
        </div>
        <div className="timelinebox mt-2 mb-3">
          {timeline_value
            ? timeline_value.map((ele, index) => {
                return (
                  <div className="dependent" index={index}>
                    <div className="dependentcircle"></div>
                    <div className="depantdetailbox">
                      <div style={{ display: "flex", justifyContent: 'space-between' }}>
                        <h2>{ele.university_name}</h2>{" "}
                        <FaEdit
                          onClick={() => {
                            setWorkIdforPopUp(ele.id);
                            setedit_work_exp(!edit_work_exp);
                          }}
                          style={{
                            color: "var(--secondary-color)",
                            marginLeft: "7px",
                          }}
                        />
                      </div>
                      <p>
                        course: {ele.course}{" "}
                        {ele.specialization_name
                          ? `(${ele.specialization_name})`
                          : ""}
                      </p>
                      <p>
                        Date of Complition: 
                        {ele.date_of_completion
                          ? ele.date_of_completion.substring(0, 10)
                          : ele.date_of_completion}
                      </p>
                    </div>
                  </div>
                );
              })
            : "No Education Deatils add if you want"}
        </div>
      </div>
      {/* </form> */}
    </>
  );
};
// education deatil

// main deatils
const Additional_details = (props) => {
  const signUpSchema = Yup.object({
    employeeid: Yup.string().required("Enter Employee Id"),
    fullname: Yup.string().required("Enter Full Name"),
    profilephoto: Yup.mixed()
      .required("Select Profile")
      .test("fileSize", "Max Size 2MB", (file) => {
        if (file) {
          return file.size <= 2000000;
        } else {
          return true;
        }
      }),
    personalemail: Yup.string()
      .email("Not a proper email")
      .required("Enter Email"),
    personalphone: Yup.string().required("Enter Number"),
    dateofbirth: Yup.date().required("Enter Date of Birth"),
    gender: Yup.string().required("Please select a gender"),
    maritalstatus: Yup.string().required("Please select a your Marital Status"),
    persentaddress: Yup.string().required("Enter Persent Address"),
    permanentaddress: Yup.string().required("Enter Permanent Address"),
    uan: Yup.string().required("Enter UAN Number"),
    pan: Yup.string().required("Enter PAN Number"),
    aadhaar: Yup.string().required("Enter Aadhaar Number"),
  });

  const [initialValues, setinitialValues] = useState({
    employeeid: "",
    fullname: "",
    profilephoto: "",
    personalphone: "",
    personalemail: "",
    dateofbirth: "",
    gender: "",
    maritalstatus: "",
    persentaddress: "",
    permanentaddress: "",
    uan: "",
    pan: "",
    aadhaar: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);

      action.resetForm();
    },
  });

  const [editInput, setEditInput] = useState(true);
  //  arr for timeline

  const timeline = [{ name: "Sarman Singh" }, { name: "Nisha Ali" }];
  //  arr for timeline

  return (
    <>
      <div className="headingbox">
        <div className="headingleftbox">
          <h1>Additional Profile</h1>
          <p>Update Additional info</p>
        </div>
      </div>

      <div className="additonal_profile">
        {/* wrok exp */}
        <WorkExperience id={props.id} onboard={props.onboard} />

        {/* educatinal details */}
        <Education id={props.id} onboard={props.onboard} />

        {/* educatinal details */}
      </div>
    </>
  );
};

export default Additional_details;
