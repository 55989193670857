import React from 'react'

const Payroll_Rule = () => {
  return (
    <div>
      Payroll Rule
    </div>
  )
}

export default Payroll_Rule
