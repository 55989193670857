import * as openclose from "./Constants.jsx";

const initialState = {
  SIDEMENUOPENCLOSE: false,
  LIGHTDARKMODE: "light-theme",
  CHECKEDDARKMODE: false,
  EVENT_TABLE_DATA: "",
  VALUE_ACTIVE_LINK: 1,
  VALUE_SUBMENU: true,
  GLOBAL_API: "https://wizsuite-testing.bzbug.com/",
  TOKEN: "",
  REFRESHTOKEN: "",
  COMPANYAPI: "",
  HTTP_AGENT: false,
  EMPLOYEE_EDIT_TOGGLE_STATE: 1,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case openclose.SET_SIDE_MENU_OPEN_CLOSE:
      return { ...state, SIDEMENUOPENCLOSE: action.payload };

    case openclose.SET_LIGHT_DARK_MODE:
      return { ...state, LIGHTDARKMODE: action.payload };

    case openclose.SET_CHECKED_MODE:
      return { ...state, CHECKEDDARKMODE: action.payload };

    case openclose.SET_EVENT_TABLE:
      return { ...state, EVENT_TABLE_DATA: action.payload };

    case openclose.SET_ACTIVE_LINK:
      return { ...state, VALUE_ACTIVE_LINK: action.payload };

    case openclose.SET_MENU_CHECK:
      return { ...state, VALUE_SUBMENU: action.payload };

    case openclose.SET_TOKEN:
      return { ...state, TOKEN: action.payload };

    case openclose.SET_REFRESH_TOKEN:
      return { ...state, REFRESHTOKEN: action.payload };

    case openclose.SET_COMPANY_ID:
      return { ...state, COMPANYAPI: action.payload };

    case openclose.SET_HTTPS:
      return { ...state, HTTP_AGENT: action.payload };

    case openclose.SET_EMPLOYEE_EDIT_TOGGLE_STATE:
      return { ...state, EMPLOYEE_EDIT_TOGGLE_STATE: action.payload };

    case openclose.SET_GLOBAL_API:
      return { ...state, GLOBAL_API: action.payload };

    default:
      return state;
  }
};
