import { legacy_createStore as createStore } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { applyMiddleware } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import Reducer from "./bulkbtns/Reducer";
// import { rootReducer } from "./RootReducer";
// import { AsyncStorage } from "AsyncStorage";

const persistConfig = {
  key: "root",
  storage: storage,
  timeout: null,
};

const rootReducer = combineReducers({
    Reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer,applyMiddleware(thunk));
const persit = persistStore(store);
export  { store, persit };
