import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IoMdAddCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";

const Salary_Settings = () => {
  const { t } = useTranslation();
  const signUpSchema = Yup.object({});

  const [initialValues, setinitialValues] = useState({});

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      // flightform(values);

      action.resetForm();
    },
  });

  const [isChecked_dahra, setIsChecked_dahra] = useState(false);
  const [isChecked_provident, setIsChecked_provident] = useState(false);
  const [isChecked_esi, setIsChecked_esi] = useState(false);
  const [isChecked_tds, setIsChecked_tds] = useState(false);

  const handleToggle = () => {
    setIsChecked_dahra(!isChecked_dahra);
  };
  const provident_handleToggle = () => {
    setIsChecked_provident(!isChecked_provident);
  };
  const esi_handleToggle = () => {
    setIsChecked_esi(!isChecked_esi);
  };
  const tds_handleToggle = () => {
    setIsChecked_tds(!isChecked_tds);
  };

  // add Tds form rows add
  const [rows, setRows] = useState([
    { id: 1, value1: "", value2: "", value3: "" },
  ]);

  const handleInputChange = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleAddRow = () => {
    const id = Date.now();
    console.log("date=", id);
    setRows((prevRows) => [
      ...prevRows,
      { id, value1: "", value2: "", value3: "" },
    ]);
    console.log("roww=", rows);
  };

  const handleDeleteRow = (id) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };
  // add Tds form rows add

  return (
    <>
      <form>
        <div className="toggleboxcontent">
          <div className="toggleboxarea mb-2">
            <div className="toggleboxhead">
              <h2>DA and HRA</h2>
              <div className="switch-toggle">
                <input
                  type="checkbox"
                  value="da_hra"
                  checked={isChecked_dahra}
                  onChange={handleToggle}
                  id="toggle-switch"
                />
                <label htmlFor="toggle-switch"></label>
              </div>
            </div>
            <div className="toggleboxbody">
              {isChecked_dahra && (
                <div className="contentboxdiv">
                  <div className="setting_input_boxes mt-2">
                    <div className="input-container">
                      <input
                        type="text"
                        name="da"
                        className="text_input"
                        id="da"
                        // placeholder="First Name"
                        value={values.da}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className="loginlabel"> {t("DA(%)")}</label>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.da && touched.da ? (
                          <p className="form-error">{errors.da}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-container">
                      <input
                        type="text"
                        name="hra"
                        className="text_input"
                        id="hra"
                        // placeholder="First Name"
                        value={values.contact_person}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className="loginlabel"> {t("HRA(%)")}</label>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.hra && touched.hra ? (
                          <p className="form-error">{errors.hra}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="toggleboxarea mb-2">
            <div className="toggleboxhead">
              <h2>Provident Found Settings</h2>
              <div className="switch-toggle">
                <input
                  type="checkbox"
                  value="provident"
                  checked={isChecked_provident}
                  onChange={provident_handleToggle}
                  id="toggle-switch_provident"
                />
                <label htmlFor="toggle-switch_provident"></label>
              </div>
            </div>
            <div className="toggleboxbody">
              {isChecked_provident && (
                <div className="contentboxdiv">
                  <div className="setting_input_boxes mt-2">
                    <div className="input-container">
                      <input
                        type="text"
                        name="emoloyee_share"
                        className="text_input"
                        id="emoloyee_share"
                        // placeholder="First Name"
                        value={values.emoloyee_share}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className="loginlabel">
                        {" "}
                        {t("Emoloyee Share")}
                      </label>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.emoloyee_share && touched.emoloyee_share ? (
                          <p className="form-error">{errors.emoloyee_share}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-container">
                      <input
                        type="text"
                        name="orgnaiztion_share"
                        className="text_input"
                        id="orgnaiztion_share"
                        // placeholder="First Name"
                        value={values.orgnaiztion_share}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className="loginlabel">
                        {" "}
                        {t("Orgnaiztion Share(%)")}
                      </label>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.orgnaiztion_share &&
                        touched.orgnaiztion_share ? (
                          <p className="form-error">
                            {errors.orgnaiztion_share}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="toggleboxarea mb-2">
            <div className="toggleboxhead">
              <h2>ESI Settings</h2>
              <div className="switch-toggle">
                <input
                  type="checkbox"
                  value="esi"
                  checked={isChecked_esi}
                  onChange={esi_handleToggle}
                  id="toggle-switch_esi"
                />
                <label htmlFor="toggle-switch_esi"></label>
              </div>
            </div>
            <div className="toggleboxbody">
              {isChecked_esi && (
                <div className="contentboxdiv">
                  <div className="setting_input_boxes mt-2">
                    <div className="input-container">
                      <input
                        type="text"
                        name="emoloyee_share_esi"
                        className="text_input"
                        id="emoloyee_share_esi"
                        // placeholder="First Name"
                        value={values.emoloyee_share_esi}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className="loginlabel">
                        {" "}
                        {t("Emoloyee Share")}
                      </label>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.emoloyee_share_esi &&
                        touched.emoloyee_share_esi ? (
                          <p className="form-error">
                            {errors.emoloyee_share_esi}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-container">
                      <input
                        type="text"
                        name="orgnaiztion_share_esi"
                        className="text_input"
                        id="orgnaiztion_share_esi"
                        // placeholder="First Name"
                        value={values.orgnaiztion_share_esi}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className="loginlabel">
                        {" "}
                        {t("Orgnaiztion Share(%)")}
                      </label>

                      <div style={{ height: "20px", textAlign: "left" }}>
                        {errors.orgnaiztion_share_esi &&
                        touched.orgnaiztion_share_esi ? (
                          <p className="form-error">
                            {errors.orgnaiztion_share_esi}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="toggleboxarea mb-2">
            <div className="toggleboxhead">
              <h2>
                TDS{" "}
                <span style={{ fontSize: "12px", fontWeight: "normal" }}>
                  Annual Salary
                </span>
              </h2>
              <div className="switch-toggle">
                <input
                  type="checkbox"
                  value="esi"
                  checked={isChecked_tds}
                  onChange={tds_handleToggle}
                  id="toggle-switch_tds"
                />
                <label htmlFor="toggle-switch_tds"></label>
              </div>
            </div>
            <div className="toggleboxbody">
              {isChecked_tds && (
                <>
                  {/* add more rows  */}
                  <div className="tdsform">
                    {rows.map((row, index) => (
                      <div className="multi_rows mb-2" key={row.id}>
                        {/* three inputs  */}
                        <div className="input-container mt-2 mr-1 ">
                          <input
                            type="text"
                            value={row.value1}
                            className="text_input"
                            onChange={(e) =>
                              handleInputChange(
                                row.id,
                                "value1",
                                e.target.value
                              )
                            }
                          />
                          <label className="loginlabel">Salary Form</label>
                        </div>

                        <div className="input-container mt-2 mr-1">
                          <input
                            type="text"
                            value={row.value2}
                            className="text_input"
                            onChange={(e) =>
                              handleInputChange(
                                row.id,
                                "value2",
                                e.target.value
                              )
                            }
                          />
                          <label className="loginlabel">Salary To</label>
                        </div>

                        <div className="input-container mt-2 mr-1">
                          <input
                            type="text"
                            value={row.value3}
                            className="text_input"
                            onChange={(e) =>
                              handleInputChange(
                                row.id,
                                "value3",
                                e.target.value
                              )
                            }
                          />
                          <label className="loginlabel">(%)</label>
                        </div>
                        {/* three inputs  */}
                        {index === rows.length - 1 ? (
                          <IoMdAddCircle
                            size="1.6em"
                            style={{ color: "var(--primary-color)" }}
                            className="mt-2"
                            onClick={handleAddRow}
                          />
                        ) : (
                          <MdDelete
                            style={{ color: "#be202e" }}
                            size="1.6em"
                            className="mt-2"
                            onClick={() => handleDeleteRow(row.id)}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  {/* add more rows  */}
                </>
              )}
            </div>
          </div>
          <div class="butnsec">
            <button class="cusbtn">Save</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Salary_Settings;
