import React, { useContext, useEffect, useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import MultiStepFormContext from "./MultiStepFormContext";
import swal from "sweetalert";

const Details = () => {
  const navigate = useNavigate();
  const { contact_initial, set_contact_Details, next } =
    useContext(MultiStepFormContext);
  //  check box
  const checkbox = useRef(null);

  // State to keep track of the checkbox value
  const [isChecked, setIsChecked] = useState(false);

  // Initialize state from localStorage
  useEffect(() => {
    const storedValue = localStorage.getItem("rememberMe");
    if (storedValue) {
      setIsChecked(JSON.parse(storedValue));
    }
  }, []);

  // Update localStorage whenever checkbox value changes
  useEffect(() => {
    localStorage.setItem("rememberMe", JSON.stringify(isChecked));
  }, [isChecked]);

  // Handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  //  check box

  // const initialValues = {
  //   contact_profile: "",
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   role: "",
  //   contact_number: "",
  //   password: "",
  //   confirm_password: "",
  // };

  const initialValues = contact_initial;

  const signUpSchema = Yup.object({
    // contact_profile: Yup.mixed()
    //   .required("Select Profile")
    //   .test("fileSize", "Max Size 2MB", (file) => {
    //     if (file) {
    //       return file.size <= 2000000;
    //     } else {
    //       return true;
    //     }
    //   }),

    first_name: Yup.string()
      .required("Enter Your First Name")
      .matches(/^[A-Za-z\s]+$/, "Name must contain only alphabets"),

    last_name: Yup.string()
      .required("Enter Your Last Name")
      .matches(/^[A-Za-z\s]+$/, "Name must contain only alphabets"),

    email: Yup.string()
      .required("Enter Your email")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address"),

    role: Yup.string()
      .required("Select Your Role")
      .min(2, "This is a required field"),

    contact_number: Yup.string()
      .required("Enter Your Contact Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),

    password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Enter Password"),
    
    confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm your password"),
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    // By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      set_contact_Details(values);
      if (checkbox.current.checked == true) {
        next();
      } else {
        // alert fail
        swal({
          title: "Alert!",
          text: "Accept Terms and Conditions",
          icon: "info",
        });
        setTimeout(() => {
          swal.close();
        }, 2000);
        // alert fail
      }
    },
  });

  const fileInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const { t } = useTranslation();
  // password Visibility
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConformPassword] = useState(false);

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConformPassword(!showConfirmPassword);
  };
  // password Visibility
  return (
    <>
      <div className="contact_person mt-2">
        <div className="contact_heading">
          <h1>{t("Contact_Person_Details")} </h1>
        </div>
      </div>
      <div className="formright mt-2">
        <form onSubmit={handleSubmit}>
          {/* <div className="profile_upload">
            {selectedImage ? (
              <img className="set_profile_img" src={selectedImage} alt="" />
            ) : (
              <div className="set_profile_img empty_profile_img">
                <FaUser size="2em" />
              </div>
            )}

            <div className="upload_btn mt-2">
              <button
                type="button"
                className="profilebtn"
                onClick={(e) => {
                  fileInputRef.current.click();
                }}
              >
                <BiUpload /> {t("upload_contact_profile")}
              </button>
              <input
                accept=".png,.jpg,.jpeg"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setSelectedImage(URL.createObjectURL(file));
                  }
                  setFieldValue("contact_profile", e.currentTarget.files[0]);
                }}
                ref={fileInputRef}
                name="contact_profile"
                type="file"
                style={{ display: "none" }}
              />
              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.contact_profile && touched.contact_profile ? (
                  <p className="form-error">{errors.contact_profile}</p>
                ) : null}
              </div>
            </div>
          </div> */}

          <div className="mt-2">
            <div className="input_boxes">
              <div className="input-container-sign-up mt-2">
                <TextField
                  name="first_name"
                  value={values.first_name}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^A-Za-z\s]/g,
                      ""
                    );
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.first_name && touched.first_name ? (
                    <p className="form-error">{errors.first_name}</p>
                  ) : null}
                </div>
              </div>
              <div className="input-container-sign-up mt-2">
                <TextField
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^A-Za-z\s]/g,
                      ""
                    );
                  }}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.last_name && touched.last_name ? (
                    <p className="form-error">{errors.last_name}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="input_boxes">
              <div className="input-container-sign-up mt-2">
                <TextField
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.email && touched.email ? (
                    <p className="form-error">{errors.email}</p>
                  ) : null}
                </div>
              </div>
              <div className="input-container-sign-up mt-2">
                <TextField
                  name="contact_number"
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                    e.target.value = e.target.value.slice(0, 10);
                  }}
                  value={values.contact_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Contact Number"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.contact_number && touched.contact_number ? (
                    <p className="form-error">{errors.contact_number}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="input_boxes">
              <div className="input-container-sign-up mt-2">
                <TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end" style={{ color:"var(--text-color)"}}
                        onClick={handleTogglePasswordVisibility}
                      >
                        {/* <IconButton onClick={handleTogglePasswordVisibility}> */}
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        {/* </IconButton> */}
                      </InputAdornment>
                    ),
                  }}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.password && touched.password ? (
                    <p className="form-error">{errors.password}</p>
                  ) : null}
                </div>
              </div>
              <div className="input-container-sign-up mt-2">
                <TextField
                  name="confirm_password"
                  value={values.confirm_password}
                  type={showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ color:"var(--text-color)"}}
                        onClick={handleToggleConfirmPasswordVisibility}
                      >
                        {/* <IconButton
                          onClick={handleToggleConfirmPasswordVisibility}
                        > */}
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                        {/* </IconButton> */}
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="text_input"
                  id="outlined-basic"
                  label="Confirm Password"
                  variant="outlined"
                />

                <div style={{ height: "20px", textAlign: "left" }}>
                  {errors.confirm_password && touched.confirm_password ? (
                    <p className="form-error">{errors.confirm_password}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="padding_role">
            <div className="role_input mt-2">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Role in organization
                </InputLabel>
                <Select
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role in organization"
                >
                  <MenuItem value="CEO (Chief Executive Officer)">
                    CEO (Chief Executive Officer)
                  </MenuItem>
                  <MenuItem value="COO (Chief Operating Officer)">
                    COO (Chief Operating Officer)
                  </MenuItem>
                  <MenuItem value="CFO (Chief Financial Officer)">
                    CFO (Chief Financial Officer)
                  </MenuItem>
                  <MenuItem value="CTO (Chief Technology Officer)">
                    CTO (Chief Technology Officer)
                  </MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Assistant Manager">
                    Assistant Manager
                  </MenuItem>
                  <MenuItem value="Team Lead/Supervisor">
                    Team Lead/Supervisor
                  </MenuItem>
                  <MenuItem value="Administrative Assistant">
                    Administrative Assistant
                  </MenuItem>
                  <MenuItem value="HR Manager">HR Manager</MenuItem>
                  <MenuItem value="Accountant">Accountant</MenuItem>
                  <MenuItem value="Sales Manager">Sales Manager</MenuItem>
                  <MenuItem value="Marketing Manager">
                    Marketing Manager
                  </MenuItem>
                </Select>
              </FormControl>
              <div style={{ height: "20px", textAlign: "left" }}>
                {errors.role && touched.role ? (
                  <p className="form-error">{errors.role}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="forgetpass mt-3">
            <div className="remember_me">
              <input
                ref={checkbox}
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <p style={{ cursor: "pointer" }}>
                {t("terms")}{" "}
                <span
                  onClick={() => {
                    navigate("/T&C");
                  }}
                >
                  {t("terms1")}
                </span>{" "}
                {t("terms2")}
                <span
                  onClick={() => {
                    navigate("/privacy_policy");
                  }}
                >
                  {t("terms3")}
                </span>{" "}
                {t("terms4")}
              </p>
            </div>
          </div>
          <div>
            <div className="nextbtn mt-3">
              <p className="signInSignUp">
                Already Have a account{" "}
                <span
                  onClick={() => {
                    navigate("/Sign_Up");
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  Log In
                </span>
                ?
              </p>
              <button type="submit" className="cusbtn_next">
                {t("btn_next")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default Details;
