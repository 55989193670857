import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { CgCloseR } from "react-icons/cg";
import { BiEdit } from "react-icons/bi";
import axiosInstance from "../../../utils/AxiosInstance.jsx";
import swal from "sweetalert";

const Add_Designation = (props) => {
  // add Department popup start
  const department = useSelector((state) => state.Reducer);
  // console.log(department);
  const signUpSchema = Yup.object({
    designationname: Yup.string().required("Department Name is Required"),
  });

  const [initialValues, setinitialValues] = useState({
    designationname: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the formd
      add_department(values);

      action.resetForm();
    },
  });

  // put company deatils
  async function add_department(e) {
    try {
      await axiosInstance
        .post(`${department.GLOBAL_API}InsertDesignation`, {
          name: e.designationname,
          company_id: department.COMPANYAPI,
        })
        .then((response) => {
          // console.log("update=", response.data);
          if (response.status == 200) {
            props.setCreate_depart_modal(false);
            props.get_all_department();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            props.setCreate_depart_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      props.setCreate_depart_modal(false);
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  // put company deatils

  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="designationname"
            value={values.designationname}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Designation"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.designationname && touched.designationname ? (
              <p className="form-error">{errors.designationname}</p>
            ) : null}
          </div>
        </div>

        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

const Edit_Designation = (props) => {
  // add Department popup start
  const department = useSelector((state) => state.Reducer);

  const signUpSchema = Yup.object({
    departmentname_edit: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Name must contain only alphabets"
    ),
    departmenthead_edit: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Name must contain only alphabets"
    ),
    departmentemail_edit: Yup.string().matches(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Invalid email address"
    ),
    parentdepartment_edit: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Name must contain only alphabets"
    ),
  });

  const [initialValues, setinitialValues] = useState({
    desiname_edit: props.prop_depart_name || "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      put_single_department(values);
    },
  });
  async function put_single_department(e) {
    try {
      await axiosInstance
        .put(
          `${department.GLOBAL_API}EditDesignation`,
          {
            company_id: department.COMPANYAPI,
            designation_id: props.single_depart_id,
            name: e.desiname_edit,
          }
        )
        .then((response) => {
          // console.log("updated values:", response.data);
          if (response.status == 200) {
            props.setedit_depart_modal(false);
            props.get_all_department();
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 2000 milliseconds (2 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            props.setedit_depart_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 2000 milliseconds (2 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      props.setedit_depart_modal(false);
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // put_single_department api

  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="desiname_edit"
            value={values.desiname_edit}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="Designation"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.desiname_edit && touched.desiname_edit ? (
              <p className="form-error">{errors.desiname_edit}</p>
            ) : null}
          </div>
        </div>

        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

const Designation = (props) => {
  // for Translation
  const [single_depart_id, setSingle_depart_id] = useState("");
  const department = useSelector((state) => state.Reducer);
  const { t } = useTranslation();

  // Add Create popup
  const [Create_depart_modal, setCreate_depart_modal] = useState(false);

  // Edit Create popup
  const [edit_depart_modal, setedit_depart_modal] = useState(false);
  useEffect(() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
      get_all_department();
    }
  }, [props.tabStyle]);

  // get_all_department api
  const [department_arr, setDepartment_arr] = useState("");
  const [prop_depart_name, set_prop_depart_name] = useState("");
  const [prop_depart_head, set_prop_depart_head] = useState("");
  const [prop_depart_email, set_prop_depart_email] = useState("");
  const [prop_parent_depart, set_prop_parent_depart] = useState("");

  async function get_all_department() {
    try {
      await axiosInstance
        .get(`${department.GLOBAL_API}AllDesignation`)
        .then((response) => {
          // console.log("desi", response.data);
          setDepartment_arr(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get_all_department api

  return (
    <>
      {Create_depart_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setCreate_depart_modal(!Create_depart_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Designation</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setCreate_depart_modal(!Create_depart_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Add_Designation
                setCreate_depart_modal={setCreate_depart_modal}
                get_all_department={get_all_department}
                department_arr={department_arr}
                setDepartment_arr={setDepartment_arr}
              />
            </div>
          </div>
        </div>
      )}
      {/* Add pop up*/}

      {/* Edit pop up */}
      {edit_depart_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setedit_depart_modal(!edit_depart_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Department</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setedit_depart_modal(!edit_depart_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Edit_Designation
                setedit_depart_modal={setedit_depart_modal}
                single_depart_id={single_depart_id}
                prop_depart_name={prop_depart_name}
                prop_depart_head={prop_depart_head}
                prop_depart_email={prop_depart_email}
                prop_parent_depart={prop_parent_depart}
                get_all_department={get_all_department}
                department_arr={department_arr}
                setDepartment_arr={setDepartment_arr}
              />
            </div>
          </div>
        </div>
      )}
      {/* Edit pop up*/}

      <div className="addbtnbra">
        <button
          className="cusbtn"
          type="button"
          onClick={() => {
            setCreate_depart_modal(!Create_depart_modal);
          }}
        >
          Add Designation
        </button>
      </div>

      <div className="branchbox mt-2 mb-3">
        {department_arr
          ? department_arr.map((ele, index) => {
              return (
                <div className="card brachcard" key={index}>
                  <div className="departbox">
                    <div className="departleft">
                      <h1>{ele.name}</h1>
                    </div>
                    <div className="editbtndep">
                      <button
                        className="stylenonebtn"
                        onClick={() => {
                          setSingle_depart_id(ele.id);
                          setedit_depart_modal(!edit_depart_modal);
                          set_prop_depart_name(ele.name);
                        }}
                      >
                        <BiEdit color="var(--secondary-color)" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          : <p style={{marginTop:"25px", fontSize:"20px",fontWeight:"bold"}}>No Desigantion Found</p>}
      </div>
    </>
  );
};

export default Designation;
