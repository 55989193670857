import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { CgCloseR } from "react-icons/cg";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import axios from "axios";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import swal from "sweetalert";
import { BiEdit } from "react-icons/bi";
import { FaPlusCircle } from "react-icons/fa";

const Edit_Leave_Type = (props) => {
  const leave_redux = useSelector((state) => state.Reducer);
  // console.log(props)
  const { t } = useTranslation();
  const signUpSchema = Yup.object({
    leave_type: Yup.string().required("Enter Leave Type"),
    leave_for: Yup.string().required("Select Type"),
    no_of_days: Yup.string().required("Enter No of days"),
    carry_forward: Yup.string().required("Select yes or no"),
    auto_approve: Yup.string().required("Select Yes or No"),
    max_limit: Yup.string().required("Enter maximum limit"),
    autoApproveDays: Yup.number().required("Enter Auto Approve Days"),
    encahsment: Yup.string().required("Select yes or no"),
    encahsment_type: Yup.string().required("Select encahsment type"),
    encashment_limit: Yup.string().required("Enter encashment limit"),
  });

  const [initialValues, setinitialValues] = useState({
    leave_type: "",
    leave_for: "",
    no_of_days: "",
    carry_forward: "",
    auto_approve: "",
    autoApproveDays: "",
    max_limit: "",
    encahsment: "",
    encahsment_type: "",
    encashment_limit: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      edit_leave_type(values);
    },
  });
  // add leave deatils
  async function edit_leave_type(e) {
    try {
      await axios
        .put(
          `${leave_redux.GLOBAL_API}EditLeaveType`,
          {
            type: e.leave_type,
            leave_for: e.leave_for,
            days: e.no_of_days,
            carryforward: e.carry_forward,
            auto_approve: e.auto_approve,
            auto_approve_days: e.autoApproveDays,
            encashment: e.encahsment,
            max_limit: e.max_limit,
            encashment_type: e.encahsment_type,
            encashment_limit: e.encashment_limit,
            leaveType_id: props.leave_id,
          },
          {
            headers: {
              Authorization: `Bearer ${leave_redux.TOKEN}`,
            },
            httpsAgent: leave_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            props.setEdit_leave_modal(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            props.setEdit_leave_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      props.setEdit_leave_modal(false);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add leave deatils
  // get single leave
  useEffect(() => {
    set_all_leave();
  }, []);

  async function set_all_leave() {
    try {
      await axios
        .post(
          `${leave_redux.GLOBAL_API}LeaveTypeById`,
          {
            leaveType_id: props.leave_id,
            // company_id: leave_redux.COMPANYAPI,
          },
          {
            headers: {
              Authorization: `Bearer ${leave_redux.TOKEN}`,
            },
            httpsAgent: leave_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("edit leave=", response.data);
          setinitialValues({
            leave_type: response.data[0].type,
            leave_for: response.data[0].leave_for,
            no_of_days: response.data[0].days,
            carry_forward: response.data[0].carryforward,
            auto_approve: response.data[0].auto_approve,
            autoApproveDays: response.data[0].auto_approve_days,
            max_limit: response.data[0].max_limit,
            encahsment: response.data[0].encashment,
            encahsment_type: response.data[0].encashment_type,
            encashment_limit: response.data[0].encashment_limit,
          });
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get single leave

  // pop up code and submit
    // List of leave types that should disable the input
    const disableLeaveTypes = ["Paternity", "Maternity", "Bereavement","Compoff"];
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-container mt-2">
          <TextField
            name="leave_type"
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(
                /[^A-Za-z\s]/g,
                ""
              );
            }}
            value={values.leave_type}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            disabled={disableLeaveTypes.includes(values.leave_type)}
            id="outlined-basic"
            label="Leave Type"
            variant="outlined"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.leave_type && touched.leave_type ? (
              <p className="form-error">{errors.leave_type}</p>
            ) : null}
          </div>
        </div>

        <div className="input-container mt-2">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Leave For</InputLabel>
            <Select
              name="leave_for"
              value={values.leave_for}
              onChange={handleChange}
              onBlur={handleBlur}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="leave_for"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
          </FormControl>

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.leave_for && touched.leave_for ? (
              <p className="form-error">{errors.leave_for}</p>
            ) : null}
          </div>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Auto Approve</h1>
          <div>
            <label>
              <input
                style={{ marginLeft: "10px" }}
                type="radio"
                name="auto_approve"
                value="1"
                checked={values.auto_approve === "1"}
                onChange={(e) => {
                  setFieldValue("auto_approve", e.target.value);
                }}
              />
              Yes
            </label>
            <label>
              <input
                style={{ marginLeft: "10px" }}
                type="radio"
                name="auto_approve"
                value="0"
                checked={values.auto_approve === "0"}
                onChange={(e) => {
                  setFieldValue("auto_approve", e.target.value);
                }}
              />
              No
            </label>
            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.auto_approve && touched.auto_approve ? (
                <p className="form-error">{errors.auto_approve}</p>
              ) : null}
            </div>
          </div>
        </div>
        {values.auto_approve === "1" && (
          <div className="input-container mt-2">
            <TextField
              name="autoApproveDays"
              value={values.autoApproveDays}
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              className="text_input"
              id="outlined-basic"
              label="Auto Approve Days"
              variant="outlined"
              type="text"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.autoApproveDays && touched.autoApproveDays ? (
                <p className="form-error">{errors.autoApproveDays}</p>
              ) : null}
            </div>
          </div>
        )}

        <div className="input-container mt-2">
          <TextField
            name="no_of_days"
            value={values.no_of_days}
            onInput={(e) => {
              e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className="text_input"
            id="outlined-basic"
            label="No of Days"
            variant="outlined"
            type="text"
          />

          <div style={{ height: "20px", textAlign: "left" }}>
            {errors.no_of_days && touched.no_of_days ? (
              <p className="form-error">{errors.no_of_days}</p>
            ) : null}
          </div>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Carry Forward</h1>
          <div>
            <label>
              <input
                style={{ marginLeft: "10px" }}
                type="radio"
                name="carry_forward"
                value="1"
                checked={values.carry_forward === "1"}
                onChange={(e) => {
                  setFieldValue("carry_forward", e.target.value);
                }}
              />
              Yes
            </label>
            <label>
              <input
                style={{ marginLeft: "10px" }}
                type="radio"
                name="carry_forward"
                value="0"
                checked={values.carry_forward === "0"}
                onChange={(e) => {
                  setFieldValue("carry_forward", e.target.value);
                }}
              />
              No
            </label>
            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.carry_forward && touched.carry_forward ? (
                <p className="form-error">{errors.carry_forward}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="shift_input_boxes">
          Maximum number of leaves which can be carried forward =
          <div className="input-container mr-1 mt-2">
            <TextField
              name="max_limit"
              type="text"
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
              }}
              value={values.max_limit}
              onChange={handleChange}
              onBlur={handleBlur}
              className="text_input"
              id="outlined-basic"
              label="Max Limit"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.max_limit && touched.max_limit ? (
                <p className="form-error">{errors.max_limit}</p>
              ) : null}
            </div>
          </div>
        </div>

        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Encashment</h1>
          <div>
            <label>
              <input
                type="radio"
                name="encahsment"
                value="1"
                checked={values.encahsment === "1"}
                onChange={(e) => {
                  setFieldValue("encahsment", e.target.value);
                }}
              />{" "}
              Yes
            </label>
            <label>
              <input
                style={{ marginLeft: "10px" }}
                type="radio"
                name="encahsment"
                value="0"
                checked={values.encahsment === "0"}
                onChange={(e) => {
                  setFieldValue("encahsment", e.target.value);
                }}
              />{" "}
              No
            </label>
            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.encahsment && touched.encahsment ? (
                <p className="form-error">{errors.encahsment}</p>
              ) : null}
            </div>
          </div>
        </div>

        {/* Radio button group for encahsment_type */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginBottom: "5px" }}>
            <label>
              <input
                type="radio"
                name="encahsment_type"
                value="Basic Salary"
                checked={values.encahsment_type === "Basic Salary"}
                onChange={handleChange}
              />{" "}
              Basic
            </label>
            <label>
              <input
                style={{ marginLeft: "5px" }}
                type="radio"
                name="encahsment_type"
                value="Total Salary"
                checked={values.encahsment_type === "Total Salary"}
                onChange={handleChange}
              />{" "}
              Total Salary
            </label>
            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.encahsment_type && touched.encahsment_type ? (
                <p className="form-error">{errors.encahsment_type}</p>
              ) : null}
            </div>
          </div>
          <div style={{ width: "40%" }}>
            <TextField
              name="encashment_limit"
              type="text"
              onInput={(e) => {
                e.target.value = (e.target.value || "").replace(/[^\d]/g, "");
              }}
              value={values.encashment_limit}
              onChange={handleChange}
              onBlur={handleBlur}
              className="text_input"
              id="outlined-basic"
              label="Max Limit"
              variant="outlined"
            />

            <div style={{ height: "20px", textAlign: "left" }}>
              {errors.encashment_limit && touched.encashment_limit ? (
                <p className="form-error">{errors.encashment_limit}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="text-center mb-3">
          <button type="submit" className="cusbtn">
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

const Leave_Type = (props) => {
  const leave_redux = useSelector((state) => state.Reducer);
  const { t } = useTranslation();
  const [all_leave_type, setAll_leave_type] = useState("");
  const [leave_id, setLeave_id] = useState("");
  const [leave_modal, setLeave_modal] = useState(false);
  const [edit_leave_modal, setEdit_leave_modal] = useState(false);
  const [auto_approvestatus, setAuto_approvestatus] = useState(false);
  // get single leave
  useEffect (() => {
    if(props.tabStyle==="activeContent"){
      // console.log("the api will be called");
    get_all_leave();
    }
  }, [props.tabStyle]);
  
  async function get_all_leave() {
    try {
      await axios
        .get(`${leave_redux.GLOBAL_API}AllLeaveType`, {
          headers: {
            Authorization: `Bearer ${leave_redux.TOKEN}`,
          },
          httpsAgent: leave_redux.HTTP_AGENT,
        })
        .then((response) => {
          // console.log("single=", response.data);
          setAll_leave_type(response.data);
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  // get single leave

  // toggle btns
  const [leaveTypeStates, setLeaveTypeStates] = useState({});

  // Assuming all_leave_type contains an array of objects with a "type" and "status" property
  const initializeLeaveTypeStates = () => {
    if (Array.isArray(all_leave_type)) {
      const initialStates = {};
      all_leave_type.forEach((ele) => {
        initialStates[ele.type] = ele.status === "1";
      });
      setLeaveTypeStates(initialStates);
    }
  };

  const handleToggleClick = (type, id) => {
    const currentStatus = leaveTypeStates[type] ? "0" : "1";
    console.log(
      `Toggle button for ${type} clicked. Current status: ${currentStatus}`
    );
    edit_leave_type(id, currentStatus);
  };

  // add leave deatils

  async function edit_leave_type(id, status) {
    try {
      await axios
        .put(
          `${leave_redux.GLOBAL_API}EditLeaveType`,
          {
            status: status,
            leaveType_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${leave_redux.TOKEN}`,
            },
            httpsAgent: leave_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);

      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add leave deatils
  useEffect(() => {
    initializeLeaveTypeStates();
  }, [all_leave_type]);

  const handleToggleLeave = (type) => {
    setLeaveTypeStates((prevStates) => ({
      ...prevStates,
      [type]: !prevStates[type],
    }));
  };
  // toggle btns

  // pop up code and submit
  const signUpSchema = Yup.object({
    leave_type: Yup.string().required("Enter Leave Type"),
    leave_for: Yup.string().required("Select Type"),
    no_of_days: Yup.string().required("Enter No of days"),
    carry_forward: Yup.string().required("Select yes or no"),
    max_limit: Yup.string().required("Enter maximum limit"),

    auto_approve: Yup.boolean(),
    autoApproveDays: Yup.string().test(
      "conditional-required",
      "Enter Auto Approve Days",
      function (value) {
        // Access the value of auto_approve
        const { auto_approve } = this.parent;

        // If auto_approve is true, autoApproveDays must not be empty
        if (auto_approve === true) {
          return value
            ? true
            : this.createError({
                path: "autoApproveDays",
                message: "Enter Auto Approve Days",
              });
        }
        // If auto_approve is false, validation for autoApproveDays is skipped
        return true;
      }
    ),

    encahsment: Yup.boolean(),
    encahsment_type: Yup.string().test(
      "conditional-required",
      "Select encashment type",
      function (value) {
        // Access the value of auto_approve
        const { encahsment } = this.parent;

        // If encahsment is true, autoApproveDays must not be empty
        if (encahsment === true) {
          return value
            ? true
            : this.createError({
                path: "encahsment_type",
                message: "Select encashment type",
              });
        }
        // If encahsment is false, validation for autoApproveDays is skipped
        return true;
      }
    ),
    encashment_limit: Yup.string().test(
      "conditionalRequired",
      "Enter encashment limit",
      function (value) {
        const { encahsment } = this.parent; // Access other fields in the form

        if (encahsment === true) {
          return value
            ? true
            : this.createError({
                path: "encashment_limit",
                message: "Enter encashment limit",
              });
        }

        return true; // Return true for non-required case
      }
    ),
  });

  const [initialValues, setinitialValues] = useState({
    leave_type: "",
    leave_for: "All",
    no_of_days: "",
    carry_forward: "",
    auto_approve: "0",
    autoApproveDays: "",
    max_limit: "",
    encahsment: "0",
    encahsment_type: "",
    encashment_limit: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("leave", values);
      add_leave_type(values);
    },
  });
  console.log(errors);
  // add leave deatils
  async function add_leave_type(e) {
    try {
      await axios
        .post(
          `${leave_redux.GLOBAL_API}InsertLeaveType`,
          {
            type: e.leave_type,
            leave_for: e.leave_for,
            days: e.no_of_days,
            carryforward: e.carry_forward,
            auto_approve: e.auto_approve,
            auto_approve_days: e.autoApproveDays,
            encashment: e.encahsment,
            max_limit: e.max_limit,
            encashment_type: e.encahsment_type,
            encashment_limit: e.encashment_limit,
          },
          {
            headers: {
              Authorization: `Bearer ${leave_redux.TOKEN}`,
            },
            httpsAgent: leave_redux.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            setLeave_modal(false);
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            setLeave_modal(false);
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      setLeave_modal(false);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // add leave deatils

  // pop up code and submit
  return (
    <>
      {/* Add pop up */}
      {leave_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setLeave_modal(!leave_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Leave Type</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setLeave_modal(!leave_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} className="mt-4">
                <div className="input-container mt-2">
                  <TextField
                    name="leave_type"
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                    }}
                    value={values.leave_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="Leave Type"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.leave_type && touched.leave_type ? (
                      <p className="form-error">{errors.leave_type}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Leave For
                    </InputLabel>
                    <Select
                      name="leave_for"
                      value={values.leave_for}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="leave_for"
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </Select>
                  </FormControl>

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.leave_for && touched.leave_for ? (
                      <p className="form-error">{errors.leave_for}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input-container mt-2">
                  <TextField
                    name="no_of_days"
                    value={values.no_of_days}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^\d]/g,
                        ""
                      );
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    id="outlined-basic"
                    label="No of Days"
                    variant="outlined"
                    type="text"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.no_of_days && touched.no_of_days ? (
                      <p className="form-error">{errors.no_of_days}</p>
                    ) : null}
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1>Carry Forward</h1>
                  <div>
                    <label>
                      <input
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="carry_forward"
                        value="1"
                        checked={values.carry_forward === "1"}
                        onChange={(e) => {
                          setFieldValue("carry_forward", e.target.value);
                        }}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="carry_forward"
                        value="0"
                        checked={values.carry_forward === "0"}
                        onChange={(e) => {
                          setFieldValue("carry_forward", e.target.value);
                        }}
                      />
                      No
                    </label>
                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.carry_forward && touched.carry_forward ? (
                        <p className="form-error">{errors.carry_forward}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* auto approve */}

                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1>Auto Approve</h1>
                  <div>
                    <label>
                      <input
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="auto_approve"
                        value="1"
                        checked={values.auto_approve === "1"}
                        onChange={(e) => {
                          setFieldValue("auto_approve", e.target.value);
                          values.auto_approve === "1"
                            ? setAuto_approvestatus(true)
                            : setAuto_approvestatus(false);
                        }}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="auto_approve"
                        value="0"
                        checked={values.auto_approve === "0"}
                        onChange={(e) => {
                          setFieldValue("auto_approve", e.target.value);
                          values.auto_approve === "0"
                            ? setAuto_approvestatus(false)
                            : setAuto_approvestatus(true);
                        }}
                      />
                      No
                    </label>
                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.auto_approve && touched.auto_approve ? (
                        <p className="form-error">{errors.auto_approve}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* this is will be rendered if auto approve is true */}
                {values.auto_approve === "1" && (
                  <div className="input-container mt-2">
                    <TextField
                      name="autoApproveDays"
                      value={values.autoApproveDays}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="outlined-basic"
                      label="Auto Approve Days"
                      variant="outlined"
                      type="text"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.autoApproveDays && touched.autoApproveDays ? (
                        <p className="form-error">{errors.autoApproveDays}</p>
                      ) : null}
                    </div>
                  </div>
                )}
                {/* auto approve */}
                {/* max limit */}
                <div className="shift_input_boxes">
                  <div className="input-container mr-1 mt-2">
                    <TextField
                      name="max_limit"
                      type="text"
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                      }}
                      value={values.max_limit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="text_input"
                      id="outlined-basic"
                      label="Max Limit"
                      variant="outlined"
                    />

                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.max_limit && touched.max_limit ? (
                        <p className="form-error">{errors.max_limit}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* max limit */}
                {/* Encashment */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1>Encashment</h1>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="encahsment"
                        value="1"
                        checked={values.encahsment === "1"}
                        onChange={(e) => {
                          setFieldValue("encahsment", e.target.value);
                        }}
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        style={{ marginLeft: "10px" }}
                        type="radio"
                        name="encahsment"
                        value="0"
                        checked={values.encahsment === "0"}
                        onChange={(e) => {
                          setFieldValue("encahsment", e.target.value);
                        }}
                      />{" "}
                      No
                    </label>
                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.encahsment && touched.encahsment ? (
                        <p className="form-error">{errors.encahsment}</p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {values.encahsment === "1" && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        <label>
                          <input
                            type="radio"
                            name="encahsment_type"
                            value="Basic Salary"
                            checked={values.encahsment_type === "Basic Salary"}
                            onChange={handleChange}
                          />{" "}
                          Basic
                        </label>
                        <label>
                          <input
                            style={{ marginLeft: "5px" }}
                            type="radio"
                            name="encahsment_type"
                            value="Total Salary"
                            checked={values.encahsment_type === "Total Salary"}
                            onChange={handleChange}
                          />{" "}
                          Total Salary
                        </label>
                        <div style={{ height: "20px", textAlign: "left" }}>
                          {errors.encahsment_type && touched.encahsment_type ? (
                            <p className="form-error">
                              {errors.encahsment_type}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div style={{ width: "40%" }}>
                        <TextField
                          name="encashment_limit"
                          type="text"
                          onInput={(e) => {
                            e.target.value = (e.target.value || "").replace(
                              /[^\d]/g,
                              ""
                            );
                          }}
                          value={values.encashment_limit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="text_input"
                          id="outlined-basic"
                          label="Max Limit"
                          variant="outlined"
                        />

                        <div style={{ height: "20px", textAlign: "left" }}>
                          {errors.encashment_limit &&
                          touched.encashment_limit ? (
                            <p className="form-error">
                              {errors.encashment_limit}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Encashment */}

                <div className="text-center mb-3">
                  <button type="submit" className="cusbtn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {/* Add pop up*/}

      {/* edit leave  */}
      {edit_leave_modal && (
        <div className="modal">
          <div
            onClick={() => {
              setEdit_leave_modal(!edit_leave_modal);
            }}
            className="overlay"
          ></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Leave Type</h2>
              <button
                className="close-modal"
                onClick={() => {
                  setEdit_leave_modal(!edit_leave_modal);
                }}
              >
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <Edit_Leave_Type
                leave_id={leave_id}
                setEdit_leave_modal={setEdit_leave_modal}
              />
            </div>
          </div>
        </div>
      )}
      {/* edit leave  */}

      <div className="leftBox">
        <div
          className="footerbuttonplus"
          onClick={() => {
            setLeave_modal(!leave_modal);
          }}
          style={{ cursor: "pointer" }}
        >
          <button className="stylenonebtn">
            <FaPlusCircle color="var(--secondary-color)" />
          </button>
          <h1 className="">Add Leave Type</h1>
        </div>
        {all_leave_type
          ? all_leave_type.map((ele, index) => {
              const isChecked = leaveTypeStates[ele.type] || false;
              return (
                <div className="leavetypesty attendancearea mt-2" key={index}>
                  <div className="leavesty">
                    <h1>{ele.type}</h1>
                    <p>{ele.days} days</p>
                  </div>
                  <div className="secondWrapper">
                    <div className="switch-toggle">
                      <input
                        type="checkbox"
                        onClick={() => handleToggleClick(ele.type, ele.id)}
                        checked={isChecked}
                        onChange={() => handleToggleLeave(ele.type)}
                        id={`toggle-${ele.type}`}
                      />
                      <label htmlFor={`toggle-${ele.type}`}></label>
                    </div>

                    <BiEdit
                      size="25px"
                      style={{ cursor: "pointer", marginLeft: "15px" }}
                      onClick={() => {
                        setEdit_leave_modal(!edit_leave_modal);
                        setLeave_id(ele.id);
                      }}
                      color="var(--secondary-color)"
                    />
                  </div>
                </div>
              );
            })
          : <p style={{marginTop:"25px", fontSize:"20px",fontWeight:"bold"}}>No Leave Type Found</p>}
      </div>
    </>
  );
};

export default Leave_Type;
