import * as openclose from "./Constants.jsx";

export const SetSideMenuOpenClose = (payload)  => ({
    type: openclose.SET_SIDE_MENU_OPEN_CLOSE,
    payload,
});

export const SETLIGHTDARKMODE = (payload) => ({
  type: openclose.SET_LIGHT_DARK_MODE,
  payload,
});

export const SETCHECKEDMODE = (payload) => ({
    type: openclose.SET_CHECKED_MODE,
    payload,
  });
  
  export const SETEVENTAPI = (payload) => ({
    type: openclose.SET_EVENT_TABLE,
    payload,
  });

  export const SETACTiVELINK = (payload) => ({
    type: openclose.SET_ACTIVE_LINK,
    payload,
  });

  export const SETSUBMENU = (payload) => ({
    type: openclose.SET_MENU_CHECK,
    payload,
  });

  export const SETTOKEN = (payload) => ({
    type: openclose.SET_TOKEN,
    payload,
  });

  export const SETREFRESHTOKEN = (payload) => ({
    type: openclose.SET_REFRESH_TOKEN,
    payload,
  });

  export const SETCOMPANYID = (payload) => ({
    type: openclose.SET_COMPANY_ID,
    payload,
  });

  export const SETHTTPS = (payload) => ({
    type: openclose.SET_HTTPS,
    payload,
  });

  export const EMPLOYEDITOGGLESTATE = (payload) => ({
    type: openclose.SET_EMPLOYEE_EDIT_TOGGLE_STATE,
    payload,
  });


  export const SETGLOBALAPI = (payload) => ({
    type: openclose.SET_GLOBAL_API,
    payload,
  });



