import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Image_sec_all from "./Image_sec_all";
import dot from "../../components/photos/dotimg.png";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { SETTOKEN } from "../../redux/bulkbtns/Actions";
import { SETCOMPANYID } from "../../redux/bulkbtns/Actions";
import { SETREFRESHTOKEN } from "../../redux/bulkbtns/Actions";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

const Sign_Up = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sign_up = useSelector((state) => state.Reducer);

  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "300px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom -100px right -100px",
  };

  // state for RememberMe
  const [remember, setRemember] = useState(false);
  // state for RememberMe
  const initialValues = {
    email: "",
    password: "",
  };

  const signUpSchema = Yup.object({
    email: Yup.string().email("Must be a valid email").required("Enter Email"),
    password: Yup.string().required("Enter Password"),
  });

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: (values, action) => {
        // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        login(values);
      },
    });

  // register api
  async function login(e) {
    // console.log("hi");
    try {
      await axios
        .post(
          `${sign_up.GLOBAL_API}WizLogin`,
          {
            email: e.email,
            password: e.password,
          },
          {
            httpsAgent: sign_up.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            console.log(response.data.message);
            dispatch(SETTOKEN(response.data.token));
            localStorage.setItem("accessToken", `${response.data.token}`);
            localStorage.setItem("remember", remember);
            dispatch(SETREFRESHTOKEN(response.data.refreshToken));
            localStorage.setItem(
              "refreshToken",
              `${response.data.refreshToken}`
            );
            navigate("/Dashboard");
            // get_company_setting(response.data.token);
            async function get_company_setting(token) {
              try {
                await axios
                  .get(`${sign_up.GLOBAL_API}CompanyDetail`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    httpsAgent: sign_up.HTTP_AGENT,
                  })
                  .then((response) => {
                    // console.log(response.data[0].id);
                    console.log(response);
                    dispatch(SETCOMPANYID(response.data[0].id));
                    if (response.status == 200) {
                      // navigate("/Dashboard/Dashboard");
                    }
                  });
              } catch (e) {
                console.log(e);
                console.log(e.response.data.error);
              }
            }
          } else {
            // alert fail
            // console.log("hi")

            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 3000);
            // alert fail
          }
        });
    } catch (e) {
      console.log("hi", e.response.data.error);
      // alert fail
      const errorMessage = e.response.data.error || "Something went wrong";

      // alert fail

      if (e.response.data.error == "Email Not Verified") {
        swal({
          title: "Email Not Verified!",
          text: "Email is send to verify to your mail",
          icon: "info",
        });

        // Close the SweetAlert after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          swal.close();
        }, 4000);
        VerifyEmail(e.response.data.userid);
        async function VerifyEmail(userid) {
          try {
            await axios
              .post(
                `${sign_up.GLOBAL_API}emailVerify`,
                {
                  userid: userid,
                },
                {
                  httpsAgent: sign_up.HTTP_AGENT,
                }
              )
              .then((response) => {
                console.log(response);
              });
          } catch (e) {
            console.log(e);
            console.log(e.response.data);
          }
        }
      } else {
        swal({
          title: "Failure!",
          text: errorMessage,
          icon: "error",
        });

        // Close the SweetAlert after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    }
  }
  // register api

  // password Visibility
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="loginbox">
        <Image_sec_all />

        <div style={style} className="form">
          <div
            className="sign_up"
            style={{ backgroundColor: "var(--main-color)" }}
          >
            <h3>{t("Sign_in")}</h3>
          </div>

          <div className="signinfull mt-5">
            <div className="hello flex_row_global">
              <h2 style={{ color: "#605eb6" }}>{t("Hello_Welcome")}</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="check_success_sign_up">
                <div className="input_container_sign_up mt-2">
                  <TextField
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    label="Email"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.email && touched.email ? (
                      <p className="form-error">{errors.email}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input_container_sign_up mt-2">
                  <TextField
                    name="password"
                    value={values.password}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton style={{ color:"var(--text-color)"}} onClick={handleTogglePasswordVisibility}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="text_input"
                    label="Password"
                    variant="outlined"
                  />

                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.password && touched.password ? (
                      <p className="form-error">{errors.password}</p>
                    ) : null}
                  </div>
                </div>

                <div className="remember">
                  <div className="remember_me">
                    <input style={{ cursor:"pointer"}}
                      type="checkbox"
                      value={remember}
                      onChange={() => {
                        setRemember(!remember);
                      }}
                    />
                    <p>{t("Remember_Me")}</p>
                  </div>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/Forgetpass");
                    }}
                  >
                    {t("Forget_Password")}
                  </p>
                </div>
                <p className="signInSignUp">
                  Do not have a account{" "}
                  <span
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    Sign Up
                  </span>
                  ?
                </p>

                <div className="login mt-4">
                  <button className="cusbtn"> {t("Log_in")}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sign_Up;
