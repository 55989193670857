import React from "react";
import logo from "../../components/photos/wizsuite_login.png";
import dot from "../../components/photos/dotimg.png";
import MultiStepForm from "./MultiStepForm";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Box } from "@mui/material";
import { CenterFocusStrong } from "@mui/icons-material";
import { Typography } from "antd";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // useEffect(() => {}, []);

  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "719px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom -321px right -404px",
  };

  return (
    <>
      <div className="loginbox">
        <div className="image">
          <div className="about_wiz ">
            <div className="img_logo">
              <img src={logo} alt="" />
            </div>

            <div className="welcome mt-5" style={{ textAlign:"center"}}>
              <h1>{t("Welcome_To_WizSuite")}</h1>
              <p style={{ padding:"0px 5px"}}>
                We are thrilled to have you join our community of HR
                professionals committed to enhancing workplace efficiency and
                employee satisfaction.  Get
                started today and experience the benefits of a powerful HR
                management tool that puts you in control. Your journey to a more
                organized and effective HR strategy begins here.
              </p>
            </div>

            <button
              onClick={() => {
                navigate("/Sign_Up");
              }}
              className="secondary-btn"
            >
              {t("Sign_in")}
            </button>
          </div>
        </div>
        <div className="form" style={style}>
          <div style={style}></div>
          <MultiStepForm />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Login;
