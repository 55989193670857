import React, { useState } from "react";
import { Steps } from "antd";
import { Provider } from "./MultiStepFormContext";
import Contact_details from "./Contact_details";
import Organisation_details from "./Organisation_details";
import Select_department from "./Select_department";
import Create_branch from "./Create_branch";
import { useTranslation } from "react-i18next";
import Shift from "./Shift";
import Congradulation from "./Congradulation";

const { Step } = Steps;

const contact_details = {
  contact_profile: "",
  first_name: "",
  last_name: "",
  email: "",
  role: "",
  contact_number: "",
  password: "",
  confirm_password: "",
};

const Organisation_detail = {
  company_logo: "",
  company: "",
  registration: "",
  website: "",
  addressline1: "",
  addressline2: "",
  country: "",
  state: "",
  city: "",
  pincode: "",
};

const branch_detail = {
  branch_name: "",
  branch_phone: "",
  branch_city: "",
  branch_latitude: "",
  branch_longitude: "",
};

const renderStep = (step) => {
  switch (step) {
    case 0:
      return <Contact_details />;
    case 1:
      return <Organisation_details />;
    // case 2:
    //   return <Create_branch />;
    // case 3:
    //   return <Select_department />;
    // case 4:
    //   return <Shift />;
    case 2:
      return <Congradulation />;
    default:
      return null;
  }
};

const MultiStepForm = () => {
  const { t } = useTranslation();
  const [contact_initial, set_contact_Details] = useState(contact_details);
  const [organisation, setOrganisation] = useState(Organisation_detail);
  // const [branch, setBranch] = useState(branch_detail);
  const [currentStep, setCurrentStep] = useState(0);

  const next = () => {
    if (currentStep === 5) {
      setCurrentStep(0);
      set_contact_Details(contact_details);
      // setOrganisation(Organisation_detail);
      // setBranch(branch_detail);
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const prev = () => setCurrentStep(currentStep - 1);
  // const goToStep = (step) => setCurrentStep(step);

  return (
    <Provider
      value={{
        contact_initial,
        set_contact_Details,
        organisation,
        setOrganisation,
        next,
        prev,
      }}
    >
      {/* <Provider value={{ next, prev }}> */}
      <div className="sign_up" style={{ backgroundColor: "var(--main-color)" }}>
        <h3 className="mb-2">{t("Sign_up")}</h3>

        <Steps
          class="ant-steps ant-steps-horizontal css-dev-only-do-not-override-1drr2mu ant-steps-label-horizontal"
          current={currentStep}
          //  onChange={goToStep}
        >
          <Step />
          <Step />
          {/* <Step title={"Step 3"} /> */}
          {/* <Step title={"Step 4"} /> */}
          {/* <Step title={"Step 5"} /> */}
          <Step />
        </Steps>
      </div>
      <main className="sign_up_color">{renderStep(currentStep)}</main>
    </Provider>
  );
};
export default MultiStepForm;
