import React, { useEffect } from "react";
import { Formik, Field, Form, useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";

const Leave_Settings = (props) => {
  const signUpSchema = Yup.object({
    unplannedLeaves: Yup.number()
      .required("Please enter a value")
      .typeError("Please enter a valid number")
      .positive("Please enter a positive number")
      .integer("Please enter a whole number")
      .min(0, "Number must be greater than or equal to 0")
      .max(99, "Number must be less than or equal to 99"),

    numberOfWFhallowed: Yup.number()
      .required("Please enter a value")
      .typeError("Please enter a valid number")
      .positive("Please enter a positive number")
      .integer("Please enter a whole number")
      .min(0, "Number must be greater than or equal to 0")
      .max(30, "Number must be less than or equal to 30"),

    monthName: Yup.string()
      .required("Please enter a Value")
      .typeError("Only Alphbets are allowed"),

    dateOfJoining: Yup.number()
      .typeError("Only numbers are allowed")
      .positive("Please enter a positive number")
      .integer("Please enter a whole number")
      .min(0, "Number must be greater than or equal to 0")
      .max(99, "Number must be less than or equal to 31"),

    maternityLeaves: Yup.number()
      .required("Please enter a value")
      .typeError("Please enter a valid number")
      .positive("Please Enter A positive Number")
      .integer("Please Enter a Whole Number")
      .min(1, "Number must be greater than or equal to 0")
      .max(6, "Number must be less than or equal to 6"),

    maxLeaves: Yup.number()
      .required("Please enter a value")
      .typeError("Please enter a valid number")
      .positive("Please Enter A positive Number")
      .integer("Please Enter a Whole Number")
      .min(1, "Number must be greater than or equal to 0")
      .max(99, "Number must be less than or equal to 99"),

    // unplannedLeaves: Yup.string().required("Select yes or no"),
    resetleavesDay: Yup.string().required("Required"),
    resetLeavesMonth: Yup.string().required("Required"),
    WFHAllowed: Yup.string().required("Select Yes or No"),
    resetLeave: Yup.string(),
    clubbing_leaves: Yup.string().required("Select Yes or No"),
    leave_type: Yup.string(),
    formatOfCreditLeaves: Yup.string().required("Select Yes or No"),
    max_ClubbedLeaves: Yup.number()
    .required("Please enter a value")
    .typeError("Please enter a valid number")
    .positive("Please Enter A positive Number")
    .integer("Please Enter a Whole Number")
    .min(1, "Number must be greater than or equal to 0")
    .max(99, "Number must be less than or equal to 99"),
    compofff: Yup.string().required("Select Yes or No"),
    leaveWOP: Yup.string().required("Please select Yes or No"),
    leavedistributed: Yup.string().required("Please select a Value"),
    leavecredit: Yup.string().test(
      "conditionalRequired",
      "Please choose a value",
      function (value) {
        const { leavedistributed } = this.parent; // Access other fields in the form
        if (
          leavedistributed === "Monthly" ||
          leavedistributed === "Monthly_JoinDate"
        ) {
          return !!value; // Return true if multi_check_inout is required and has a value
        }
        return true; // Return true for non-required case
      }
    ),
  });
  const [checkFeilds, setChecksFeilds] = useState("");
  const [leavesettingID, setleavesettingID] = useState();
  const leavesetting = useSelector((state) => state.Reducer);

  // console.log(leavesetting);
  const [initialValues, setinitialValues] = useState({
    unplannedLeaves: "",
    numberOfWFhallowed: 0,
    monthName: "",
    maternityLeaves: "",
    maxLeaves: "",
    leaveWOP: "",
    WFHAllowed: "",
    clubbing_leaves: "",
    leave_type: "",
    formatOfCreditLeaves: "",
    max_ClubbedLeaves: "",
    resetleavesDay: "",
    resetLeavesMonth: "",
    resetLeave: "",
    compofff: "",
    leavecredit: "",
    leavedistributed: "",
    hours: "",
    exp_time: "",
  });
  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values, action) => {
      console.log("the values for leave seting are" ,values);
      // console.log("checkFeilds=", checkFeilds);
      if (checkFeilds === "post") {
        post_leave_setting(values);
      } else if (checkFeilds === "put") {
        put_leave_setting(values);
      }
    },
  });

  //POST REQUEST
  async function post_leave_setting(e) {
    try {
      await axios
        .post(
          `${leavesetting.GLOBAL_API}InsertLeave`,
          {
            leave_w_pay: e.leaveWOP,
            unplan_leaves: e.unplannedLeaves,
            wfh: e.WFHAllowed,
            no_wfh_month: e.numberOfWFhallowed,
            wfh_After_join: e.monthName,
            maternity_criteria: e.maternityLeaves,
            max_leaves: e.maxLeaves,
            reset_leaves: e.resetLeave,
            leave_distribution: e.leavedistributed,
            date_distribution: e.leavecredit,
            clubbing_leaves: e.clubbing_leaves,
            leave_type: e.leave_type,
            formatOfCreditLeaves: e.formatOfCreditLeaves,
            max_ClubbedLeaves: e.max_ClubbedLeaves,
            comp_off: e.compofff,
            minimum_comp_hour: e.hours,
            expirationTime_compoff: e.exp_time,
          },
          {
            headers: {
              Authorization: `Bearer ${leavesetting.TOKEN}`,
            },
            httpsAgent: leavesetting.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("updated value for leave setting=", response.data);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });
            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });

      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }

  //GET REQUEST leave setting

  useEffect(() => {
    if (props.tabStyle === "activeContent") {
      // console.log("the api will be called");
      leave_setting_get();
    }
  }, [props.tabStyle]);
  async function leave_setting_get() {
    try {
      await axios
        .get(`${leavesetting.GLOBAL_API}LeaveSettings`, {
          headers: {
            Authorization: `Bearer ${leavesetting.TOKEN}`,
          },
          httpsAgent: leavesetting.HTTP_AGENT,
        })
        .then((response) => {
          console.log(response.data);
          const mess = response.data.message;
          if (response.status == 200 && response.data[0]) {
            // console.log("leave setting",response.data[0]);
            setleavesettingID(response.data[0].id);
            setChecksFeilds("put");
            const resetLeavesData = response.data[0].reset_leaves;
            const parts = resetLeavesData.split("/");

            // parts[0] contains the data before the slash
            const rday = parts[0].trim();

            // parts[1] contains the data after the slash
            const rmonth = parts[1].trim();
            // console.log("splitted day and month:",rday,rmonth);
            setinitialValues({
              unplannedLeaves: response.data[0].unplan_leaves,
              numberOfWFhallowed: response.data[0].no_wfh_month,
              monthName: response.data[0].wfh_After_join,
              maternityLeaves: response.data[0].maternity_criteria,
              maxLeaves: response.data[0].max_leaves,
              leaveWOP: response.data[0].leave_w_pay,
              WFHAllowed: response.data[0].wfh,
              resetleavesDay: rday,
              resetLeavesMonth: rmonth,
              clubbing_leaves: response.data[0].clubbing_leaves,
              leave_type:response.data[0].leave_type,
              formatOfCreditLeaves:response.data[0].formatOfCreditLeaves,
              max_ClubbedLeaves: response.data[0].max_ClubbedLeaves,
              compofff: response.data[0].comp_off,
              leavecredit: response.data[0].date_distribution,
              leavedistributed: response.data[0].leave_distribution,
              hours: response.data[0].min_comp_hour,
              exp_time: response.data[0].expirationTime_compoff,
            });
          } else if (response.data.message) {
            console.log("empty");
            setChecksFeilds("post");
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
    }
  }
  //GET REQUEST leave setting

  //PUT REQUEST leave setting

  async function put_leave_setting(e) {
    const no = parseInt(e.leavecredit);
    // const keyMapping = {
    //   leaveWOP: 'leave_w_pay',
    //   unplannedLeaves: 'unplan_leaves',
    //   WFHAllowed: 'wfh',
    //   numberOfWFhallowed: 'no_wfh_month',
    //   monthName: 'wfh_After_join',
    //   maternityLeaves: 'maternity_criteria',
    //   maxLeaves: 'max_leaves',
    //   resetLeave: 'reset_leaves',
    //   leavedistributed: 'date_distribution',
    //   leavecredit: 'no_wfh_month',
    //   compofff: 'comp_off',
    //   leavesettingID: 'leaveSetttings_id',
    // };
    //  const updatedValues = { leaveSetttings_id: leavesettingID,};
    // Object.keys(e).forEach(key => {
    //   const apiKey = keyMapping[key] || key;
    //   if (e[key] !== initialValues[key]) {
    //     updatedValues[apiKey] = e[key];
    //   }
    // });
    try {
      // console.log("Values to be updated", updatedValues);
      await axios
        .put(
          `${leavesetting.GLOBAL_API}EditLeaveSetting`,
          {
            leave_w_pay: e.leaveWOP,
            unplan_leaves: e.unplannedLeaves,
            wfh: e.WFHAllowed,
            no_wfh_month: e.numberOfWFhallowed,
            wfh_After_join: e.monthName,
            maternity_criteria: e.maternityLeaves,
            max_leaves: e.maxLeaves,
            reset_leaves: e.resetLeave,
            leave_distribution: e.leavedistributed,
            date_distribution: no,
            comp_off: e.compofff,
            clubbing_leaves: e.clubbing_leaves,
            leave_type:e.leave_type,
            formatOfCreditLeaves:e.formatOfCreditLeaves,
            max_ClubbedLeaves: e.max_ClubbedLeaves,
            minimum_comp_hour: e.hours,
            expirationTime_compoff: e.exp_time,
            leaveSetttings_id: leavesettingID,
          },
          {
            headers: {
              Authorization: `Bearer ${leavesetting.TOKEN}`,
            },
            httpsAgent: leavesetting.HTTP_AGENT,
          }
        )
        .then((response) => {
          console.log("update=", response);
          if (response.status == 200) {
            const successMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Success!",
              text: successMessage,
              icon: "success",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          } else {
            const errorMessage =
              response.data.message || "Something went wrong";
            swal({
              title: "Failure!",
              text: errorMessage,
              icon: "error",
              buttons: {
                confirm: {
                  text: "OK",
                  className: "swal-button--custom",
                },
              },
            });

            // Close the SweetAlert after 3000 milliseconds (3 seconds)
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        });
    } catch (e) {
      console.log(e);
      console.log(e.response.data.error);
      const errorMessage = e.response.data.error || "Something went wrong";
      swal({
        title: "Failure!",
        text: errorMessage,
        icon: "error",
        buttons: {
          confirm: {
            text: "OK",
            className: "swal-button--custom",
          },
        },
      });
      // Close the SweetAlert after 3000 milliseconds (3 seconds)
      setTimeout(() => {
        swal.close();
      }, 2000);
    }
  }
  // console.log(values);

  return (
    <form onSubmit={handleSubmit}>
      <div className="toggleboxcontent">
        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>If leave without pay is allowed</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  {" "}
                  <input
                    type="radio"
                    value="1"
                    checked={values.leaveWOP === "1"}
                    onChange={(e) => {
                      setFieldValue("leaveWOP", e.target.value);
                    }}
                  />
                </label>
                <span>Yes</span>
              </div>
              <div className="radio_btn">
                <label>
                  {" "}
                  <input
                    type="radio"
                    value="0"
                    checked={values.leaveWOP === "0"}
                    onChange={(e) => {
                      setFieldValue("leaveWOP", e.target.value);
                    }}
                  />
                </label>

                <span>No</span>
              </div>
            </div>
          </div>
          {touched.leaveWOP && errors.leaveWOP && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.leaveWOP}
            </p>
          )}
        </div>

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>No of unplanned leaves allowed</h2>
            <div className="attendace_Checkarea rightsty">
              <div
                className="inputbox lateselect inputbocandh2"
                style={{ textAlign: "right" }}
              >
                <input
                  type="text"
                  style={{ marginRight: "3%" }}
                  className="custominputprofile"
                  autoComplete="off"
                  name="unplannedLeaves"
                  id="unplannedLeaves"
                  // placeholder="INV"
                  value={values.unplannedLeaves}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                />{" "}
                <h2>Days</h2>
                <span></span>
              </div>
            </div>
          </div>
          {touched.unplannedLeaves && errors.unplannedLeaves && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.unplannedLeaves}
            </p>
          )}
        </div>

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Is WFH allowed</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="1"
                    onChange={(e) =>
                      setFieldValue("WFHAllowed", e.target.value)
                    }
                    checked={values.WFHAllowed === "1"}
                  />
                </label>
                <span>Yes</span>
              </div>

              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="0"
                    onChange={(e) => {
                      setFieldValue("WFHAllowed", e.target.value);
                    }}
                    checked={values.WFHAllowed === "0"}
                  />
                </label>
                <span>No</span>
              </div>
            </div>
          </div>
          {touched.WFHAllowed && errors.WFHAllowed && (
            <p class="form-error" style={{ textAlign: "right" }}>
              Please select Yes or No
            </p>
          )}
        </div>
        {values.WFHAllowed == "1" && (
          <>
            <div
              className="toggleboxarea mb-2"
              style={{ borderBottom: "none" }}
            >
              <div className="toggleboxhead">
                <h2>Number of WFH allowed in a month</h2>
                <div className="attendace_Checkarea rigthsty ">
                  <div
                    className="inputbox lateselect inputbocandh2"
                    style={{ textAlign: "right" }}
                  >
                    <input
                      type="text"
                      style={{ marginRight: "3%" }}
                      className="custominputprofile"
                      autoComplete="off"
                      name="numberOfWFhallowed"
                      id="unplannedLeaves"
                      // placeholder="INV"
                      value={values.numberOfWFhallowed}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onInput={(e) => {
                        e.target.value = (e.target.value || "").replace(
                          /[^\d]/g,
                          ""
                        );
                      }}
                    />
                    <h2>Days</h2>
                  </div>
                </div>
              </div>
              {touched.numberOfWFhallowed && errors.numberOfWFhallowed && (
                <p class="form-error" style={{ textAlign: "right" }}>
                  {errors.numberOfWFhallowed}
                </p>
              )}
            </div>

            <div className="toggleboxarea mb-2">
              <div className="attendace_Checkarea">
                <div
                  className="inputbox lateselect inputboxleave wfhinputbox"
                  style={{ textAlign: "unset", marginLeft: "3%" }}
                >
                  <h2 style={{ marginRight: "1%" }}>Effective After</h2>
                  <input
                    style={{ marginRight: "1%" }}
                    type="text"
                    className="custominputprofile"
                    autoComplete="off"
                    name="monthName"
                    id="monthName"
                    // placeholder="INV"
                    value={values.monthName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onInput={(e) => {
                      e.target.value = (e.target.value || "").replace(
                        /[^\d]/g,
                        ""
                      );
                    }}
                  />

                  <h2>Months from date of joining</h2>
                </div>
              </div>
              {touched.monthName && errors.monthName && (
                <p class="form-error" style={{ textAlign: "right" }}>
                  {errors.monthName}
                </p>
              )}
            </div>
          </>
        )}

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Add eligiblity criteria for maternity leaves</h2>
            {/* <p>
              A Female candidate will be eligible for maternity leaves after
            </p> */}
            <div className="attendace_Checkarea rightsty">
              <div
                className="inputbox lateselect inputbocandh2"
                style={{ textAlign: "right" }}
              >
                <input
                  type="text"
                  style={{ marginRight: "3%" }}
                  className="custominputprofile"
                  autoComplete="off"
                  name="maternityLeaves"
                  id="maternityLeaves"
                  // placeholder="INV"
                  value={values.maternityLeaves}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                />
                <h2>Month</h2>
                <span></span>
              </div>
            </div>
          </div>
          {touched.maternityLeaves && errors.maternityLeaves && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.maternityLeaves}
            </p>
          )}
        </div>

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Max number of leaves applied at a time</h2>
            <div className="attendace_Checkarea rightsty">
              <div
                className="inputbox lateselect inputbocandh2"
                style={{ textAlign: "right" }}
              >
                <input
                  style={{ marginRight: "3%" }}
                  type="text"
                  className="custominputprofile"
                  autoComplete="off"
                  name="maxLeaves"
                  id="maxLeaves"
                  // placeholder="INV"
                  value={values.maxLeaves}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={(e) => {
                    e.target.value = (e.target.value || "").replace(
                      /[^\d]/g,
                      ""
                    );
                  }}
                />

                <span></span>
              </div>
            </div>
          </div>
          {touched.maxLeaves && errors.maxLeaves && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.maxLeaves}
            </p>
          )}
        </div>

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>
              Reset leaves will be done for your organisation on every -- day of
              -- Month
            </h2>
            <div className="">
              <div
                className="inputbox lateselect"
                style={{ textAlign: "right" }}
              >
                <label
                  htmlFor=""
                  className="custominputprofile"
                  style={{ display: "none" }}
                >
                  <select
                    name="leavesDay"
                    type="text"
                    className="custominputprofile"
                    id="leavesDay"
                    // placeholder="INV"
                    value={values.resetleavesDay}
                    onChange={(e) => {
                      setFieldValue("resetleavesDay", e.target.value);
                    }}
                  >
                    <option value="" label="Day a day" />
                    {[...Array(31)].map((_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </label>

                <span></span>
              </div>
              <div
                className="inputbox lateselect"
                style={{ textAlign: "right", width: "77%" }}
              >
                <label htmlFor="" className="custominputprofile">
                  <select
                    name="leavesDay"
                    type="text"
                    className="custominputprofile"
                    id="leavesDay"
                    // placeholder="INV"
                    value={values.resetLeavesMonth}
                    onChange={(e) => {
                      {
                        setFieldValue("resetLeavesMonth", e.target.value);
                        setFieldValue(
                          "resetLeave",
                          `${values.resetleavesDay}/${e.target.value}`
                        );
                      }
                    }}
                  >
                    <option value="" label="Month" />
                    {[...Array(12)].map((_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </label>

                <span></span>
              </div>

              <div className="attendace_Checkarea"></div>
            </div>
          </div>
          {touched.resetleavesDay && (
            <p className="form-error" style={{ textAlign: "right" }}>
              {errors.resetleavesDay}
            </p>
          )}
        </div>

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Comp off allowed</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="1"
                    onChange={(e) => {
                      setFieldValue("compofff", e.target.value);
                    }}
                    checked={values.compofff === "1"}
                  />
                </label>
                <span>Yes</span>
              </div>

              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="0"
                    onChange={(e) => {
                      setFieldValue("compofff", e.target.value);
                    }}
                    checked={values.compofff === "0"}
                  />
                </label>
                <span>No</span>
              </div>
            </div>
          </div>
          {touched.compofff && errors.compofff && (
            <p class="form-error" style={{ textAlign: "right" }}>
              Please select Yes or No
            </p>
          )}

          {/* comp off  */}
          {values.compofff == "1" && (
            <>
              <div className=" mb-2 mt-2" style={{ borderBottom: "none" }}>
                <div className="toggleboxhead">
                  <h2>
                    The number of hours beyond the shift timing after which comp
                    off should be counted.
                  </h2>
                  <div className="attendace_Checkarea rigthsty ">
                    <div className="inputbox" style={{ textAlign: "right" }}>
                      <select
                        style={{ marginRight: "3%" }}
                        className="custominputprofile"
                        name="hours"
                        value={values.hours}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Hour
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </select>
                    </div>
                  </div>
                </div>
                {touched.hours && errors.hours && (
                  <p class="form-error" style={{ textAlign: "right" }}>
                    {errors.hours}
                  </p>
                )}
              </div>

              <div className=" mb-2" style={{ borderBottom: "none" }}>
                <div className="toggleboxhead">
                  <h2>
                    The number of days within which a comp off should be
                    utilized once it is approved.
                  </h2>
                  <div className=" ">
                    <div className="inputbox" style={{ textAlign: "right" }}>
                      <select
                        style={{ marginRight: "3%" }}
                        className="custominputprofile"
                        name="exp_time"
                        id="exp_time"
                        value={values.exp_time}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Month
                        </option>
                        <option value="current_month">Current month</option>
                        <option value="15Days">15 days</option>
                        <option value="1Month">1 month</option>
                        <option value="3Month">3 month</option>
                        <option value="6Month">6 month</option>
                      </select>
                    </div>
                  </div>
                </div>
                {touched.exp_time && errors.exp_time && (
                  <p class="form-error" style={{ textAlign: "right" }}>
                    {errors.exp_time}
                  </p>
                )}
              </div>
            </>
          )}
          {/* comp off  */}
        </div>

        <div className="toggleboxarea mb-2">
          <div className="toggleboxhead">
            <h2>Is Clubbing of leaves allowed</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="1"
                    onChange={(e) => {
                      setFieldValue("clubbing_leaves", e.target.value);
                    }}
                    checked={values.clubbing_leaves === "1"}
                  />
                </label>
                <span>Yes</span>
              </div>

              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    value="0"
                    onChange={(e) => {
                      setFieldValue("clubbing_leaves", e.target.value);
                    }}
                    checked={values.clubbing_leaves === "0"}
                  />
                </label>
                <span>No</span>
              </div>
            </div>
          </div>
          {touched.clubbing_leaves && errors.clubbing_leaves && (
            <p class="form-error" style={{ textAlign: "right" }}>
              Please select Yes or No
            </p>
          )}

          {/* comp off  */}
          {values.clubbing_leaves === "1" && (
            <div className="toggleboxhead" style={{ marginTop: "40px" }}>
              <h2>Select leave type</h2>
              <div className="attendace_Checkarea">
                <div className="radio_btn">
                  <label>
                    <input
                      type="radio"
                      value="1"
                      onChange={(e) => {
                        setFieldValue("leave_type", e.target.value);
                      }}
                      checked={values.leave_type === "1"}
                    />
                  </label>
                  <span>Casual</span>
                </div>

                <div className="radio_btn">
                  <label>
                    <input
                      type="radio"
                      value="2"
                      onChange={(e) => {
                        setFieldValue("leave_type", e.target.value);
                      }}
                      checked={values.leave_type === "2"}
                    />
                  </label>
                  <span>Earned</span>
                </div>
              </div>
              {touched.leave_type && errors.leave_type && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.leave_type}
                    </p>
                  )}
            </div>
          )}

          {values.clubbing_leaves === "1" && values.leave_type && (
            <div className="toggleboxhead" style={{ marginTop: "40px" }}>
              <h2>Select format</h2>
              <div className="attendace_Checkarea">
                <div className="radio_btn">
                  <label>
                    <input
                      type="radio"
                      value="percentage"
                      onChange={(e) => {
                        setFieldValue("formatOfCreditLeaves", e.target.value);
                      }}
                      checked={values.formatOfCreditLeaves === "percentage"}
                    />
                  </label>
                  <span>Percent</span>
                </div>

                <div className="radio_btn">
                  <label>
                    <input
                      type="radio"
                      value="number"
                      onChange={(e) => {
                        setFieldValue("formatOfCreditLeaves", e.target.value);
                      }}
                      checked={values.formatOfCreditLeaves === "number"}
                    />
                  </label>
                  <span>Number</span>
                </div>
              </div>
              {touched.formatOfCreditLeaves && errors.formatOfCreditLeaves && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.formatOfCreditLeaves}
                    </p>
                  )}
            </div>
          )}

          {values.clubbing_leaves === "1" &&
            values.leave_type &&
            values.formatOfCreditLeaves === "percentage" && (
              <>
                <div className=" mb-2 mt-2" style={{ borderBottom: "none" }}>
                  <div className="toggleboxhead">
                    <h2>The number of clubbed leaves allowed (in %)</h2>
                    <div className="attendace_Checkarea rightsty">
                      <div
                        className="inputbox lateselect inputbocandh2"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="text"
                          style={{ marginRight: "3%" }}
                          className="custominputprofile"
                          autoComplete="off"
                          name="max_ClubbedLeaves"
                          id="max_ClubbedLeaves"
                          // placeholder="INV"
                          value={values.max_ClubbedLeaves}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onInput={(e) => {
                            e.target.value = (e.target.value || "").replace(
                              /[^0-9%]/g,
                              ""
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {touched.max_ClubbedLeaves && errors.max_ClubbedLeaves && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.max_ClubbedLeaves}
                    </p>
                  )}
                </div>
              </>
            )}

          {values.clubbing_leaves === "1" &&
            values.leave_type &&
            values.formatOfCreditLeaves === "number" && (
              <>
                <div className=" mb-2 mt-2" style={{ borderBottom: "none" }}>
                  <div className="toggleboxhead">
                    <h2>The number of clubbed leaves allowed (in number)</h2>
                    <div className="attendace_Checkarea rightsty">
                      <div
                        className="inputbox lateselect inputbocandh2"
                        style={{ textAlign: "right" }}
                      >
                        <input
                          type="text"
                          style={{ marginRight: "3%" }}
                          className="custominputprofile"
                          autoComplete="off"
                          name="max_ClubbedLeaves"
                          id="max_ClubbedLeaves"
                          // placeholder="INV"
                          value={values.max_ClubbedLeaves}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onInput={(e) => {
                            e.target.value = (e.target.value || "").replace(
                              /[^0-9%]/g,
                              ""
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {touched.max_ClubbedLeaves && errors.max_ClubbedLeaves && (
                    <p class="form-error" style={{ textAlign: "right" }}>
                      {errors.max_ClubbedLeaves}
                    </p>
                  )}
                </div>
              </>
            )}
          {/* comp off  */}
        </div>

        <div className="toggleboxarea mb-2" style={{ borderBottom: "none" }}>
          <div className="leaveDistributedsty">
            <h2>How will employee get the leaves distributed on</h2>
            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    name="leavedistributed"
                    value="Monthly"
                    checked={values.leavedistributed === "Monthly"}
                    onChange={(e) => {
                      setFieldValue("leavedistributed", e.target.value);
                    }}
                  />
                </label>
                <p style={{ display: "inline" }}>
                  Each month starting from date of joining
                </p>
              </div>
            </div>

            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    name="leavedistributed"
                    value="Monthly_JoinDate"
                    checked={values.leavedistributed === "Monthly_JoinDate"}
                    onChange={(e) => {
                      setFieldValue("leavedistributed", e.target.value);
                    }}
                  />
                </label>
                <p style={{ display: "inline" }}>
                  Irrespective of date of joining(distributed)
                </p>
              </div>
            </div>

            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    name="leavedistributed"
                    value="No_Monthly"
                    checked={values.leavedistributed === "No_Monthly"}
                    onChange={(e) => {
                      setFieldValue("leavedistributed", e.target.value);
                    }}
                  />
                </label>
                <p style={{ display: "inline" }}>
                  On very first day(without distributed) of leave period
                </p>
              </div>
            </div>

            <div className="attendace_Checkarea">
              <div className="radio_btn">
                <label>
                  <input
                    type="radio"
                    name="leavedistributed"
                    value="No_Monthly_JoinDate"
                    checked={values.leavedistributed === "No_Monthly_JoinDate"}
                    onChange={(e) => {
                      setFieldValue("leavedistributed", e.target.value);
                    }}
                  />
                </label>
                <p style={{ display: "inline" }}>
                  On date of joining(distributed) of leave period
                </p>
              </div>
            </div>
          </div>

          {touched.leavedistributed && errors.leavedistributed && (
            <p class="form-error" style={{ textAlign: "right" }}>
              {errors.leavedistributed}
            </p>
          )}
        </div>

        {(values.leavedistributed === "Monthly" ||
          values.leavedistributed === "Monthly_JoinDate") && (
          <>
            <div className="toggleboxarea mb-2" style={{ border: "none;" }}>
              <div className="toggleboxhead">
                <h2>Set leave credit date for monthly balance</h2>
                <div className="attendace_Checkarea">
                  <div
                    className="inputbox lateselect"
                    style={{ textAlign: "right", width: "74%" }}
                  >
                    <label htmlFor="" className="custominputprofile">
                      <select
                        name="leaveCreditDate"
                        type="text"
                        className="custominputprofile"
                        id="leaveCreditDate"
                        // placeholder="INV"
                        value={values.leavecredit}
                        onChange={(e) => {
                          setFieldValue("leavecredit", e.target.value);
                        }}
                      >
                        <option value="" label="Day" />
                        {[...Array(31)].map((_, index) => (
                          <option key={index + 1} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </label>

                    <span></span>
                  </div>
                </div>
              </div>
              {touched.leavecredit && errors.leavecredit && (
                <p class="form-error" style={{ textAlign: "right" }}>
                  {errors.leavecredit}
                </p>
              )}
            </div>
          </>
        )}
      </div>
      <div className="butnsec">
        <button className="cusbtn" type="submit">
          Save
        </button>
      </div>
    </form>
  );
};

export default Leave_Settings;
